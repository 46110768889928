import { LitElement, css, html } from "lit";
import { isIE } from "../../../../../legl-ui/utils";
import { stringToHTML } from "../../../../../legl-ui/utils/functions.js";

class HTMLHeading extends LitElement {
  static get properties() {
    return {
      heading: { type: String, attribute: "heading" },
    };
  }

  static get styles() {
    return css`
            :host h4 {
                margin: 0;
            }
            :host h3 {
                margin: 5px 0;
            }

            :host i {
                font-weight: normal;
            }

            :host p {
                margin: 6px 0 24px 0px;
            }

            :host a {
                color: var(--legl-grey-dark);
            }
        `;
  }

  firstUpdated(props) {
    super.firstUpdated(props);
    this.shadowRoot.querySelectorAll(".heading a").forEach((element) => {
      element.target = "_blank";
    });
  }

  render() {
    return html`
            <div class="heading">
                ${
                  isIE()
                    ? this.heading.replace(/(<([^>]+)>)/gi, "")
                    : stringToHTML(this.heading)
                }
            </div>
        `;
  }
}

if (!customElements.get("legl-html-heading")) {
  customElements.define("legl-html-heading", HTMLHeading);
}
