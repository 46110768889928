import { LitElement, css, html, nothing } from "lit";
import { connect } from "pwa-helpers";
import "../../../../../../legl-ui/action-bar";
import "../../../../../../legl-ui/activity-log";
import "../../../../../../legl-ui/card";
import "../../../../../../legl-ui/lds-alert";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../legl-ui/lds-modal";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import "../../../../../../legl-ui/number";
import { get } from "../../../../../../legl-ui/utils/fetch";
import { formatAddress, formatDateTime, titleCase } from "../../../../../../legl-ui/utils/functions.js";
import store, { payActions } from "../../../../../../redux/lawyers-admin";
import companyPaymentSettings from "../../config/company-payment-settings.js";
import { userConfig } from "../../config/user-config";
import "../../pages/pay/components/modals/refund/new-refund-modal";
import { createRefundModal } from "../../pages/pay/components/modals/refund/refund-modal";
import "../../pages/pay/components/pay-review-actions.js";
import { createClipboardButton } from "../../utils/clipboard";
import "../email-reminders-row/email-reminders-row.js";

export class LeglPayReview extends connect(store)(LitElement) {
  static get styles() {
    return css`
            :host {
                --legl-action-bar-min-width: 0;
            }

            .heading-actions-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;
            }

            .refund-button-container {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
            }

            .action-buttons {
                display: flex;
                align-items: center;
            }

            .open-engage-drawer-btn {
                align-self: center;
                color: ${neutral["700"]};
                border: none;
                cursor: pointer;
                transform-origin: 50% 50%;
                transition: transform 0.125s ease-in-out 0s;
                padding: 15px 5px;
                background: none;
                font-size: 12px;
                min-width: 44px;
                margin-right: 10;
                display: grid;
                grid-template-rows: 22px auto;
            }

            .open-engage-drawer-btn:hover {
                background-color: ${neutral["200"]};
                border-radius: 4px;
                text-decoration: none;
            }

            .open-engage-drawer-btn lds-icon {
                font-size: 16px;
            }

            .title {
                margin-top: 0;
                width: 200px;
                font-size: 16px;
            }

            .content-details {
                color: var(--legl-grey-dark);
            }

            .contact-details {
                display: flex;
            }

            .engage-request-info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .payment-details {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                width: 100%;
                margin-top: ${spacing.m};
            }

            .workflow-details {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
            }

            .contact-details lds-icon {
                padding-right: 5px;
            }

            .request-details-column {
                padding-right: 35px;
            }

            .content-item {
                display: flex;
                align-items: center;
                margin-bottom: ${spacing.s};
                color: ${neutral["900"]};
                ${typographyPresets.medium};
            }

            .content-item:last-child {
              margin-bottom: 0;
            }

            .content-item p {
              margin: 0;
            }

            .content-item.attachments {
              align-items: flex-start;
            }

            .engage-content-item {
                padding: 5px 5px 5px 15px;
                display: flex;
                align-items: center;
            }

            .engage-request-status {
                margin-right: -20px;
            }

            .full-width {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            .margin-bottom {
                margin-bottom: 0.1em;
            }

            .pay-request-history {
                padding-top: 5px;
                color: ${neutral["800"]};
            }

            .title {
              ${typographyPresets.mediumBold};
                width: 200px;
            }

            .pay-drawer__card {
                margin-bottom: 15px;
            }

            .invoice-link {
                color: ${primary["500"]};
                overflow: hidden;
                display: inline-block;
                white-space: nowrap; 
                text-overflow: ellipsis;
                max-width: 400px;

            }

            .invoice-link span {
                text-decoration: underline;
            }

            .payment-provider {
                display: flex;
                align-items: center;
            }

            .payment-provider lds-icon {
                margin-right: ${spacing.xs};
            }

            .payment-provider:first-child {
                margin-right: ${spacing.s};
            }

            lds-alert {
                margin-bottom: ${spacing.s};
            }

            lds-alert span {
                color: ${neutral["800"]};
                ${typographyPresets.bodyBold};
            }

            lds-alert a {
                color: ${primary["500"]};
                ${typographyPresets.bodyBold};
            }
        `;
  }
  static get properties() {
    return {
      params: {
        type: URLSearchParams,
      },
      paymentRequest: {
        type: Object,
      },
      companyPaymentSettings: {
        type: Object,
      },
      showPaymentUnavailableMessage: { type: Boolean, state: true },
      isLoadingRefund: { type: Boolean, state: true },
    };
  }

  constructor() {
    super();
    this.addEventListener("close-link-clicked-from-drawer", () =>
      this.fetchReviewInformation(),
    );
    this.showPaymentUnavailableMessage = false;
    this.isLoadingRefund = false;
  }

  stateChanged(state) {
    this.paymentRequest = state.pay[this.params.get("appId")];
  }

  get isPortalPayment() {
    return this.paymentRequest.source === "PAYMENT_PORTAL";
  }

  get isPaymentTransferComplete() {
    return (
      this.paymentRequest.transfer_id &&
      this.paymentRequest.transfer_time &&
      this.paymentRequest.bank_account_name &&
      this.paymentRequest.amount
    );
  }

  get paymentIcon() {
    if (this.paymentRequest.wallet_type === "apple_pay") {
      return "ApplePay";
    } else if (this.paymentRequest.wallet_type === "google_pay") {
      return "GooglePay";
    } else if (this.paymentRequest.source === "PHONE_PAYMENT") {
      return "Phone";
    }
    return "CreditCard";
  }

  get cardCountry() {
    if (this.paymentRequest && this.paymentRequest.card_country) {
      return this.paymentRequest.card_country;
    }
    return "";
  }

  get last4() {
    if (this.paymentRequest && this.paymentRequest.last4) {
      return this.paymentRequest.last4;
    }
    return "----";
  }

  get paymentMethod() {
    if (this.paymentRequest.provider_name === "banked") {
      return html`<span class="payment-provider"
                ><lsd-icon name="lds-icon-Bank"></lds-icon>Pay by bank</span
            >`;
    }
    if (this.paymentRequest.provider_name === "dummy") {
      return html`<span class="payment-provider"
                ><lds-icon name="lds-icon-SettingsOutlined"></lds-icon>Dummy
                test payment</span
            >`;
    }
    if (this.paymentRequest.provider_name === "adyen") {
      const cardType = this?.paymentRequest?.card_brand || "-";
      if (waffle.flag_is_active("show_payment_details") && this.paymentRequest.payment_details) {
        const paymentMethod = this.paymentRequest.payment_details.payment_method;
        return html`<span data-testid="payment-method-value" class="payment-provider"><lds-icon .name="lds-icon-${this.paymentIcon}"></lds-icon>${paymentMethod.card_method}${paymentMethod.card_country}*${paymentMethod.card_last4} - ${`${titleCase(paymentMethod.card_function)} `}${paymentMethod.type}</span>`
      }
      return html` <span class="payment-provider">
                <lds-icon .name="lds-icon-${this.paymentIcon}"></lds-icon>
                <span class="card-type">${cardType}</span> ${this.cardCountry}
                *${this.last4}
            </span>`;
    }
    if (this.paymentRequest.provider_name === "stripe") {
      const cardType = this?.paymentRequest?.card_brand || "-";
      return html` <span class="payment-provider">
                <lds-icon .name="lds-icon-${this.paymentIcon}"></lds-icon>
                <span class="card-type">${cardType}</span> ${this.cardCountry}
                *${this.last4}
            </span>`;
    }

    return html`<span class="payment-provider">-</span>`;
  }

  get engageRequestReviewer() {
    if (this.paymentRequest.engage_request.reviewed_by) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.reviewed_by.name}
                </span>
            `;
    } else if (this.paymentRequest.engage_request.assigned_reviewer) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.assigned_reviewer.name}
                </span>
            `;
    } else if (this.paymentRequest.engage_request.created_by) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.created_by.name}
                </span>
            `;
    }
    return "-";
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchReviewInformation();
    this.fetchCompanyPaymentSettings();
    window.addEventListener("reload-payment-drawer", () => {
      this.fetchReviewInformation();
    });
  }

  async fetchReviewInformation() {
    const res = await get(`/api/payments/${this.params.get("appId")}/`);
    if (res.status === 404) {
      this.showPaymentUnavailableMessage = true;
    } else {
      const paymentRequest = await res.json();
      store.dispatch(payActions.fetchPaymentDetails(paymentRequest));
    }
  }

  async fetchCompanyPaymentSettings() {
    this.firmReferenceLabel1 = "Invoice reference";
    this.firmReferenceLabel2 = "Matter reference";
    await companyPaymentSettings.fetch();
    this.companyPaymentSettings = companyPaymentSettings.settings;
    this.setFirmReferenceLabels();
  }

  setFirmReferenceLabels() {
    if (this.companyPaymentSettings.invoice_reference_label) {
      this.firmReferenceLabel1 =
        this.companyPaymentSettings.invoice_reference_label;
    }

    if (this.companyPaymentSettings.matter_reference_label) {
      this.firmReferenceLabel2 =
        this.companyPaymentSettings.matter_reference_label;
    }
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    const copyButton = this.shadowRoot.querySelector("#clipboard-button");
    if (copyButton) {
      createClipboardButton(copyButton);
    }
  }

  onViewClicked() {
    window.location.hash = `/drawer/engage-review/?appId=${this.paymentRequest.engage_request.id}`;
  }

  isPaymentComplete() {
    return (
      this.paymentRequest.status === "Paid" ||
      this.paymentRequest.status === "Transferred" ||
      this.paymentRequest.marked_paid ||
      this.paymentRequest.closed
    );
  }

  get disableRefundButton() {
    return this.paymentRequest.provider_name !== "adyen";
  }

  get refundTooltipText() {
    return html`Refunding payments directly is only available for Adyen
            payments.`;
  }

  showRefundButton() {
    if (this.paymentRequest.provider_name !== "adyen") {
      return false;
    }
    if (waffle.flag_is_active("new-refund-request-flow")) {
      if (!userConfig.hasRefundPermissions) {
        return false;
      }
      return ["Transferred", "Partially Refunded"].includes(
        this.paymentRequest.status,
      );
    }
    return [
      "Paid",
      "Transferred",
      "Partially Refunded",
      "Refund Processing",
    ].includes(this.paymentRequest.status);
  }

  handleRefund() {
    if (this.paymentRequest.provider_name === "adyen") {
      this.openRefundModal();
    }
  }

  get refundButtonText() {
    if (this.paymentRequest.status === "Refund Processing") {
      return "View refund request";
    }
    return "Refund payment";
  }

  get requestUrl() {
    return `/api/payments/${this.paymentRequest.id}/`;
  }

  get isStatusCreated() {
    return this.paymentRequest.status === "Created";
  }

  get sendEmailUrl() {
    return `api/payments/send_payments_email/${this.paymentRequest.reference}/`;
  }

  get contactEmail() {
    return this.paymentRequest.contact?.email;
  }

  get isReminderRowHidden() {
    return !(
      this.paymentRequest.source === "PAY_REQUEST" ||
      (this.paymentRequest.source === "SCHEDULED_PAYMENT" &&
        !this.paymentRequest.off_session)
    );
  }

  get detailsAttachmentValue() {
    return this.paymentRequest?.payment_documents?.length
      ? this.paymentRequest?.payment_documents.map(payment_document => html`<div><a
            class="invoice-link"
            href=${payment_document.url}
            title=${payment_document.name}
            target="_blank"
            rel="noopener noreferrer"
        >
            <span>${payment_document.name}</span>
        </a></div>`)
      : "-";
  }

  getBankAccountActivityText(event) {
    if (event.metadata?.destination_bank_account) {
      return ` to be refunded from <strong>${event.metadata?.destination_bank_account}</strong>`;
    }
    return "";
  }

  getEventIndividual(event) {
    return event.triggered_by ? event.triggered_by.name : "Legl";
  }

  getActivityText(event) {
    switch (event.type) {
      case "off_session_payment_failed":
        return `Legl <strong>declined</strong> this payment. The reason was: <strong>${event?.comment}</strong>`;
      case "off_session_payment_retried":
        return `${event?.triggered_by?.name} <strong>retried</strong> this payment.`;
      case "off_session_payment_retried_automatically":
        return "Legl <strong>retried</strong> this payment automatically.";
      case "refund_requested":
        return `${
          event.triggered_by.name
        } <strong>requested</strong> a refund for this payment of amount <strong><legl-currency currency="${
          this.paymentRequest.currency
        }" value="${
          event.metadata?.amount
        }" locale="${this.getLocale()}"></legl-currency></strong>${this.getBankAccountActivityText(event)}. 
        
        The reason was: <strong>${event.comment}</strong>`;
      case "refund_failed":
        return `A refund for this payment of amount <strong><legl-currency currency="${
          this.paymentRequest.currency
        }" value="${
          formatIntToDecimal(event.metadata?.amount)
        }" locale="${this.getLocale()}"></legl-currency></strong>${this.getBankAccountActivityText(event)} has failed to process. Please contact support for more information`;  
      case "refund_cancelled":
        return `${event.triggered_by.name} <strong>cancelled</strong> the refund request for this payment.`;
      case "fake_event":
        return event.comment;
      default:
        return "";
    }
  }

  formatIntToDecimal (value) {
    let decimalValue = (parseInt(value) / 100).toFixed(2);

    return decimalValue.toString()
  }

  getRetrySuccessUser() {
    const retryEvents = this.paymentRequest.events.filter((event) => {
      return (
        event.type ===
        ("off_session_payment_retried" ||
          "off_session_payment_retried_automatically")
      );
    });
    const successRetryEvent = retryEvents[0];
    return successRetryEvent?.triggered_by
      ? successRetryEvent.triggered_by.name
      : "Legl";
  }

  getEvents() {
    const events = [...this.paymentRequest.events];

    if (this.paymentRequest.autogenerated) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: "Legl",
        },
        comment:
          "This request was automatically generated when the contact's email address was updated. The previous request was marked closed.",
        created_at: this.paymentRequest.created_at,
        payRequestComment: this.paymentRequest.comment,
      });
    }
    if (
      this.paymentRequest.requested_by &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.paymentRequest.autogenerated &&
      !this.paymentRequest.off_session
    ) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.requested_by?.name },
        comment: `${this.paymentRequest.requested_by?.name} <strong>requested</strong> this payment.`,
        created_at: this.paymentRequest.created_at,
        payRequestComment: this.paymentRequest.comment,
      });
    }
    if (this.paymentRequest.off_session) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "Legl <strong>created</strong> this payment automatically.",
        created_at: this.paymentRequest.created_at,
      });
    }
    if (this.paymentRequest.engage_payment) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: this.paymentRequest.engage_request?.created_by?.name,
        },
        comment: `${this.paymentRequest.engage_request?.created_by?.name} <strong>created</strong> this payment as part of a workflow.`,
        created_at: this.paymentRequest.created_at,
      });
    }
    if (
      this.paymentRequest.completed_at &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.isPortalPayment &&
      this.paymentRequest.off_session
    ) {
      const actor = this.getRetrySuccessUser();
      events.push({
        type: "fake_event",
        triggered_by: { name: actor },
        comment: `${actor} <strong>completed</strong> this payment${
          actor === "Legl" ? " automatically" : ""
        }.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (
      this.paymentRequest.completed_at &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.isPortalPayment &&
      !this.paymentRequest.off_session
    ) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name}`,
        },
        comment: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name} <strong>completed</strong> this payment.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (this.paymentRequest.completed_at && this.isPortalPayment) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name}`,
        },
        comment: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name} <strong>paid</strong> this request from payment portal.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (this.paymentRequest.source === "PHONE_PAYMENT") {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.requested_by?.name },
        comment: `${this.paymentRequest.requested_by?.name} <strong>completed a phone payment</strong>
                                    on behalf of ${this.paymentRequest.first_name} ${this.paymentRequest.last_name}.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (
      this.paymentRequest.marked_paid &&
      !this.paymentRequest.marked_paid_by
    ) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "This payment was <strong>marked as paid</strong>.",
        created_at: this.paymentRequest.marked_paid_at,
      });
    }
    if (this.paymentRequest.marked_paid && this.paymentRequest.marked_paid_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.marked_paid_by.name },
        comment: `${this.paymentRequest.marked_paid_by.name} <strong>marked this payment as paid</strong>.`,
        created_at: this.paymentRequest.marked_paid_at,
      });
    }
    if (this.paymentRequest.closed && !this.paymentRequest.closed_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "This payment was <strong>marked as closed</strong>.",
        created_at: this.paymentRequest.closed_at,
      });
    }
    if (this.paymentRequest.closed && this.paymentRequest.closed_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.closed_by.name },
        comment: `${this.paymentRequest.closed_by.name} <strong>marked this payment as closed</strong>.`,
        created_at: this.paymentRequest.closed_at,
      });
    }
    if (this.isPaymentTransferComplete) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: `<legl-currency currency="${
          this.paymentRequest.currency
        }" value="${
          this.paymentRequest.amount
        }" locale="${this.getLocale()}"></legl-currency> was <strong>transferred</strong> to ${
          this.paymentRequest.bank_account_name
        }.`,
        created_at: this.paymentRequest.transfer_time,
      });
    }
    return events.sort(
      (ev1, ev2) => new Date(ev2.created_at) - new Date(ev1.created_at),
    );
  }

  getLocale() {
    return this.companyPaymentSettings?.company?.locale || "en-GB";
  }

  async openRefundModal() {
    if (this.disableRefundButton) {
      return;
    }
    this.isLoadingRefund = true;
    const url = "/refund/?reference=" + this.paymentRequest.reference;
    const response = await get(url);

    if (response.status === 200) {
      const refundRequest = await response.json();

      if (waffle.flag_is_active("new-refund-request-flow")) {
        LdsModal.create({
          title: "Refund request",
          slotComponent: "legl-new-refund-modal",
          slotComponentProperties: {
            payment: this.paymentRequest,
            refundRequest: refundRequest,
          },
          closeOnClickOutside: true,
        });
      } else {
        createRefundModal(this.paymentRequest, refundRequest);
      }
    } else {
      LdsToast.showError({
        text: "Something went wrong. Please try again.",
      });
    }
    this.isLoadingRefund = false;
  }

  render() {
    if (this.showPaymentUnavailableMessage) {
      return html`
                <legl-permissions-overlay
                    overlayMessage="This Payment Request could not be found"
                ></legl-permissions-overlay>
            `;
    }
    if (this.paymentRequest) {
      return html`
                <div class="heading-actions-row">
                    <legl-request-status
                        status="${this.paymentRequest.status}"
                    >
                        ${this.paymentRequest.status}
                    </legl-request-status>
                    ${
                      this.showRefundButton()
                        ? html` <div class="refund-button-container">
                                  <lds-button
                                      .isLoading=${this.isLoadingRefund}
                                      .disabled=${this.disableRefundButton}
                                      small
                                      @click=${this.handleRefund}
                                  >
                                      ${this.refundButtonText}
                                  </lds-button>
                                  ${
                                    this.disableRefundButton
                                      ? html`<lds-tooltip
                                            .text=${this.refundTooltipText}
                                            placement="bottom"
                                        >
                                            <lds-icon
                                                name="lds-icon-InfoFilled"
                                            ></lds-icon>
                                        </lds-tooltip>`
                                      : nothing
                                  }
                              </div>`
                        : nothing
                    }
                    <legl-pay-review-actions
                        .paymentRequest=${this.paymentRequest}
                    ></legl-pay-review-actions>
                </div>
                ${
                  this.paymentRequest.source === "SCHEDULED_PAYMENT"
                    ? html`<lds-alert type="info">
                              <div slot="content">
                                  <span
                                      >This payment is part of a payment
                                      plan.</span
                                  >
                                  <a
                                      data-cy-payment-schedule-link
                                      href=${`#/drawer/payment-plan-review?uid=${this.paymentRequest.payment_schedule_id}`}
                                      >View payment plan details</a
                                  >
                              </div>
                          </lds-alert>`
                    : nothing
                }
                ${
                  !this.isReminderRowHidden
                    ? html`
                              <email-reminders-row
                                  .params=${this.params}
                                  .reminders=${this.paymentRequest.reminders}
                                  .isRequestClosedOrComplete=${Boolean(
                                    this.isPaymentComplete(),
                                  )}
                                  .requestUrl=${this.requestUrl}
                                  .sendEmailUrl=${this.sendEmailUrl}
                                  .contactEmail=${this.contactEmail}
                                  .isStatusCreated=${this.isStatusCreated}
                                  .requestId=${this.paymentRequest.id}
                                  productType="pay"
                              ></email-reminders-row>
                          `
                    : nothing
                }
                ${
                  this.paymentRequest.engage_payment
                    ? html`
                            <legl-card class="pay-drawer__card">
                                <h3 slot="title" variant="corner">WORKFLOW DETAILS</h3>
                                  <div class="workflow-details">
                                     <div>
                                        <div
                                            class="engage-content-item"
                                        >
                                          <span class="title">Workflow</span>
                                          <span>
                                          ${
                                              this.paymentRequest
                                                .engage_request.flow_name
                                            }</span>
                                        </div>  
                                        <div
                                            class="engage-content-item"
                                        >
                                            <span class="title">Status</span>
                                            <legl-request-status
                                                class="engage-request-status"
                                                status="${
                                                  this.paymentRequest
                                                    .engage_request
                                                    .status
                                                }"
                                            >
                                                ${
                                                  this.paymentRequest
                                                    .engage_request
                                                    .status
                                                }
                                            </legl-request-status>
                                        </div>

                                        <div
                                            class="engage-content-item"
                                        >
                                            <span class="title">Reviewer</span>
                                            ${
                                              this
                                                .engageRequestReviewer
                                            }
                                        </div>

                                        <div
                                            class="engage-content-item"
                                        >
                                            <span class="title">Matter reference</span class="title">
                                            ${
                                              this.paymentRequest
                                                .engage_request
                                                .matter_reference
                                                ? html`<span
                                                          >${this.paymentRequest.engage_request.matter_reference}
                                                      </span>`
                                                : "-"
                                            }
                                        </div>

                                        <div
                                            class="engage-content-item"
                                        >
                                          <span class="title">Client reference</span>
                                            ${
                                              this.paymentRequest
                                                .engage_request
                                                .client_reference
                                                ? html`<span
                                                          >${this.paymentRequest.engage_request.client_reference}
                                                      </span>`
                                                : "-"
                                            }
                                        </div>
                                          ${
                                            this.paymentRequest
                                              .custom_reference
                                              ? html`<div
                                                        class="engage-content-item"
                                                    >
                                                        <span
                                                            class="title"
                                                            >${
                                                              this
                                                                .paymentRequest
                                                                .custom_reference_label ||
                                                              "Firm reference"
                                                            } </span
                                                        >
                                                        ${
                                                          this
                                                            .paymentRequest
                                                            .custom_reference
                                                            ? html`<span
                                                                  data-cy-custom-reference
                                                                  >${this.paymentRequest.custom_reference}
                                                              </span>`
                                                            : "-"
                                                        }
                                                    </div>`
                                              : ""
                                          }
                                      </div>
                                    <div>
                                      <lds-button
                                        data-cy-review
                                        @click=${() => this.onViewClicked()}
                                        icon="lds-icon-Review"
                                        variant="outlined"
                                        colour="primary"
                                        small
                                       >
                                        View engage request
                                      </lds-button>
                                    </div>
                                  </div>
                                </legl-card>
                              `
                    : ""
                }
                <legl-card class="pay-drawer__card">
                    <h3 slot="title" variant="corner">REQUEST DETAILS</h3>
                    <div class="content-details">
                          <div class="content-item">
                            <span class="title">Contact</span class="title">
                            <legl-contact-card
                                .contact="${this.paymentRequest.contact}"
                            ></legl-contact-card>
                          </div>

                          <div class="content-item">
                            <span class="title">Created</span class="title">
                            <span>
                                ${formatDateTime(
                                  this.paymentRequest.created_at,
                                )}
                                
                            </span>
                          </div>

                          <div class="content-item">
                            <span class="title">Requested by</span class="title">
                            ${
                              this.paymentRequest.requested_by
                                ? html`<span
                                          >${this.paymentRequest.requested_by.name}
                                      </span>`
                                : "-"
                            }
                            
                          </div>

                        <div class="content-item">
                            <span class="title">Source</span class="title">
                            ${this.paymentRequest.payment_source}
                            
                        </div>

                        ${
                          this.paymentRequest.source === "PAY_REQUEST"
                            ? html` <div class="content-item">
                                      <span class="title"
                                          >Available payment methods</span
                                      >
                                      
                                      <div
                                          class="payment-providers-wrapper"
                                      >
                                          ${
                                            this.paymentRequest.available_payment_providers?.includes(
                                              "stripe",
                                            )
                                              ? html`<span
                                                    class="payment-provider"
                                                    ><lds-icon
                                                        name="lds-icon-CreditCard"
                                                    ></lds-icon
                                                    >Pay by card</span
                                                >`
                                              : nothing
                                          }
                                          ${
                                            this.paymentRequest.available_payment_providers?.includes(
                                              "banked",
                                            )
                                              ? html`<span
                                                    class="payment-provider"
                                                    ><lds-icon
                                                        name="lds-icon-Bank"
                                                    ></lds-icon
                                                    >Pay by bank</span
                                                >`
                                              : nothing
                                          }
                                      </div>
                                      
                                  </div>`
                            : nothing
                        }
                         ${
                           !this.paymentRequest.engage_payment
                             ? html`<div class="content-item">
                                           <span class="title"
                                               >${this.firmReferenceLabel2} </span
                                           >
                                           ${
                                             this.paymentRequest
                                               .matter_reference
                                               ? html`<span
                                                     data-cy-firm-reference-2
                                                     >${this.paymentRequest.matter_reference}
                                                 </span>`
                                               : "-"
                                           }
                                       </div>

                                       <div class="content-item">
                                           <span class="title"
                                               >${this.firmReferenceLabel1} </span
                                           >
                                           ${
                                             this.paymentRequest
                                               .invoice_reference
                                               ? html`<span
                                                     data-cy-firm-reference-1
                                                     >${this.paymentRequest.invoice_reference}
                                                 </span>`
                                               : "-"
                                           }
                                       </div>
                                       <div class="content-item">
                                           <span class="title"
                                               >Legl Reference </span
                                           >
                                           <span data-cy-legl-reference
                                               >${
                                                 this.paymentRequest
                                                   .reference || "-"
                                               }
                                           </span>
                                       </div>
                                       ${
                                         this.paymentRequest.custom_reference
                                           ? html`<div class="content-item">
                                                 <span class="title"
                                                     >${
                                                       this.paymentRequest
                                                         .custom_reference_label ||
                                                       "Firm reference"
                                                     } </span
                                                 >
                                                 ${
                                                   this.paymentRequest
                                                     .custom_reference
                                                     ? html`<span
                                                           data-cy-custom-reference
                                                           >${this.paymentRequest.custom_reference}
                                                       </span>`
                                                     : "-"
                                                 }
                                             </div>`
                                           : ""
                                       }
                                       <div class="content-item attachments" data-testid="attachments">
                                           <span class="title"
                                                  data-testid="attachments-label"
                                               >Attachments</span
                                           >
                                           <span data-cy-custom-reference>
                                              ${this.detailsAttachmentValue}
                                            </span>
                                       </div>`
                             : ""
                         }
                        </div>

                    </div>

                </legl-card>
                <legl-card class="pay-drawer__card">
                    <h3 slot="title" variant="corner">PAYMENT DETAILS</h3>
                    <div data-testid="payment-details-content" class="content-details payment-details">
                        <div>
                          <div class="content-item">
                              <p class="title">Total</p>
                              <p>
                                ${
                                  this.paymentRequest.amount
                                    ? html`<legl-currency
                                              data-testid="total-amount"
                                              .currency=${
                                                this.paymentRequest
                                                  ?.currency
                                              }
                                              locale="${this.getLocale()}"
                                              >${this.paymentRequest.amount}
                                          </legl-currency>`
                                    : "-"
                                }
                              </p>
                            </div>
                            <div class="content-item">
                              <p class="title">To account</p>
                              ${
                                this.paymentRequest.bank_account_name
                                  ? html`<p
                                            >${this.paymentRequest.bank_account_name}
                                        </p>`
                                  : "-"
                              }   
                            </div>
                            <div data-testid="payment-method" class="content-item">
                              <p data-testid="payment-method-label" class="title">Payment method</p>
                              ${this.paymentMethod}
                            </div>
                            ${
                              waffle.flag_is_active("show_payment_details") && this.paymentRequest.payment_details?.payment_method ?
                              html`
                                <div data-testid="card-holder-name" class="content-item">
                                  <p data-testid="card-holder-name-label" class="title">Cardholder name</p>
                                  <span data-testid="card-holder-name-value">${this.paymentRequest.payment_details.payment_method?.holder_name || "-"}</span>
                                </div>
                              ` : nothing
                            }
                            ${
                              waffle.flag_is_active("show_payment_details") && this.paymentRequest.payment_details?.billing_address ?
                              html`
                                <div data-testid="billing-address" class="content-item">
                                  <p data-testid="billing-address-label" class="title">Billing address</p>
                                  <span data-testid="billing-address-value">${formatAddress(this.paymentRequest.payment_details.billing_address) || "-"}</span>
                                </div>
                              ` : nothing
                            }
                            ${
                              !waffle.flag_is_active("show_payment_details") ?
                              html`
                                <div class="content-item">
                                  <p class="title">Billing postcode</p>
                                  ${
                                    this.paymentRequest.postcode
                                      ? html`<p
                                                >${this.paymentRequest.postcode}
                                            </p>`
                                      : "-"
                                  }
                              </div>` : nothing
                            }
                            <div class="content-item">
                              <p class="title">Payment time</p>
                              ${
                                this.paymentRequest.completed_at
                                  ? html`<p
                                            >${formatDateTime(
                                              this.paymentRequest
                                                .completed_at,
                                            )}
                                        </p>`
                                  : "-"
                              }    
                            </div>
                            <div class="content-item">
                              <p class="title">Transfer ID</p>
                              ${
                                this.paymentRequest.transfer_id
                                  ? html`<p
                                            >${this.paymentRequest.transfer_id}
                                        </p>`
                                  : "-"
                              }      
                            </div>
                            <div class="content-item">
                              <p class="title">Transfer time</p>
                              ${
                                this.paymentRequest.transfer_time
                                  ? html`<p
                                            >${formatDateTime(
                                              this.paymentRequest
                                                .transfer_time,
                                            )}
                                        </p>`
                                  : "-"
                              }
                            </div>
                            <div class="content-item">
                              <p class="title">Comment</p>
                              ${
                                this.paymentRequest.comment
                                  ? html`<p
                                            >${this.paymentRequest.comment}
                                        </p>`
                                  : "-"
                              }    
                            </div>
                        </div>
                    </div>
                </legl-card>
                 <div class="pay-request-history">
                    <h2>Payment history</h2>
                     ${this.getEvents().map((event) => {
                       return html`<legl-activity-log
                             user=${this.getEventIndividual(event)}
                             .activityText=${this.getActivityText(event)}
                             date=${event.created_at}
                             comment=${event.payRequestComment}
                         ></legl-activity-log>`;
                     })}
                </div>
            `;
    }
  }
}

if (!customElements.get("legl-pay-review")) {
  customElements.define("legl-pay-review", LeglPayReview);
}
