import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";
import { formatDateTime } from "../../legl-ui/utils/functions.js";


export class ContactsInsightCddItem extends LitElement {
  static get properties() {
    return {
      latestCddStep: { type: Object, attribute: "latest-cdd-step" },
    };
  }

  static get styles() {
    return css`
            :host .flow-details {
                display: block;
                font-size: 0.55rem;
                line-height: 1rem;
            }

            legl-request-status {
                transform: scale(0.75);
                display: flex;
                justify-content: center;
                position: relative;
                left: -7px;
                font-size: 1.25rem;
            }

            .title {
                font-size: 0.75rem;
                font-weight: 600;
                color: ${neutral["800"]};
            }

            .placeholder-content {
                font-size: 1.25rem;
                font-weight: 700;
            }
        `;
  }

  get formattedDate() {
    return formatDateTime(this?.latestCddStep?.reviewed_at);
  }

  render() {
    if (this.latestCddStep && Object.keys(this.latestCddStep).length > 0) {
      return html` <div class="wrapper">
                <legl-request-status
                    class="request-status"
                    status="${this.latestCddStep.status}"
                    slot="content"
                    >${this.latestCddStep.status}
                </legl-request-status>
                <span class="flow-details"
                    >${this.latestCddStep.flow_name}
                    [${this.formattedDate}]</span
                ><br />
                <span class="title">most recent cdd</span>
            </div>`;
    }
    return html`
        <span class="placeholder-content">-</span><br />
        <span class="title">most recent cdd</span>`;
}
}

customElements.define(
  "legl-contacts-insights-cdd-item",
  ContactsInsightCddItem,
);
