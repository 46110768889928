import { dedupeMixin } from "@lion/core";
import {
  IsEmail,
  MaxLength,
  MaxNumber,
  MinLength,
  MinNumber,
  Pattern,
  Required,
  Validator,
} from "@lion/form-core";
import { LionInput } from "@lion/input";
import { loadDefaultFeedbackMessages } from "@lion/validate-messages";
import { css } from "lit";
import { isCreditCardNumer } from "../../legl-ui/utils/functions";
import { LeglBaseInputMixin } from "./base-input-mixin";

loadDefaultFeedbackMessages();

export class LeglRequired extends Required {
  static getMessage(data) {
    return `${data.fieldName} is required`;
  }
}

export class LeglNonEmpty extends Validator {
  static validatorName = "LeglNonEmpty";

  static getMessage({ fieldName }) {
    return `${fieldName} cannot be blank`;
  }

  execute(modelValue) {
    let isEnabled = false;
    const regex = /^\s+$/;

    const isBlankStrings =
      typeof modelValue === "string" && modelValue.match(regex);

    if (isBlankStrings || !modelValue) {
      isEnabled = true;
    }

    return isEnabled;
  }
}

export class LeglIsEmail extends IsEmail {
  static getMessage(data) {
    return `${data.fieldName} must be a valid email address`;
  }
}

export class LeglPattern extends Pattern {
  static getMessage(data) {
    return `${data.fieldName} is invalid`;
  }

  execute(value, pattern = this.param) {
    let updatedPattern = pattern;
    // If regex is string force full match, otherwise use standard explicit regexp matching
    if (typeof updatedPattern === "string") {
      updatedPattern = RegExp(`^${pattern}$`);
    }
    return super.execute(value, updatedPattern);
  }
}

export class LeglMinAmount extends MinNumber {
  static getMessage(data) {
    return `The amount entered must be at least £${data.params}`;
  }
}

export class LeglMaxAmount extends MaxNumber {
  static getMessage(data) {
    return `${data.fieldName} must not be greater than £${data.params}`;
  }
}

export class LeglPhonePaymentMaxAmount extends MaxNumber {
  static getMessage(data) {
    return `Your firm's phone payment limit is set to £${data.params}. To raise your limit, please contact support@legl.com.`;
  }
}

export class LeglMaxLength extends MaxLength {
  static getMessage(data) {
    return `${data.fieldName} must not exceed ${data.params} characters`;
  }
}

export class LeglMinLength extends MinLength {
  static getMessage(data) {
    return `${data.fieldName} must be at least ${data.params} characters long`;
  }
}

export class LeglIsNotCardNumber extends Validator {
  execute(value) {
    const cardNumbers = [
      ...String(value).replaceAll(" ", "").matchAll(/\d+/gm),
    ].filter((match) => isCreditCardNumer(match[0]));
    return cardNumbers.length > 0;
  }

  static getMessage(data) {
    return `${data.fieldName} should not be a credit card number`;
  }
}

export class LeglServerMessagesEmptyValue extends Required {
  static getMessage({ params: message }) {
    return `${message}`;
  }
}

export class LeglInput extends LeglBaseInputMixin(LionInput) {
  static get styles() {
    return [
      super.styles,
      css`
                :host([filled][readonly][shows-feedback-for=""])
                    .input-group:after {
                    content: "";
                }
            `,
    ];
  }
}

if (!customElements.get("legl-input")) {
  customElements.define("legl-input", LeglInput);
}
