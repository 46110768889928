import { LitElement, css, html, nothing } from "lit";
import LeglFormValidationMixin from "../../../../../../../static-src/js/mixins/LeglFormValidationMixin";
import {
  LeglMinAmount,
  LeglPhonePaymentMaxAmount,
  LeglRequired,
} from "../../../../../../legl-ui/input";
import "../../../../../../legl-ui/input/legl-input-amount.js";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { toastService } from "../../../../../../legl-ui/toast";
import { get, post } from "../../../../../../legl-ui/utils/fetch";

class PhonePaymentDetailsForm extends LeglFormValidationMixin(LitElement) {
  static get styles() {
    return css`
            :host {
                color: var(--legl-grey-dark);
                display: block;
                width: 100%;
            }
            :host fieldset {
                border: none;
            }

            :host legend {
                font-weight: bold;
            }

            :host .same-line {
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr;
            }
            .container {
              margin-top: ${spacing.s};
            }
            lds-input, lds-input-amount, lds-select {
              margin-bottom: ${spacing.m};
            }
        `;
  }

  static get properties() {
    return {
      phonePaymentDetails: {
        attribute: false,
      },
      bankAccounts: {
        type: Array,
        attribute: false,
      },
      isLoading: {
        attribute: false,
      },
      referenceFieldConfigs: {
        type: Array,
      },
      minimumPayment: {
        type: Number,
      },
      maximumPhonePayment: {
        type: Number,
      },
      selectedContact: {
        type: Object,
      },
    };
  }

  get formControls() {
    return [...this.shadowRoot.querySelectorAll("lds-input,lds-input-amount")];
  }

  get paymentReferenceFields() {
    if (!this.referenceFieldConfigs) {
      return nothing;
    }
    const fields = this.referenceFieldConfigs.map(
      ({ name, label, visible, required }) => {
        if (visible) {
          return html`<lds-input
                        .name=${name}
                        .label=${label}
                        data-cy-pp-reference="${name}"
                        @model-value-changed=${this.onChange}
                        .modelValue=${this.phonePaymentDetails?.[name]}
                        >${
                          required
                            ? html`<span slot="help-text" class="hint-text"
                                  >Your settings suggest that ${label} is
                                  usually required when taking payments</span
                              >`
                            : ""
                        }</lds-input
                    >`;
        }
      },
    );

    if (fields.length === 2) {
      return html`<div class="same-line">${fields}</div>`;
    }
    return fields;
  }

  get contactFields() {
    return html`
      <input
          type="hidden"
          name="contact_uid"
          .value=${this.selectedContact?.uid}
      />
      <input
          type="hidden"
          name="email"
          .value=${this.selectedContact?.email}
      />
      <input
          type="hidden"
          name="first_name"
          .value=${this.selectedContact?.first_name}
      />
      <input
          type="hidden"
          name="last_name"
          .value=${this.selectedContact?.last_name}
      />
    `;
  }

  constructor() {
    super();
    this.bankAccounts = [];
    this.isLoading = false;
    this.referenceFieldConfigs = [];
  }

  firstUpdated(properties) {
    super.firstUpdated(properties);
    this.fetchBankAccounts();
  }

  async fetchBankAccounts() {
    try {
      const res = await get("/api/bank_accounts/");
      if (res.ok) {
        this.bankAccounts = await res.json();
        this.onChange();
      } else {
        throw new Error(res.status);
      }
    } catch (err) {
      toastService.showError("Error retrieving bank accounts");
    }
  }

  onChange() {
    this.dispatchEvent(
      new CustomEvent("form-values-changed", {
        detail: { isValid: this.isValid, isLoading: this.isLoading },
      }),
    );
  }

  async submit() {
    return this.onSubmit();
  }

  get form() {
    const body = {
      ...Object.fromEntries(
        new FormData(this.shadowRoot.querySelector("form")),
      ),
      transaction_moto: true,
      contact_uid: this.selectedContact.uid,
      client_reference: this.selectedContact.client_reference,
      email: this.selectedContact.email || null,
    };
    body.amount = body.amount.replaceAll(",", "");
    return JSON.stringify(body);
  }

  async onSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (!this.isValid || this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.onChange();
    try {
      const res = await post("/api/payments/", {
        body: this.form,
      });
      if (!res.ok) {
        throw new Error("Unable to create phone payment");
      }
      return await res.json();
    } catch (err) {
      toastService.showError(
        "There was a problem submitting your form. Please try again later.",
      );
      throw new Error(err.message);
    } finally {
      this.isLoading = false;
      this.onChange();
    }
  }

  render() {
    return html`
            <form class="container" @submit=${this.onSubmit}>
              ${this.contactFields}
                  <lds-input-amount
                    .validators=${[
                      new LeglRequired(),
                      new LeglMinAmount(this.minimumPayment),
                      new LeglPhonePaymentMaxAmount(this.maximumPhonePayment),
                    ]}
                    .modelValue=${Number.parseFloat(
                      this.phonePaymentDetails?.amount,
                    )}
                    @model-value-changed=${this.onChange}
                    data-cy-pp-amount
                    label="Amount (including VAT)"
                    name="amount"
                  ></lds-input-amount>
                  ${this.paymentReferenceFields}
                  <lds-select
                      label="To account"
                      name="destination_bank_account_url"
                      .modelValue=${
                        this.phonePaymentDetails?.destination_bank_account_url
                      }
                  >
                      <select slot="input">
                          ${this.bankAccounts.map((account) => {
                            return html` <option value=${account.url}>
                                  ${account.name}
                              </option>`;
                          })}
                      </select>
                  </lds-select>
                  <lds-textarea
                      .modelValue=${this.phonePaymentDetails?.comment}
                      data-cy-pp-comment
                      name="comment"
                      label="Comments (for internal use only)"
                  ></lds-textarea>
          </form>
        `;
  }
}

customElements.define(
  "legl-phone-payment-details-form",
  PhonePaymentDetailsForm,
);
