import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";
import { daysAgo, formatDateTime } from "../../legl-ui/utils/functions.js";
import { pluralize } from "../../legl-ui/utils/pluralize.js";

export class LeglTimestamp extends LitElement {
  static get properties() {
    return {
      timestamp: { type: String },
    };
  }

  static get styles() {
    return css`
            :host {
                font-size: 12px;
                color: ${neutral["800"]};
            }

            :host .datetime-margin {
                margin: 0;
            }
        `;
  }

  get daysAgo() {
    return daysAgo(this.timestamp);
  }

  get formattedDate() {
    return formatDateTime(this.timestamp);
  }

  get isFutureDate() {
    return this.daysAgo < 0;
  }

  formattedDays(isFutureDate) {
    if (isFutureDate) {
      return `In ${pluralize(Math.abs(this.daysAgo), "day")}`;
    }

    return `${pluralize(this.daysAgo, "day")} ago`;
  }

  render() {
    return html`
            <p class="log-datetime datetime-margin">
                ${this.formattedDays(this.isFutureDate)} (${this.formattedDate})
            </p>
        `;
  }
}

if (!customElements.get("legl-timestamp")) {
  customElements.define("legl-timestamp", LeglTimestamp);
}
