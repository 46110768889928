import { LitElement, css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { LeglExpandablePanelMixin } from "../../../../../../../legl-ui/expandable-panel";
import { userConfig } from "../../../config/user-config.js";
export class LeglEngagePanel extends LeglExpandablePanelMixin(LitElement) {
  static get styles() {
    return [
      super.styles,
      css`
                .expandable-content > iframe {
                    width: 100%;
                    height: 70vh;
                    border: 0;
                }

                .is-disabled lds-icon,
                .is-disabled span {
                    color: var(--legl-grey);
                }
                .pdf-download-is-disabled {
                    pointer-events: none;
                    color: var(--legl-grey);
                }

                .download-btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            `,
    ];
  }

  static get properties() {
    return {
      status: {
        type: String,
      },
      canHavePDF: {
        type: Boolean,
      },
      pdfURL: {
        type: String,
      },
      pdfURLWithComments: {
        type: String,
      },
      pdfURLWithLeglAssist: {
        type: String,
      },
      canHavePayment: {
        type: Boolean,
      },
      isOnfidoStep: {
        type: Boolean,
      },
      payment: {
        type: Object,
      },
      filename: {
        type: String,
      },
      elementType: {
        type: String,
      },
    };
  }

  get hasPDF() {
    return !!this.pdfURL;
  }

  get isPDF() {
    if (["document-request", "shared-document"].includes(this.elementType)) {
      return this?.filename?.toLowerCase().endsWith(".pdf");
    }
    return true;
  }

  get hasPDFWithComments() {
    return !!this.pdfURLWithComments;
  }

  get isExpandable() {
    return this.hasPDF && this.isPDF;
  }

  get payURL() {
    if (!this?.payment?.pk) {
      return null
    };

    return `#/drawer/pay-review/?appId=${this.payment.pk}`;
  }

  get hasPayURL() {
    return !!this.payURL;
  }

  get pdfButtonClasses() {
    return {
      action: true,
      "download-btn": true,
      "is-disabled": !this.hasPDF,
    };
  }

  get paymentLinkClasses() {
    return {
      action: true,
      "download-btn": true,
      "is-disabled": !this.hasPayURL,
    };
  }

  get statusIcon() {
    switch (this.status) {
      case "In progress":
      case "Awaiting Signatures":
        return "legl-icon-in-progress-circle";
      case "Processing":
        return "legl-icon-processing-circle";
      case "Completed":
        return "legl-icon-confirm-circle";
      case "Force completed":
      case "Incomplete":
        return "legl-icon-minus-circle";
      case "Declined":
        return "legl-icon-cancel-circle-filled";
      case "Created":
      default:
        return "legl-icon-minus-circle-o";
    }
  }

  get statusIconColour() {
    switch (this.status) {
      case "Completed":
        return "green";
      case "Force completed":
        return "red";
      case "Created":
      case "In progress":
      case "Awaiting Signatures":
      case "Processing":
      case "Incomplete":
      default:
        return "grey";
    }
  }

  get statusTooltipContent() {
    switch (this.status) {
      case "Created":
        return "Step not yet complete by client";
      case "In progress":
      case "Awaiting Signatures":
        return "Step currently in-progress";
      case "Processing":
        return "This report is currently processing and will be available soon";
      case "Completed":
        return "Completed step";
      case "Force completed":
        return "Force completed during this step";
      case "Incomplete":
        return "Force completed before this step. No result";
      case "Declined":
        return "Step declined by client";
      default:
        return "";
    }
  }

  get _expandableContent() {
    return html`<iframe src="${this.pdfURL}?download=0"></iframe>`;
  }

  get _panelBefore() {
    return html`<legl-tooltip variant="top-right">
            <legl-icon
                title=""
                name="${this.statusIcon}"
                color="${this.statusIconColour}"
                size="xx-large"
                padding="padding-right"
            ></legl-icon>
            <span slot="content" class="status">
                ${this.statusTooltipContent}
            </span>
        </legl-tooltip>`;
  }

  get actions() {
    let actions = nothing;
    if (this.canHavePDF) {
      if (this.isOnfidoStep && userConfig.companyProducts.reviewCommentPdf) {
        actions = html`${actions}<a
                        slot="secondary"
                        href="${this.pdfURL}"
                        id="download-report"
                        class="${
                          !this.hasPDF
                            ? "pdf-download-is-disabled"
                            : "download-report"
                        }"
                        >Download report</a
                    >
                    <a
                        slot="secondary"
                        href="${this.pdfURLWithComments}"
                        id="download-report-with-comment"
                        class="${
                          !this.hasPDFWithComments
                            ? "pdf-download-is-disabled"
                            : "download-report-with-comment"
                        }"
                        >Download report with comment</a
                    >`;
      } else {
        actions = html`${actions}<a
                        class="${classMap(this.pdfButtonClasses)}"
                        slot="primary"
                        href="${this.pdfURL}"
                        download="${this.filename}"
                        target="_blank"
                        ><lds-icon name="lds-icon-Download"></lds-icon>
                        <span>download</span>
                    </a>`;
      }
    }
    if (this.canHavePayment) {
      actions = html`${actions}<a
                    class="${classMap(this.paymentLinkClasses)}"
                    slot="primary"
                    href="${this.payURL}"
                    data-cy-view-payment
                    ><legl-icon name="legl-icon-review"></legl-icon>
                    <span>view payment</span>
                </a>`;
    }

    return html`${actions}${super.actions}`;
  }
}

if (!customElements.get("legl-engage-panel")) {
  customElements.define("legl-engage-panel", LeglEngagePanel);
}
