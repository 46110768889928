import * as Sentry from "@sentry/vue";
import axios from "axios";
import { LdsToast } from "../../legl-ui/lds-toast";

declare const CSRF_TOKEN: string;

export const http = axios.create({
  headers: { "X-CSRFTOKEN": CSRF_TOKEN },
});

export function showErrorToast(error: unknown) {
  if (axios.isAxiosError(error)) {
    Sentry.addBreadcrumb({
      category: "Axios request",
      message: "Axios request - error",
      data: error,
      level: "error",
    });

    // let errorMessage = "Something went wrong. Please try again in a moment.";
    let errorTitle: string;
    let errorMessage: string;

    switch (error.response?.status) {
      case 401:
        errorTitle = "You've been logged out due to inactivity.";
        errorMessage = "Please log in again to continue.";
        break;
      case 403:
        errorTitle = "You don't have permission to view this.";
        errorMessage = "Please contact your account admin for access.";
        break;
      case 404:
        errorTitle = "We can't find what you're looking for.";
        errorMessage =
          "If you think this is a mistake, please contact our team.";
        break;
      case 429:
        errorTitle = "Too many requests.";
        errorMessage = "Please wait a moment and try again.";
        break;
      default:
        errorTitle =
          "We encountered a problem and couldn't process your request.";
        errorMessage = "Our team has been notified and is looking into it.";
        break;
    }

    LdsToast.showError({
      title: errorTitle,
      text: errorMessage,
      autoClose: true,
    });
  }
}
