import type { AxiosResponse } from "axios";
import { http } from "../../../../../request-manager/axios-http";

export function getBusinessDetails(businessId: string): Promise<AxiosResponse> {
  return http.get(`/api/businesses/${businessId}/`);
}

export function getBusinessSanctionsMonitoringAlerts(
  businessId: number,
): Promise<AxiosResponse> {
  return http.get(`/api/businesses/${businessId}/ongoing_monitoring/`);
}

export function setOngoingMonitoring(
  businessId: number,
  enabled: string,
): Promise<AxiosResponse> {
  return http.patch(`/api/businesses/${businessId}/monitoring/`, {
    enabled,
  });
}

type NewSanctionsCheckPostData = {
  business_id: number;
  created_automatically: boolean;
  entity_id?: string;
};

type NewSanctionsCheckResponse = {
  id: number;
  business_id: string;
  created_at: string;
  status: string;
};

type NewSanctionsCheckAsyncResponse = {
  job_id: string;
};

export function generateNewSanctionCheck(
  postData: NewSanctionsCheckPostData,
): Promise<AxiosResponse<NewSanctionsCheckResponse>> {
  return http.post<
    NewSanctionsCheckPostData,
    AxiosResponse<NewSanctionsCheckResponse>
  >(`/api/sanctions_check/`, postData);
}

export function generateNewSanctionCheckAsync(
  postData: NewSanctionsCheckPostData,
): Promise<AxiosResponse<NewSanctionsCheckAsyncResponse>> {
  return http.post<
    NewSanctionsCheckPostData,
    AxiosResponse<NewSanctionsCheckAsyncResponse>
  >(`/api/sanctions_check/async_create/`, postData);
}

type RunCompanyReportPost = {
  country_iso_code: string;
  provider_company_id: string;
  business_id: string;
  created_automatically: boolean;
};

export type RunCompanyReportResponse = {
  created_at: string;
  reference: string;
};

export function runCompanyReport(
  postData: RunCompanyReportPost,
): Promise<AxiosResponse<RunCompanyReportResponse>> {
  return http.post<
    RunCompanyReportPost,
    AxiosResponse<RunCompanyReportResponse>
  >("/api/company_report/generate_company_report/", postData);
}

export function disableCompanyReportOngoingMonitoring(
  businessId: string,
): Promise<AxiosResponse> {
  return http.post(
    `/api/businesses/${businessId}/disable_company_report_ongoing_monitoring/`,
  );
}
