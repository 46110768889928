import { LionSelect } from "@lion/select";
import { css } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

import { LeglBaseInputMixin } from "./base-input-mixin.js";

export class LeglSelect extends LeglBaseInputMixin(LionSelect) {
  static get styles() {
    return [
      super.styles,
      css`
                :host .input-group:after {
                    content: "";
                    position: absolute;
                    display: block;
                    right: 10px;
                    top: 50%;
                    margin-top: -3px;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid currentColor;
                    color: ${neutral["800"]};
                    z-index: 0;
                }

                :host .form-field__label {
                    top: -2px;
                    font-size: 10px;
                    right: auto;
                }
                /* Fallback to default dropdown arrow in IE */
                @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    :host .input-group:after {
                        display: none;
                    }
                }
                :host([shows-feedback-for="error"]) .input-group:after {
                    content: "";
                    position: absolute;
                    display: block;
                    right: 10px;
                    top: 50%;
                    margin-top: -3px;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid currentColor;
                    color: ${neutral["800"]};
                    z-index: 0;
                }

                :host([filled][shows-feedback-for=""]) .input-group:after {
                    content: "";
                    position: absolute;
                    display: block;
                    right: 10px;
                    top: 50%;
                    margin-top: -3px;
                    width: 0;
                    height: 0;
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 7px solid currentColor;
                    color: ${neutral["800"]};
                    z-index: 0;
                }

                :host .input-group__after {
                    position: absolute;
                    right: 30px;
                    z-index: 1;
                    top: 10px;
                }

                :host .input-group {
                    padding: 0;
                    height: 100%;
                }

                .input-group__container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .input-group__container
                    > .input-group__input
                    ::slotted(.legl-form-control) {
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 11px 35px 1rem 0.5rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            `,
    ];
  }
}

if (!customElements.get("legl-select")) {
  customElements.define("legl-select", LeglSelect);
}
