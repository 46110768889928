import { router } from "@/router";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { I18NextVue, i18next } from "../../../legl-ui/translations";
import App from "./App.vue";

const pinia = createPinia();

const app = createApp(App);
// @ts-ignore
if (HEROKU_APP_NAME) {
  Sentry.init({
    app,
    // @ts-ignore
    environment: HEROKU_APP_NAME.replace(/^(legl-)/, ""),
    dsn: "https://d42c9333aa9224cda51aa493fef54fd0@o4507180312297472.ingest.de.sentry.io/4507180805980241",
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["VueSPA"],
        behaviour: "drop-error-if-exclusively-contains-third-party-frames",
      }),
    ],
  });
}
// @ts-ignore
app.use(router);
app.use(pinia);
app.use(I18NextVue, {
  i18next,
});

app.mount("#app");

// Reload the page when there's a preload error
window.addEventListener("vite:preloadError", (event) => {
  window.location.reload();
});
