import { LionStep, LionSteps } from "@lion/steps";
import { css, html } from "lit";
import "../../../legl-ui/button";
import "../../../legl-ui/icon";

export { LeglStep } from "./legl-step.js";
export { LeglStepProgress } from "./legl-step-progress.js";

export class LeglSteps extends LionSteps {
  static get styles() {
    return css`
            :host {
                display: grid;
                gap: 10px;
                grid-template:
                    "back-btn progress" 80px
                    "slot slot" auto;
                height: 100%;
            }

            .steps__back {
                grid-area: back-btn;
                align-self: center;
                width: 50px;
            }
            [part="steps__progress"] {
                grid-area: progress;
                justify-self: end;
                align-self: center;
            }
            [part="steps__buttons"] {
                grid-area: buttons;
                justify-self: end;
                align-self: center;
            }

            ::slotted(*) {
                grid-area: slot;
                width: 100%;
                justify-self: center;
            }
            .green {
                color: var(--legl-green);
            }
            .grey {
                color: var(--legl-grey);
            }
        `;
  }

  static get properties() {
    return {
      progress: {
        type: Object,
      },
      disableBackButton: {
        attribute: "disable-back-button",
        type: Boolean,
      },
    };
  }

  get steps() {
    const defaultSlot = this.shadowRoot?.querySelector("slot:not([name])");
    if (defaultSlot) {
      return defaultSlot
        .assignedNodes()
        .filter((node) => node.nodeType === Node.ELEMENT_NODE);
    }
    return [];
  }

  get isBackDisabled() {
    return this.current === 0 || this.disableBackButton;
  }

  updated(changedProperties) {
    if (!changedProperties.has("progress")) {
      this.progress = html`
                <legl-step-progress
                    current-step=${this.current}
                    .steps=${this.steps}
                ></legl-step-progress>
            `;
    }
  }

  render() {
    return html`
            <legl-button
                data-cy-back-button
                class="steps__back"
                size="small"
                variant="transparent"
                icon="legl-icon-chevron-left"
                .disabled=${this.isBackDisabled}
                @click=${(e) => {
                  if (!e.currentTarget.disabled) {
                    this.previous();
                  }
                }}
            >
                Back
            </legl-button>

            <article part="steps__progress">${this.progress}</article>

            <slot></slot>
        `;
  }
}

if (!customElements.get("legl-steps")) {
  customElements.define("legl-steps", LeglSteps);
}
