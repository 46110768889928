import { LitElement, css, html, nothing } from "lit";
import {
  danger,
  neutral,
  primary,
  warning,
} from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";

export class LdsAlert extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
            }
            .message-wrapper {
                display: flex;
                border-radius: 4px;
                padding: ${spacing.s};
                justify-content: space-between;
            }
            p {
                margin: 0;
            }

            a {
                color: ${neutral["800"]};
            }

            .message {
                display: flex;
            }
            .title {
                font-weight: bold;
                color: ${neutral["800"]};
            }
            .with-title {
                display: block;
                color: ${neutral["800"]};
            }
            .with-title::slotted(*),
            .with-title .description {
                margin-top: ${spacing.xs};
            }
            .message-wrapper.info {
                background-color: ${neutral["100"]};
                border: 1px solid ${neutral["400"]};
            }
            .message-wrapper.warning {
                background-color: ${warning["100"]};
                border: 1px solid ${warning["400"]};
            }
            .message-wrapper.success {
                background-color: ${primary["100"]};
                border: 1px solid ${primary["400"]};
            }
            .message-wrapper.error {
                background-color: ${danger["100"]};
                border: 1px solid ${danger["400"]};
            }
            .message-icon {
                margin-right: ${spacing.xs};
                font-size: 19px;
            }
            .message-icon.success {
                color: ${primary["400"]};
            }
            .message-icon.info,
            .message-icon.warning {
                color: ${neutral["700"]};
            }
            .message-icon.error {
                color: ${danger["500"]};
            }
            .content-top-right {
              flex-shrink: 0;
            }
        `;
  }

  static get properties() {
    return {
      // types: success, warning, error, info
      type: {},
      title: {},
      message: {},
    };
  }

  get icon() {
    switch (this.type) {
      case "info":
        return "InfoOutlined";
      case "warning":
        return "WarningOutlined";
      case "success":
        return "CheckCircleFilled";
      case "error":
        return "ErrorFilled";
    }
  }

  render() {
    return html`<div class=${`message-wrapper ${this.type}`}>
            <div class="message">
                <lds-icon
                    class=${`message-icon ${this.type}`}
                    name="lds-icon-${this.icon}"
                ></lds-icon>
                <div class="title-container">
                    ${
                      this.title
                        ? html`<p class="title">${this.title}</p>`
                        : nothing
                    }
                    <slot
                        name="content"
                        class=${this.title ? "with-title" : ""}
                    >
                        ${
                          this.message
                            ? html`<p class="description">${this.message}</p>`
                            : nothing
                        }
                    </slot>
                </div>
            </div>
            <div class="content-top-right">
                <slot name="content-top-right"></slot>
            </div>
        </div>`;
  }
}

if (!customElements.get("lds-alert")) {
  customElements.define("lds-alert", LdsAlert);
}
