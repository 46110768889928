import { LitElement, css, html, nothing } from "lit";
import "../../../../../../../legl-ui/action-bar";
import { neutral } from "../../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../../legl-ui/lds-modal";
import { LdsToast } from "../../../../../../../legl-ui/lds-toast";
import { patch, post, put } from "../../../../../../../legl-ui/utils/fetch";
import location from "../../../../../../../legl-ui/utils/location.js";
import { createCancellationModal } from "./modals/payment-plan-cancellation/payment-plan-cancellation-modal";

export class LeglPayReviewActions extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-action-bar-min-width: 0;
            }

            .copy-button {
                border: 1px solid ${neutral["700"]};
                background: ${neutral["100"]};
                box-sizing: border-box;
                color: ${neutral["700"]};
                border-radius: 4px;
                padding: 4px 14px;
                font-size: 11.5px;
                width: 100px;
            }

            .copy-button:hover:not([disabled]) {
                background-color: ${neutral["800"]};
                color: var(--legl-white, #ffffff);
                cursor: pointer;
            }

            .copy-button:disabled {
                opacity: 0.4;
                cursor: not-allowed;
                color: ${neutral["700"]};
            }

            .action-buttons {
                display: flex;
            }

            .icon {
                padding-right: 7px;
                bottom: -1px;
                position: relative;
            }

            .action-button.-copy {
                border-radius: 4px;
                font-size: 16px;
                line-height: 48px;
                color: ${neutral["800"]};
                text-decoration: none;
                padding: 0px 12px;
                border: none;
                background: white;
                margin: 0px;
                text-align: left;
                cursor: pointer;
                justify-content: flex-start;
            }

            .action-button.-copy:hover {
                background: rgb(238, 238, 238);
            }
        `;
  }
  static get properties() {
    return {
      paymentRequest: {
        type: Object,
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.paymentRequest = {};
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
  }

  async sendPaymentEmail(reference, remindersEnabled, id) {
    await post(`/api/payments/send_payments_email/${reference}/`);

    if (remindersEnabled) {
      await patch(`/api/payments/${id}/`, {
        body: JSON.stringify({
          reminders: {
            enabled: true,
          },
        }),
      });
    }

    window.dispatchEvent(new CustomEvent("reload-payments-table"));
  }

  openSendEmailModal() {
    LdsModal.create({
      title: "Send email",
      slotComponent: "send-initial-payment-email-modal",
      dataTestId: "send-initial-payment-email-modal",
      slotComponentProperties: {
        reference: this.paymentRequest.reference,
        id: this.paymentRequest.id,
        email: this.paymentRequest.contact.email,
        sendPaymentEmail: this.sendPaymentEmail,
      },
    });
  }

  get emailButtonDisabled() {
    return this.paymentRequest.contact?.email === null;
  }

  get sendEmailButton() {
    if (
      this.paymentRequest.status !== "Created" ||
      this.paymentRequest.engage_request
    )
      return nothing;
    return html`<lds-button
            icon="lds-icon-Send"
            variant="outlined"
            small
            .disabled=${this.emailButtonDisabled}
            data-cy-send-email-button
            @click=${this.openSendEmailModal}
        >
            Send email</lds-button
        >`;
  }

  get isPaymentPlan() {
    return this.paymentRequest.source === "SCHEDULED_PAYMENT";
  }

  reloadPaymentDrawer() {
    const reviewDrawer = document.querySelector("legl-pay-review");
    reviewDrawer.fetchReviewInformation();
  }

  async retryPayment(id) {
    const response = await put(`/api/payments/${id}/retry/`);
    const resJson = response.status !== 500 ? await response.json() : null;
    if (response.ok) {
      LdsToast.showSuccess({
        text: "Payment successfully retried.",
        autoClose: true,
      });
    } else {
      if (resJson?.error) {
        LdsToast.showError({
          title: "Payment declined",
          text: resJson.error,
        });
      } else {
        LdsToast.showError({
          title: "Something went wrong",
          text: "Please try again later.",
        });
      }
    }
    this.reloadPaymentDrawer();
  }

  async closeLink(reference) {
    const currentLocation = location.getCurrentLocation();
    const formData = new FormData();
    formData.append("reference", reference);
    const body = new URLSearchParams(formData).toString();
    const response = await post("/payment-mark-closed/", {
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    if (response.ok) {
      this.dispatchEvent(
        new CustomEvent("close-link-clicked-from-drawer", {
          bubbles: true,
          composed: true,
        }),
      );
      if (currentLocation.indexOf("payment-requests") > -1) {
        window.dispatchEvent(new CustomEvent("reload-payments-table"));
      }
    } else {
      LdsToast.showError({
        text: "Something went wrong. Please try again later.",
      });
    }
  }

  retryPaymentModal() {
    LdsModal.create({
      title: "Retry payment?",
      description: html`
                <p>
                    We recommend you try only once per day to avoid the card
                    being blocked.<br />We'll let you know immediately if the
                    card issuer has authorised the payment.
                </p>
            `,
      primaryButtonAttributes: {
        variant: "contained",
        colour: "primary",
        onClick: () => this.retryPayment(this.paymentRequest.id),
        label: "Retry",
      },
      hideCloseBtn: false,
    });
  }

  async markPaid(reference) {
    const currentLocation = location.getCurrentLocation();
    const formData = new FormData();
    formData.append("reference", reference);
    const body = new URLSearchParams(formData).toString();
    const response = await post("/mark-paid/", {
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
    if (response.ok) {
      if (
        currentLocation.indexOf("payment-requests") > -1 ||
        currentLocation.indexOf("payment-plans") > -1
      ) {
        this.dispatchReloadPaymentTableEvent();
      }
      this.dispatchEvent(
        new CustomEvent("close-link-clicked-from-drawer", {
          bubbles: true,
          composed: true,
        }),
      );
    } else {
      LdsToast.showError({
        text: "Something went wrong. Please try again later.",
      });
    }
  }

  dispatchReloadPaymentTableEvent() {
    this.dispatchEvent(
      new CustomEvent("reload-payments-table", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  markPaidModal() {
    LdsModal.create({
      title: "Mark this payment as paid?",
      description:
        "If the payment has been made outside of the Legl platform, you can use this to mark the payment as paid and close the payment link.",
      primaryButtonAttributes: {
        variant: "contained",
        colour: "primary",
        onClick: () => this.markPaid(this.paymentRequest.reference),
        label: "Mark as paid",
      },
      hideCloseBtn: false,
    });
  }

  get manualRetryEnabled() {
    return (
      this.paymentRequest.status === "Created" &&
      this.paymentRequest.off_session === true
    );
  }

  openCloseLinkModal() {
    LdsModal.create({
      title: "Close request link",
      description:
        "You are about to close a request link, this procedure is irreversible. If your client has already started their request you can force complete their request instead to retain any complete steps. Do you want to proceed?",
      primaryButtonAttributes: {
        variant: "contained",
        colour: "danger",
        onClick: () => this.closeLink(this.paymentRequest.reference),
        label: "Close payment link",
      },
      hideCloseBtn: false,
    });
  }

  openCancelPlanModal() {
    createCancellationModal(this.paymentRequest.payment_schedule_id, () => {
      this.reloadPaymentDrawer();
      this.dispatchReloadPaymentTableEvent();
    });
  }

  async callObservability() {
    await post("/api/observability/", 
      {
        body: JSON.stringify({
          type: "pay_request_link_clicked",
          payment_reference: this.paymentRequest.reference,
        }),
      },
    );
  }

  render() {
    return html`
            <div class="action-buttons" data-cy-pay-action-buttons>
                ${
                  !this.paymentRequest.off_session
                    ? this.sendEmailButton
                    : nothing
                }
                <legl-action-bar data-cy-pay-action-bar class="action-dropdown">
                    <legl-copy-button
                        data-cy-copy-link-extra-information-button
                        class="action-button -copy"
                        slot="secondary"
                        .showToast=${true}
                        copy-value="${this.paymentRequest?.link}"
                        .disabled="${
                          this.paymentRequest.disable_buttons ||
                          this.paymentRequest.engage_payment ||
                          this.paymentRequest.off_session
                        }"
                        @click="${this.callObservability}"
                    >
                        Copy link
                    </legl-copy-button>
                    ${
                      !this.paymentRequest.off_session
                        ? html`
                              <button
                                  data-cy-mark-closed-extra-information-button
                                  .disabled=${
                                    this.paymentRequest.disable_buttons
                                  }
                                  slot="secondary"
                                  @click=${
                                    this.isPaymentPlan
                                      ? this.openCancelPlanModal
                                      : this.openCloseLinkModal
                                  }
                              >
                                  ${
                                    this.isPaymentPlan
                                      ? "Cancel Plan"
                                      : "Close Link"
                                  }
                              </button>
                          `
                        : nothing
                    }
                    <button
                        data-cy-mark-paid-extra-information-button
                        .disabled=${this.paymentRequest.disable_buttons}
                        @click=${this.markPaidModal}
                        slot="secondary"
                    >
                        Mark Paid
                    </button>
                    ${this.paymentRequest.source === "SCHEDULED_PAYMENT" ?
                      html`<button
                        data-cy-retry-payment
                        .disabled=${!this.manualRetryEnabled}
                        @click=${this.retryPaymentModal}
                        slot="secondary"
                    >
                        Retry payment
                    </button>` : nothing
                    }

                </legl-action-bar>
            </div>
        `;
  }
}

if (!customElements.get("legl-pay-review-actions")) {
  customElements.define("legl-pay-review-actions", LeglPayReviewActions);
}
