import { LitElement, css, html } from "lit";
import { LabelWithIcon } from "../../../legl-ui/label-with-icon";
import { neutral } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import { STATUS_CONFIG } from "./status_config.js";

export class LeglRequestStatus extends LabelWithIcon {
  static get properties() {
    return {
      status: { type: String },
    };
  }

  constructor() {
    super();
    this.labelIconMap = STATUS_CONFIG;
  }

  set status(value) {
    this.label = value;
  }

  get status() {
    return this.label;
  }
}

if (!customElements.get("legl-request-status")) {
  customElements.define("legl-request-status", LeglRequestStatus);
}
