import { LitElement, css, html, nothing } from "lit";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import "../../../../../../legl-ui/lds-icon";
import { spacing } from "../../../../../../legl-ui/lds-spacing";

export class Accordion extends LitElement {
  static get styles() {
    return css`
            :host {
                width: 100%;
                display: block;
                text-align: center;
                padding: 1em 2em;
                transition: opacity 0.5s ease-in-out;
            }

            :host([locked]) {
                opacity: 0.5;
            }

            :host([complete][locked]) {
                opacity: 1;
            }

            @media only screen and (max-width: 400px) {
                padding: 1em;
            }

            summary::-webkit-details-marker {
                /* Style the default marker */
                display: none;
            }
            .summary-text-container {
                padding: 1rem 2rem;
            }
            .hidden {
                display: none;
            }

            :host .content-slot ::slotted(section) {
                display: block;
                max-height: 0;
                opacity: 0;
                transition: opacity 0.5s ease-in 0s,
                    max-height 0s ease-in-out 0.5s;
                overflow: hidden;
            }

            :host .content-slot.is-expanded ::slotted(section) {
                max-height: max-content;
                height: auto;
                opacity: 1;
                transition: opacity 0.5s ease-out 0.5s,
                    max-height 0s ease-in-out 0.5s;
            }

            .title-container {
                display: flex;
                justify-content: space-between;
                font-size: 1.3em;
            }
            .title {
                display: flex;
                align-items: center;
                align-self: center;
                font-size: 1em;
                font-weight: 700;
                margin: 0;
            }
            .icon {
                user-select: none;
                outline: none;
                color: ${neutral["700"]};
            }

            lds-icon {
                display: flex;
                transition: color 0.5s ease-in-out;
                color: ${neutral["300"]};
                padding-right: ${spacing.s};
            }

            .clickable {
                cursor: pointer;
            }

            :host([hidden]) {
                display: none;
            }

            :host([complete][locked]) .icon {
                display: none;
            }

            :host([locked]) .icon {
                color: ${neutral["300"]};
            }

            :host([complete]) lds-icon {
                color: ${primary["500"]};
            }
        `;
  }

  render() {
    return html`
            <div
                data-component-container
                @click="${(e) => {
                  if (!this.locked) {
                    if (this.showAlpha) {
                      this.clickAlpha();
                    } else {
                      this.clickBeta();
                    }
                  }
                }}"
            >
                <div class="summary-text-container">
                    <div class="title-container">
                        <h2 class="title">
                            ${
                              this.showStatusIcon
                                ? html` <lds-icon
                                      large
                                      class="success-icon"
                                      name="lds-icon-CheckCircleFilled"
                                  ></lds-icon>`
                                : nothing
                            }

                            <slot name="title"></slot>
                        </h2>
                        <span class="icon">
                            <div class="${this.showAlpha ? "" : "hidden"}">
                                <slot name="icon-alpha"></slot>
                            </div>
                            <div class="${this.showAlpha ? "hidden" : ""}">
                                <slot name="icon-beta"></slot>
                            </div>
                        </span>
                    </div>
                </div>
                <div
                    class="content-slot ${
                      this.showAlpha ? "is-expanded" : "hidden"
                    }"
                >
                    <slot name="content-alpha"></slot>
                </div>
                <div
                    class="content-slot ${
                      this.showAlpha ? "hidden" : "is-expanded"
                    }"
                >
                    <slot name="content-beta" data-cy-accordion-content-beta>
                    </slot>
                </div>
            </div>
        `;
  }

  constructor() {
    super();

    this.showAlpha = false;
    this.locked = false;
    this.hidden = false;
    this.complete = false;
  }

  static get properties() {
    return {
      showAlpha: {
        type: Boolean,
        attribute: "show-alpha",
        reflect: true,
      },
      // locked prevents the accordion being interacted with
      locked: { type: Boolean, reflect: true },
      form: { type: Boolean },
      hidden: { type: Boolean, reflect: true },
      complete: { type: Boolean, reflect: true },
      showStatusIcon: {
        type: Boolean,
        attribute: "show-status-icon",
      },
    };
  }

  clickAlpha() {
    this.dispatchEvent(new CustomEvent("click-alpha", {}));
  }

  clickBeta() {
    this.dispatchEvent(new CustomEvent("click-beta", {}));
  }
}

if (!customElements.get("legl-accordion")) {
  customElements.define("legl-accordion", Accordion);
}
