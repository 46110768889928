import { LitElement, css, html, nothing } from "lit";
import "../../../legl-ui/avatar";
import { neutral } from "../../../legl-ui/lds-colours";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import "../../../legl-ui/timestamp";
import { stringToHTML } from "../../../legl-ui/utils/functions.js";

export class LeglActivityLog extends LitElement {
  static get properties() {
    return {
      comment: { type: String },
      user: { type: String },
      activityText: { type: String },
      date: { type: String },
    };
  }

  static get styles() {
    return css`
            .container {
                display: flex;
                padding-bottom: 24px;
            }
            .flex-column {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                margin-left: 12px;
            }
            .log-activity {
                font-size: 14px;
                font-weight: 400;
                color: ${neutral["800"]};
            }
            .activity-text-margin {
                margin: 2px 0 0;
            }
            .avatar-margin {
                margin-right: 12px;
                flex-shrink: 0;
                align-items: center;
            }

            .card {
                background-color: ${neutral["200"]};
                padding: 10px;
                margin: 10px 0;
                border-radius: 3px;
                font-size: 12px;
                color: ${neutral["800"]};
            }
            pre {
                ${typographyPresets.small}
                white-space: pre-wrap;
                margin: 0;
            }
            .bold,
            .semi-bold {
                ${typographyPresets.mediumBold};
            }
        `;
  }

  get activityHTML() {
    return html`<p class="log-activity activity-text-margin">
            ${stringToHTML(this.activityText)}
        </p>`;
  }

  render() {
    return html`
            <span class="container">
                <span class="flex-column">
                    <legl-timestamp timestamp=${this.date}></legl-timestamp>
                    ${this.activityHTML}
                    ${
                      this.comment
                        ? html`<div class="card" data-cy-activity-comment>
                              <pre>${this.comment}</pre>
                          </div>`
                        : ""
                    }
                </span>
            </span>
        `;
  }
}

if (!customElements.get("legl-activity-log")) {
  customElements.define("legl-activity-log", LeglActivityLog);
}
