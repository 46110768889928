import { LitElement, css, html } from "lit";

export class LeglCarousel extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
                --legl-button-background-color: #2f37f2;
                --legl-button-background-color-hover: #5c6cff;
                --legl-button-padding: 0.5rem 2rem;
                --legl-button-font-size: 20px;
                --legl-button-font-weight: 100;
                --legl-button-border-radius: 8px;
                --legl-carousel-dot: #c4c4c4;
                --legl-carousel-dot-active: #747474;
            }
            .button-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 15px 0;
            }
            .button-container.-partial-footer lds-button {
                display: none;
            }
            .button-container.-partial-footer .dots-container {
                display: none;
            }
            .button-container.-partial-footer.-show-skip .dots-container {
                display: block;
                margin-bottom: 13px;
            }
            .button-container.-partial-footer.-show-skip lds-button {
                display: block;
            }
            .button {
                text-align: center;
            }
            .skip-instructions {
                margin-top: 12px;
            }
            .skip-instructions:hover {
                cursor: pointer;
            }
            .dots-container {
                text-align: center;
            }
            .dot {
                height: 7px;
                width: 7px;
                margin: 0 2px;
                background-color: var(--legl-carousel-dot);
                border-radius: 50%;
                display: inline-block;
                transition: background-color 0.6s ease;
            }

            .active {
                height: 9px;
                width: 9px;
                background-color: var(--legl-carousel-dot-active);
            }

            /* Fading animation */
            .fade {
                -webkit-animation-name: fade;
                -webkit-animation-duration: 1.5s;
                animation-name: fade;
                animation-duration: 1.5s;
            }

            @-webkit-keyframes fade {
                from {
                    opacity: 0.4;
                }
                to {
                    opacity: 1;
                }
            }

            @keyframes fade {
                from {
                    opacity: 0.4;
                }
                to {
                    opacity: 1;
                }
            }

            .instruction__button-container {
                width: 100%;
                display: flex;
                justify-content: centre;
            }
            .instructions__button {
                margin: 50px auto;
                color: #ffffff;
                max-width: 100%;
                border-radius: 8px;
                border: none;
                background-color: #4a70fa;
                font-size: 17px;
                padding: 16px 70px;
                cursor: pointer;
                outline: none;
            }

            .instruction-block__icon-grid {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 52px 20px;
                margin-bottom: 50px;
            }
            .instruction-block {
                margin-bottom: 30px;
            }
            .instruction-block legl-options-selector {
                display: block;
                margin-top: 25px;
            }
            .instruction-block__text {
                margin: 0;
                font-size: 16px;
                color: var(--legl-grey-dark);
                text-align: center;
            }
            .instruction-block__text p {
                margin: 0;
                text-align: left;
            }
            .instruction-block__text p:first-child {
                font-weight: bold;
            }
            .instruction-block__title--2 {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                text-align: center;
                color: var(--legl-grey-dark);
                margin: 30px 0;
            }
            .instruction-block__title--3 {
                margin: 0 0 50px 0;
                color: var(--legl-button-background-color);
                font-size: 16px;
                font-weight: bold;
            }
            .instruction-block__image {
                height: 350px;
                width: 100%;
            }
            .instruction-block__image--small {
                height: 350px;
            }
            .instruction-block__image-container {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        `;
  }

  static get properties() {
    return {
      steps: { type: Object },
      stepsCount: { attribute: false },
      lastSlide: { attribute: false },
      completeAction: {
        type: Function,
        attribute: false,
      },
      hidden: { attribute: false },
      hideFooter: { attribute: false },
    };
  }

  constructor() {
    super();
    this.slideIndex = 1;
    this.stepsCount = 0;
    this.lastSlide = false;
    this.hidden = false;
    this.carousel = "";
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
  }

  updated() {
    if (!this.style.display) {
      this.showSlides(this.slideIndex);
    }
  }

  hide() {
    this.hidden = true;
  }

  show() {
    this.hidden = false;
  }

  next() {
    const nextSlide = (this.slideIndex += 1);
    if (this.slideIndex > 4 && !this.shadowRoot.querySelector(".-show-skip")) {
      this.shadowRoot
        .querySelector(".button-container")
        .classList.add("-show-skip");
    }

    if (nextSlide === this.stepsCount) {
      this.lastSlide = true;
      this.showSlides(this.stepsCount);
    } else {
      this.showSlides(nextSlide);
    }
  }

  showSlides(index) {
    let i;
    const slides = this.shadowRoot.querySelectorAll(".container");
    if (this.stepsCount === 0) {
      slides.forEach((el) => {
        if (el.nodeType === 1) {
          // update the step count se we can render the correct number of "dots"
          this.stepsCount += 1;
          // add the class to each step so it animates
          el.classList.add("fade");
        }
      });
      this.lastSlide = false;
    }

    const dots = this.shadowRoot.querySelectorAll(".dot");
    if (dots.length) {
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      dots[this.slideIndex - 1].className += " active";
    }
    if (index > slides.length) {
      this.slideIndex = 1;
    }
    if (index < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.slideIndex - 1].style.display = "block";
  }

  render() {
    return html`${
      this.hidden ? (this.style.display = "none") : this.carouselComponent
    }`;
  }

  get carouselComponent() {
    if (this.steps) {
      return html` ${this.steps.map(
        (block) => html` <div class="container">${block}</div>`,
      )}
            ${
              !this.hideFooter
                ? html` <div class="dots-container">${this.renderDots}</div>
                      <div class="button-container">
                          ${
                            this.lastSlide
                              ? this.getStartedButton
                              : this.continueButton
                          }
                          <a
                              class="skip-instructions"
                              href="javascript:;"
                              @click="${() => this.completeAction()}"
                              >Skip instructions</a
                          >
                      </div>`
                : html`
                      <div class="button-container -partial-footer">
                          ${
                            this.lastSlide
                              ? this.getStartedButton
                              : this.continueButton
                          }
                          <lds-button
                              class="skip-instructions"
                              colour="neutral"
                              variant="text"
                              .icon="null"
                              ?is-loading="false"
                              ?disabled="false"
                              @click="${() => this.completeAction()}"
                          >
                              Skip instructions
                          </lds-button>
                      </div>
                  `
            }`;
    }
  }

  get renderDots() {
    const dotLengthArray = Array.from(Array(this.stepsCount).keys());
    return dotLengthArray.map(
      (el, i) => html`<span class=${i === 0 ? "dot active" : "dot"}></span> `,
    );
  }

  get getStartedButton() {
    return html`
            <legl-button
                class="button"
                variant="default"
                @click=${() => this.completeAction()}
                data-cy-back-button
            >
                Get Started
            </legl-button>
        `;
  }

  get continueButton() {
    return html`
            <legl-button
                class="button"
                variant="default"
                @click=${() => this.next()}
                data-cy-back-button
            >
                Continue
            </legl-button>
        `;
  }
}

if (!customElements.get("legl-carousel")) {
  customElements.define("legl-carousel", LeglCarousel);
}
