import { AdyenCheckout, ApplePay, Card } from "@adyen/adyen-web";
import "@adyen/adyen-web/styles/adyen.css";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { toastService } from "../../../legl-ui/toast";
import { put } from "../../../legl-ui/utils/fetch";
import "../../lds-alert";
import "./styles.css";

import { LitElement, html, nothing } from "lit";
import { WaffleFlags } from "../../../waffle-flags";
import { countries } from "./countries";

export class LeglAdyenComponent extends LitElement {
  #MOUNT_ELEMENT_ID = "#pay-adyen-component";

  static get properties() {
    return {
      session: {},
      source: { type: String },
      clientKey: {},
      environment: {},
      onPaymentCompleted: {},
      sessionProcessUrl: {},
      isPaymentSubmitting: { type: Boolean },
      warningMessageTitle: { type: String },
      isCardValid: { type: Boolean },
      cardComponent: { state: true },
      isDesktop: { state: true },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", () => {
      this.isDesktop = window.innerWidth > 768;
    });
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.warningMessageTitle = "";
    this.isPaymentSubmitting = false;
    this.isCardValid = true;
    this.isDesktop = window.innerWidth > 768;
  }

  async submitAdyenPayment() {
    if (this.isPaymentSubmitting) {
      return;
    }
    this.isPaymentSubmitting = true;
    this.cardComponent.submit();
    if (!this.cardComponent.isValid) {
      this.isPaymentSubmitting = false;
    }
  }

  async postcodeAddressLookUp(value, actions, sessionId) {
    let formattedData = [];
    let rawData = [];
    const trimmedValueLength = value.replaceAll(" ", "").length;
    // Only request API when atleast minimum postcode characters are entered
    if (trimmedValueLength >= 5 && trimmedValueLength <= 7) {
      formattedData = await axios
        .get(`/json/postcode/${value}`)
        .then((res) => {
          rawData = res.data;
          return res.data.map(
            (
              {
                flat_number,
                building_number,
                building_name,
                street,
                sub_street,
                town,
                postcode,
              },
              index,
            ) => {
              // filter out empty values
              const nameParts = [
                flat_number,
                building_name,
                building_number,
                street,
                sub_street,
                town,
                postcode,
              ].filter((item) => item);
              const name = nameParts.join(", ");

              // Attempt to collect house number or name from some part of the address
              let houseNumberOrName =
                building_number || building_name || flat_number;

              // filter out empty values & building name or flat number if already used for houseNumberOrName
              const streetParts = [
                flat_number,
                building_name,
                street,
                sub_street,
              ];
              street = streetParts
                .filter((item) => item && item !== houseNumberOrName)
                .join(", ");

              return {
                id: index + 1,
                name: name,
                city: town,
                street: street,
                houseNumberOrName: houseNumberOrName,
                postalCode: postcode,
                country: "GB",
              };
            },
          );
        })
        .catch((error) => {
          // Remove existing address options if postcode is invalid
          if (error.response && error.response.status === 404) {
            return [];
          } else {
            Sentry.captureException(error);
            actions.reject("Something went wrong, try adding manually.");
          }
        });
    }

    // pass back observability
    try {
      axios.post(
        "/api/observability/",
        {
          type: "onfido_postcode_lookup",
          session_id: sessionId,
          address_query: value,
          matched_addresses_count: rawData.length,
          matched_addresses: rawData,
        },
        { headers: { "X-CSRFTOKEN": CSRF_TOKEN } },
      );
    } catch (error) {
      console.error(error);
    }
    actions.resolve(formattedData);
  }

  async initializeAdyen() {
    const translations = {
      "en-US": {
        "address.search.contextualText":
          "Enter a UK postcode to find an address",
        "select.noOptionsFound":
          "Please enter a valid UK postcode or select to enter an address manually",
        // Hide contextual text for these common input fields
        "creditCard.expiryDate.contextualText": "",
        "creditCard.securityCode.contextualText.3digits": "",
        "creditCard.securityCode.contextualText.4digits": "",
      },
    };

    const configuration = {
      session: this.session,
      clientKey: this.clientKey,
      environment: this.environment,
      translations: translations,
      onPaymentCompleted: (result, component) =>
        this.onPaymentCompleted(result, component),
      onPaymentFailed: (result, component) =>
        this.onPaymentFailed(result, component),
      onError: (error, component) =>
        console.error(error.name, error.message, error.stack, component),
    };

    // Create an instance of AdyenCheckout using the configuration object.
    this.checkout = await AdyenCheckout(configuration);

    this.cardComponent = new Card(this.checkout, {
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired: true,
      showPayButton: false,
      onBinLookup: (binLookup) => this.checkCardIssuer(binLookup),
      onAddressLookup:
        this.source === "PHONE_PAYMENT"
          ? (value, actions) =>
              this.postcodeAddressLookUp(value, actions, this.session.id)
          : undefined,
      placeholders: {
        cardNumber: "1234 5678 90123 4567",
        expiryDate: "MM/YY",
        securityCodeThreeDigits: "3 digits",
        securityCodeFourDigits: "4 digits",
      },
      data: {
        billingAddress: { country: "GB" },
      },
      styles: {
        base: {
          color: "#253658",
          fontFamily: "Lato, sans-serif",
          fontSize: "16px",
          lineHeight: "14px",
          fontSmoothing: "antialiased",
        },
        placeholder: {
          color: "#C5CAD3",
          fontFamily: "Lato, sans-serif",
          fontSize: "16px",
          lineHeight: "14px",
        },
      },
    }).mount(this.#MOUNT_ELEMENT_ID);

    if (this.applePay) {
      await this.initializeApplePay();
    }
  }

  async getCompanyPaymentSettings() {
    await axios
      .get("/api/company_payment_settings/")
      .then((response) => {
        this.companyPaymentSettings = response.data;
      })
      .catch((error) => {
        Sentry.captureException(
          error,
          "Failed to fetch company payment settings in Adyen component.",
        );
      });
  }

  get validCountries() {
    this.warningMessageTitle = "We are unable to take a payment at this moment";
    if (!this.companyPaymentSettings) {
      return ["GB"];
    }
    if (
      this.companyPaymentSettings.accept_european_cards &&
      this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle =
        "We are unable to accept card payments from this country";
      return [...countries.european, ...countries.international, "GB"];
    } else if (
      this.companyPaymentSettings.accept_european_cards &&
      !this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle =
        "We are only able to accept UK or EU card payments";
      return [...countries.european, "GB"];
    } else if (
      !this.companyPaymentSettings.accept_european_cards &&
      this.companyPaymentSettings.accept_international_non_european_cards
    ) {
      this.warningMessageTitle = "We are unable to accept EU card payments";
      return [...countries.international, "GB"];
    }
    this.warningMessageTitle = "We are only able to accept UK card payments";
    return ["GB"];
  }

  checkCardIssuer(binLookup) {
    if (this.validCountries.includes(binLookup.issuingCountryCode)) {
      this.isCardValid = true;
    } else {
      this.isCardValid = false;
    }
    this.submitTelemetry(binLookup);
  }

  submitTelemetry(binLookup) {
    try {
      axios.post(
        "/api/observability/",
        {
          type: "payment_card_entered",
          session_id: this.session.id,
          accept_european_cards:
            this.companyPaymentSettings?.accept_european_cards,
          accept_international_non_european_cards:
            this.companyPaymentSettings
              ?.accept_international_non_european_cards,
          card_brand: binLookup?.detectedBrands?.[0],
          card_issuing_country_code: binLookup?.issuingCountryCode,
          card_allowed: this.isCardValid,
        },
        { headers: { "X-CSRFTOKEN": CSRF_TOKEN } },
      );
    } catch (error) {
      console.error(error);
    }
  }

  get applePay() {
    return (
      this.source !== "PHONE_PAYMENT" &&
      this.checkout?.paymentMethodsResponse?.paymentMethods.some(
        (paymentMethod) => paymentMethod.type === "applepay",
      )
    );
  }

  async initializeApplePay() {
    const applePayComponent = new ApplePay(this.checkout, {
      supportedCountries: this.validCountries,
    });
    applePayComponent
      .isAvailable()
      .then(() => {
        this.hasMultiplePaymentMethods = true;
        applePayComponent.mount("#applepay-container");
        document.getElementById("or-pay-with").innerHTML = "Or pay with";
      })
      .catch((e) => {
        //Apple Pay is not available
      });
  }

  dispatchPhonePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("phone-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  dispatchEngagePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("engage-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  async onPaymentCompleted(result, component) {
    try {
      const res = await put(this.sessionProcessUrl, {});
    } catch (exception) {
      console.log(exception);
    } finally {
      if (this.source === "PHONE_PAYMENT") {
        this.dispatchPhonePaymentComplete();
      } else if (this.source === "ENGAGE_PAYMENT") {
        this.dispatchEngagePaymentComplete();
      } else {
        if (window.isNewPayApp) {
          window.dispatchEvent(new CustomEvent("payment-completed"));
        } else {
          window.location.href = `/pay/success/`;
        }
      }
    }
  }

  async onPaymentFailed(result, component) {
    toastService.showError(result.resultCode);
    component.mount(this.#MOUNT_ELEMENT_ID);
    this.isPaymentSubmitting = false;
    if (result.resultCode === "Error") {
      Sentry.captureMessage(`Adyen payment failed.·${result.resultCode}`);
    }
  }

  render() {
    return html`
      ${
        this.applePay
          ? html`
            <div data-cy-adyen-apple-pay id="applepay-container"></div>
            <div data-cy-or-pay-with id="or-pay-with"></div>`
          : nothing
      }
      ${
        this.isCardValid
          ? nothing
          : html`
            <lds-alert
              class="adyen-alert"
              data-testid="issuing-country-error-message"
              type="info"
              title="${this.warningMessageTitle}"
              message="${this.companyPaymentSettings ? "Please try again with another card." : "Please refresh the page and try again"}"
            />`
      }
      <div id="pay-adyen-component" data-testid="pay-adyen-component"></div>
      <div class="container-payment-submit">
        ${
          this.paymentAmount && this.cardComponent
            ? html`
              <lds-button
                style="${this.isDesktop ? "" : "width: 100%"}"
                data-cy-payment-submit-button
                role="button"
                id="payment-submit"
                colour="primary"
                variant="contained"
                icon="lds-icon-LockOutlined"
                ?is-loading="${this.isPaymentSubmitting}"
                ?disabled="${this.isPaymentSubmitting || !this.isCardValid}"
                @click="${this.submitAdyenPayment}"
              >Pay £${this.paymentAmount}
              </lds-button>`
            : nothing
        }
      </div>
    `;
  }
}

if (!customElements.get("legl-adyen-component")) {
  customElements.define("legl-adyen-component", LeglAdyenComponent);
}
