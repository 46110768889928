import { css, html } from "lit";
import { Onfido } from "onfido-sdk-ui";

import * as Sentry from "@sentry/browser";
import "../../../../../../legl-ui/fullscreen-loading-spinner";
import "../../../../../../legl-ui/icon";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import { pushEvent } from "../../../../../../legl-ui/utils";
import { ElementBase } from "../element_base.js";
import "./external-helper-screens";
import "./internal-helper-screens";
function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

export class OnfidoElement extends ElementBase {
  static get styles() {
    return [
      css`
                .troubleshooting-message {
                    margin-bottom: 24px;
                    padding-left: 24px;
                    padding-right: 24px;
                    color: ${neutral["800"]};
                }

                .troubleshooting-message a {
                    color: ${primary["500"]};
                }

                @media only screen and (max-width: 1030px) {
                    .troubleshooting-message {
                        padding-left: 16px;
                    }
                }
                .processing-message {
                  padding: 0 2rem;
                  color: ${neutral["700"]};
                }
            `,
    ];
  }
  constructor() {
    super();
    this.steps = [];
    this.instructionSteps = [];
    this.isInternal = false;
    this.onfidoMount = document.createElement("div");
    this.selectedUploadOption = "";
  }

  static get properties() {
    return {
      ...super.properties,
      token: { type: String },
      steps: { type: Array },
      instructionSteps: { type: Array },
      isInternal: { type: Boolean },
      loadingElement: { type: HTMLElement },
      selectedUploadOption: { type: String },
      variant: { type: String },
      companyPhoneNumber: { type: String },
      companyEmail: { type: String },
      companyName: { type: String },
    };
  }

  get stepsConfig() {
    if (!this.steps.length) {
      return [
        {
          type: "complete",
          options: {
            message: "Processing Identity Check",
          },
        },
      ];
    }

    return [
      {
        type: "welcome",
        options: {
          title: "Submit identity document",
          descriptions: [
            "To finish verifying your identity, we need an identity document.",
            `Please follow these steps to take and upload a photograph of an identity document. ${
              !this.isInternal ? "Do not upload a PDF." : ""
            }`,
            `${
              this.isInternal
                ? "Please note: any photos taken during this process will not be stored on your smartphone"
                : ""
            }`,
          ],
        },
      },
      ...this.steps.map((step) => {
        if (step === "face") {
          return {
            type: "face",
            options: {
              requestedVariant: this.variant || "video",
            },
          };
        }
        if (step === "document") {
          // The onfido widget doesn't work great on IE so force users to switch to mobile
          return {
            type: "document",
            options: {
              forceCrossDevice: isIE(),
              documentTypes: {
                passport: true,
                driving_licence: true,
                national_identity_card: true,
              },
            },
          };
        }
        return step;
      }),
      {
        type: "complete",
        options: {
          message: "Upload complete!",
        },
      },
    ];
  }

  showLoadingElement() {
    this.hideLoadingElement();
    this.loadingElement = document.createElement(
      "legl-fullscreen-loading-spinner",
    );
    document.body.appendChild(this.loadingElement);
  }

  hideLoadingElement() {
    if (this?.loadingElement?.parentElement) {
      this.loadingElement.parentElement.removeChild(this.loadingElement);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("removeSpinner", this.hideLoadingElement);
  }

  async disconnectedCallback() {
    this.hideLoadingElement();
    this.removeEventListener("removeSpinner", this.hideLoadingElement);
    await this.onfidoWidget?.tearDown();
  }

  firstUpdated() {
    // Onfido won't mount inside the shadow DOM so we are currently mounting it outside
    // and opening it as a modal
    this.onfidoMount.id = "onfido-mount";
    this.querySelector('section[slot="onfido-slot"]').appendChild(
      this.onfidoMount,
    );

    this.onfidoWidget = Onfido.init({
      version: "v14.41.0", // This version needs to match the version in package.json
      token: this.token,
      smsNumberCountryCode: "GB",
      language: {
        locale: "en_GB",
        phrases: {
          upload_guide: {
            image_detail_good_label:
              "The photo should clearly show your document and signature",
            subtitle: this.isInternal
              ? ""
              : "PDFs, scans and photocopies are not accepted",
          },
          doc_submit: {
            button_link_upload: this.buttonLinkUploadText(),
            subtitle: `${
              this.isInternal
                ? "Take a photo with your phone. The photo will be securely uploaded and will not be stored on your device."
                : "Take a photo with your phone"
            }`,
          },
          doc_confirmation: {
            alert: {
              glare_title: "Poor image quality",
              glare_detail:
                "Make sure the document is clearly visible, in full colour, and is not obscured by glare.",
            },
          },
        },
      },
      onComplete: (data) => {
        this.showLoadingElement();
        data["upload_method"] = this.selectedUploadOption;
        this.sendResults(data);
      },
      onError: (error) => {
        Sentry.addBreadcrumb({
          category: "onfido",
          message: "Onfido widget error",
          level: "error",
          data: error,
        });
        Sentry.captureException(error);
      },
      steps: this.stepsConfig,
    });
    this.onfidoMount.hidden = !this.instructionsHidden;
    window.addEventListener("userAnalyticsEvent", (event) => {
      pushEvent("onfido:userAnalyticsEvent", event.detail);
    });
  }

  buttonLinkUploadText() {
    if (!this.isInternal) {
      return "or upload photo – no PDFs, scans or photocopies";
    } else {
      return this.selectedUploadOption === "take-photo" ||
        this.selectedUploadOption === "upload-photo"
        ? "or upload a photo - no PDFs, scans or photocopies"
        : "upload photo or scan";
    }
  }

  handleResultResponse() {
    this.hideLoadingElement();
    this.sendComplete(
      !this.isInternal
        ? "<p>We're processing your identity information...</p>"
        : "<p>We're processing your client's identity information...</p>",
    );
    // Disable this and the Basic Info element to prevent editing
    this.lock(["basic-information"]);
  }

  completeAction = (selectedRoute) => {
    this.onfidoMount.hidden = false;
    this.onfidoMount.style.display = "flex";
    //this.onfidoMount.style["justify-content"] = "center";
    this.selectedUploadOption = selectedRoute;
  };

  render() {
    return this.isInternal && !this.steps.includes("document")
      ? html`
      <div class="processing-message">
        <p>We're processing your client's identity information...</p>
      </div>`
      : html`
            ${
              this.isInternal
                ? html`<legl-internal-helper-screens
                      .completeAction=${this.completeAction}
                  ></legl-internal-helper-screens>`
                : html`<legl-external-helper-screens
                      .companyPhoneNumber=${this.companyPhoneNumber}
                      .companyEmail=${this.companyEmail}
                      .companyName=${this.companyName}
                      .completeAction=${this.completeAction}
                  ></legl-external-helper-screens>`
            }
            <div class="troubleshooting-message">
                If you have any difficulties completing this step, please see
                our help guide
                <a
                    href="https://legl.com/support/troubleshooting-taking-a-photo-of-my-id"
                    target="_blank"
                    >here</a
                >.
            </div>
            <slot name="onfido-slot">
                <div id="onfido-mount"></div>
            </slot>
        `;
  }
}

if (!customElements.get("legl-element-onfido")) {
  customElements.define("legl-element-onfido", OnfidoElement);
}
