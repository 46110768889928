import { LitElement, css, html } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { userConfig } from "../../config/user-config.js";

export class LeglReviewerSelection extends LitElement {
  static get styles() {
    return css`
            .container {
                margin-top: 30px;
            }
            .field-container {
                display: grid;
                grid-template-columns: calc(50% - 8px) calc(50% - 8px);
                row-gap: 16px;
                column-gap: 16px;
                margin-top: 16px;
            }
            .heading {
                font-size: ${spacing.s};
                color: var(--lds-colour-neutral-800);
            }
            .label {
                color: ${neutral["800"]};
                font-size: ${spacing.s};
                font-weight: 700;
                display: flex;
                align-items: flex-start;
            }

            .info-text {
                font-size: ${spacing.s};
                color: ${neutral["800"]};
            }

            .subtitle {
                font-size: 10px;
                margin-top: 0;
            }
        `;
  }

  static get properties() {
    return {
      reviewer: { type: String, attribute: false },
      note: { type: String, attribute: false },
    };
  }

  constructor() {
    super();
    this.reviewer = userConfig.userId;
    this.note = "";
  }

  dispatchCustomEvent() {
    this.dispatchEvent(
      new CustomEvent("reviewer-selection-change", {
        detail: {
          reviewer: this.reviewer,
          note: this.note,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html` <div class="container">
            <h2 class="heading">Who should review this?</h2>
            <p class="info-text">
                Assign anyone within your firm to review the results. The
                default is yourself, but you can change it here with an optional
                note for email notification when ready for review.
            </p>
            <div class="field-container">
                <label class="label" for="reviewer"
                    >Who will review the workflow results?</label
                >
                <lds-select
                    id="reviewer"
                    label="Reviewer"
                    .modelValue=${this.reviewer || "Select One"}
                    @model-value-changed=${(e) => {
                      this.reviewer = e.target.modelValue;
                      this.dispatchCustomEvent();
                    }}
                >
                    <select slot="input">
                        <option disabled>Select One</option>
                        ${userConfig.engageReviewers.map(
                          ({ value, label }) =>
                            html`
                                    <option
                                        ?selected=${value === userConfig.userId}
                                        .value=${value}
                                    >
                                        ${label}
                                    </option>
                                `,
                        )}
                    </select>
                </lds-select>
                <label class="label" for="note"
                    >Provide an optional note for the assigned reviewer:
                </label>
                <lds-textarea
                    id="note"
                    label="Comment"
                    class="source-input"
                    @model-value-changed=${(e) => {
                      this.note = e.target.modelValue;
                      this.dispatchCustomEvent();
                    }}
                >
                </lds-textarea>
            </div>
        </div>`;
  }
}

if (!customElements.get("legl-reviewer-selection")) {
  customElements.define("legl-reviewer-selection", LeglReviewerSelection);
}
