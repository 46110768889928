import HelloSign from "hellosign-embedded";
import { LitElement, css, html } from "lit";
import { connect } from "pwa-helpers";
import { userConfig } from "../../../apps/lawyers-app/static-src/js/config/user-config";
import store from "../../../redux/lawyers-admin";
import { toastService } from "../../toast";
import { post } from "../../utils/fetch";

export class LeglMiniAccordionESig extends connect(store)(LitElement) {
  static get styles() {
    return css`
            :host p:nth-child(2) {
                margin-bottom: 0;
            }
            .doc-upload-container {
                display: grid;
                grid-template-columns: 1fr 180px;
            }
            .tag-button-container {
                display: flex;
                justify-content: end;
                align-items: center;
                justify-self: end;
            }
        `;
  }

  static get properties() {
    return {
      step: {
        type: Object,
      },
      fileToESign: {
        type: Object,
      },
      signatureButtonText: {
        type: String,
      },
      disabled: {
        type: Boolean,
      },
      signatureRequestId: {
        type: String,
      },
      signatureRequestFileName: {
        type: String,
      },
      handleSigantureUpload: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.signatureButtonText = "Tag document";
    this.disabled = true;
  }

  addFile() {
    this.fileToESign = this.shadowRoot.querySelector(
      "legl-input-file-upload",
    ).dropzone.files[0];
    this.disabled = false;
    this.signatureRequestFileName = this.fileToESign?.name;
  }

  removeFile() {
    const dropzoneFiles = this.shadowRoot.querySelector(
      "legl-input-file-upload",
    ).dropzone.files;
    if (dropzoneFiles.length === 0) {
      this.fileToESign = null;
    }
    /*
             When an additional file is uploaded the removeFile is called straight away to remove it
             In that case we already have 1 document uploaded and don't want to disable the button.
         */
    if (dropzoneFiles.length !== 1) {
      this.disabled = true;
      this.signatureRequestId = null;
    }
    this.handleSigantureUpload({
      stepId: this.step.pk,
      fileName: null,
      signatureRequestId: null,
    });
    this.signatureButtonText = "Tag document";
  }

  stateChanged(state) {
    this.selectedContact = state.engage.selectedContact;
  }

  tagDocument() {
    this.disabled = true;
    const formData = new FormData();
    formData.append("file[0]", this.fileToESign);
    formData.append("requester_email_address", userConfig.userEmail);
    formData.append("type", "request_signature");
    formData.append("signers[0][name]", this.selectedContact.name);
    formData.append(
      "signers[0][email_address]",
      this.selectedContact.email || userConfig.userEmail,
    );
    formData.append("subject", "Request Signature");
    formData.append("is_for_embedded_signing", "1");
    formData.append("skip_me_now", "0");
    formData.append("field_options[date_format]", "DD / MM / YYYY");

    const requestOptions = {
      body: formData,
      // as we are using FormData the "Content-Type": "application/json" Header will break the request
      removeJSONHeader: true,
      redirect: "follow",
    };

    const path = this.signatureRequestId
      ? `/api/hellosign/unclaimed_draft/edit_and_resend/${this.signatureRequestId}`
      : "/api/hellosign/unclaimed_draft/create_embedded";

    post(path, this.signatureRequestId ? null : requestOptions)
      .then((response) => response.json())
      .then((res) => {
        const { claim_url, signature_request_id } = res.unclaimed_draft;
        const client = new HelloSign({
          clientId: userConfig.hellosign_client_id,
        });
        // HelloSign events: https://app.hellosign.com/api/embeddedSigningWalkthrough
        client.on("close", () => (this.disabled = false));
        client.on("finish", () => {
          // set the signatureRequestId only after the document is signed as we need to generate a new url
          // each time we want to tag a document first time
          this.signatureRequestId = signature_request_id;
          this.handleSigantureUpload({
            stepId: this.step.pk,
            fileName: this.signatureRequestFileName,
            signatureRequestId: this.signatureRequestId,
          });
          this.signatureButtonText = "Edit document";
          this.disabled = false;
        });
        client.open(claim_url);
      })
      .catch(() => {
        toastService.showError(
          "Error uploading and generating the document. Please try again.",
        );
        this.disabled = false;
      });
  }

  render() {
    return html`<div>
            <p>
                Document Name: ${this.step?.element_config?.document_name || ""}
            </p>
            <p data-cy-e-sign-file-name>
                File: ${this.signatureRequestFileName}
            </p>
            <div class="doc-upload-container">
                <legl-input-file-upload
                    data-cy-flow-template-upload-document-url
                    .maxFiles=${1}
                    .acceptedFiles=${[
                      "pdf",
                      "doc",
                      "docx",
                      "txt",
                      "jpg",
                      "jpeg",
                      "png",
                    ]}
                    .autoProcessQueue=${false}
                    param-name="document"
                    .extraTokenData=${{ stepId: this.step.pk }}
                    @file-added="${() => {
                      this.addFile();
                    }}"
                    @file-removed="${() => {
                      this.removeFile();
                    }}"
                    ><input
                        slot="input"
                        type="hidden"
                        name="bespoke_document_details[]"
                /></legl-input-file-upload>
                <div class="tag-button-container">
                    <legl-button
                        icon="legl-icon-pencil"
                        size="medium"
                        ?disabled=${this.disabled}
                        @click=${this.tagDocument}
                    >
                        ${this.signatureButtonText}
                    </legl-button>
                </div>
            </div>
        </div> `;
  }
}

if (!customElements.get("legl-mini-accordion-e-sig")) {
  customElements.define("legl-mini-accordion-e-sig", LeglMiniAccordionESig);
}
