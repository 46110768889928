import { LitElement, css, html } from "lit";
import "../../legl-ui/icon";
import { neutral } from "../../legl-ui/lds-colours";
import "../../legl-ui/menu";
import "../../legl-ui/tooltip-old";

export class LeglActionBarItem extends LitElement {
  static get styles() {
    return css`
            :host a.action-invoker {
                color: ${neutral["700"]};
                text-decoration: none;
            }

            :host([slot="primary"]) .action-invoker {
                color: ${neutral["700"]};
                box-sizing: border-box;
                border-radius: var(--legl-action-bar-item-border-radius, 6px);
                font-size: 12px;
                font-weight: 700;
                overflow: hidden;
                cursor: pointer;
                border: none;
                position: relative;
                padding: 15px 5px;
                background: none;
                transform-origin: 50% 50%;
                transition: transform 0.125s ease-in-out;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                text-decoration: none;
                text-align: center;
                margin: 0 5px;
                max-width: 90px;
                display: grid;
                grid-template-rows: 20px auto;
            }

            :host([slot="primary"]) .action-invoker:active,
            :host([slot="primary"]) .action-invoker:focus {
                outline: none;
                text-decoration: none;
            }

            :host([slot="primary"]) .action-invoker:hover {
                background-color: ${neutral["200"]};
                text-decoration: none;
            }

            :host([slot="primary"][disabled]) .action-invoker,
            :host([slot="primary"].is-disabled) .action-invoker {
                color: var(--legl-grey);
                cursor: not-allowed;
                pointer-events: none;
            }

            :host([slot="primary"]) legl-icon {
                font-size: 14px;
            }

            :host([slot="primary"]) legl-tooltip {
                display: inline-block;
            }

            :host([slot="primary"]) .fullsize {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0;
                height: 100%;
                width: 100%;
            }
        `;
  }

  static get properties() {
    return {
      icon: {
        type: String,
      },
      href: {
        type: String,
      },
      tooltip: {
        type: String,
      },
      size: {
        type: String,
      },
    };
  }

  get iconTemplate() {
    if (this.icon) {
      return html`<legl-icon name="${this.icon}"></legl-icon>`;
    }
  }

  get innerTemplate() {
    return html`${this.iconTemplate} <slot></slot>`;
  }

  getTooltipTemplate(inner) {
    if (this.tooltip) {
      return html`<legl-tooltip variant="top-center"
                >${inner}<span slot="content"
                    >${this.tooltip}</span
                ></legl-tooltip
            >`;
    }
    return inner;
  }

  render() {
    if (this.href) {
      return this.getTooltipTemplate(html`<a
                class="action-invoker ${
                  this.size === "fullsize" ? "fullsize" : ""
                }"
                href="${this.href}"
                data-cy-action-invoker-link
                >${this.innerTemplate}</a
            >`);
    }
    return this.getTooltipTemplate(html`
            <button class="action-invoker" data-cy-action-invoker-button>
                ${this.innerTemplate}
            </button>
        `);
  }
}

if (!customElements.get("legl-action-bar-item")) {
  customElements.define("legl-action-bar-item", LeglActionBarItem);
}
