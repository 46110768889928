import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../legl-ui/lds-colours";

function isIntersectingViewportBottom(el) {
  if (el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >=
      (window.innerHeight || document.documentElement.clientHeight)
    );
  }
}
export class LeglMenu extends LitElement {
  static get styles() {
    return css`
            :host {
                position: absolute;
            }

            .label {
                font-family: "Lato", sans-serif;
            }

            .activator {
                cursor: pointer;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                background: none;
                border: 0;
            }

            .activator__icon {
                position: relative;
                border-radius: 50%;
                padding: 0 2px 2px 2px;
                font-size: 22px;
                color: ${neutral["800"]};
                box-sizing: border-box;
                width: 32px;
                height: 32px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            .activator__icon > legl-icon {
                margin-top: 5px;
            }

            .activator:hover .activator__icon {
                background: var(--legl-grey-active);
            }

            .menu[hidden] {
                display: none;
            }

            .menu {
                background: white;
                display: flex;
                flex-direction: column;
                position: absolute;
                padding: 0;
                top: 30px;
                min-width: var(--legl-menu-min-width, 100px);
                right: 0;
                border-radius: 4px;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12),
                    0px 2px 4px rgba(0, 0, 0, 0.24);
                z-index: 1;
            }

            .menu.is-up {
                top: auto;
                bottom: 30px;
            }

            .menu ::slotted(a),
            .menu ::slotted(button),
            .menu ::slotted(legl-inline-action),
            .menu ::slotted(legl-action-bar-item) {
                font-family: "Lato", sans-serif;
                font-style: normal;
                font-weight: normal;
                border-radius: 4px;
                font-size: 16px;
                line-height: 48px;
                color: ${neutral["800"]};
                text-decoration: none;
                padding: 0px 12px;
                border: none;
                background: white;
                margin: 0;
                text-align: left;
                cursor: pointer;
                white-space: nowrap;
            }

            .menu ::slotted(a:hover),
            .menu ::slotted(a.is-active),
            .menu ::slotted(button:hover),
            .menu ::slotted(button.is-active),
            .menu ::slotted(legl-inline-action:hover),
            .menu ::slotted(legl-inline-action.is-active),
            .menu ::slotted(legl-action-bar-item:hover),
            .menu ::slotted(legl-action-bar-item.is-active) {
                background: #eeeeee;
            }

            .menu ::slotted(a.is-disabled),
            .menu ::slotted(legl-inline-action.is-disabled),
            .menu ::slotted(button[disabled]),
            .menu ::slotted(legl-action-bar-item[disabled]) {
                color: ${neutral["400"]};
                cursor: not-allowed;
                pointer-events: none;
            }
        `;
  }

  constructor() {
    super();
    this.closed = true;
    this.boundOnClose = this.onBodyClose.bind(this);
    this.icon = "lds-icon-More";
  }

  static get properties() {
    return {
      closed: { type: Boolean },
      icon: {
        type: String,
      },
      label: {
        type: String,
      },
      direction: {
        attribute: false,
      },
    };
  }

  get menuClasses() {
    return {
      menu: true,
      "is-up": this.direction === "up",
    };
  }

  onBodyClose(e) {
    if (!e.composedPath().includes(this)) {
      this.onClose();
    }
  }

  onClose() {
    document.body.removeEventListener("mousedown", this.boundOnClose);
    this.closed = true;
    this.direction = "down";
  }

  onOpen() {
    this.closed = false;
    document.body.addEventListener("mousedown", this.boundOnClose);
    const menu = this.shadowRoot.querySelector(".menu");
    if (menu && isIntersectingViewportBottom(menu)) {
      this.direction = "up";
    } else {
      this.direction = "down";
    }
  }

  onToggle() {
    if (this.closed) {
      this.onOpen();
    } else {
      this.onClose();
    }
  }

  render() {
    return html`
            <button
                aria-label="More actions"
                title="More actions"
                class="activator"
                @click=${(e) => {
                  e.stopPropagation();
                  this.onToggle();
                }}
            >
                <div class="activator__icon">
                    <lds-icon .name=${this.icon}></lds-icon>
                </div>
                ${
                  this.label
                    ? html`<span class="label">${this.label}</span>`
                    : ""
                }
            </button>

            <div class="${classMap(this.menuClasses)}" .hidden=${this.closed}>
                <slot
                    @click=${(e) => {
                      e.stopPropagation();
                      this.onToggle();
                    }}
                ></slot>
            </div>
        `;
  }
}

if (!customElements.get("legl-menu")) {
  customElements.define("legl-menu", LeglMenu);
}
