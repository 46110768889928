import { LitElement, css, html } from "lit";
import { when } from "lit/directives/when.js";
import LeglFormValidationMixin from "../../../../../../../static-src/js/mixins/LeglFormValidationMixin";
import { LeglRequired } from "../../../../../../legl-ui/input";
import {
  LeglIsDate,
  LeglMaxDate,
  LeglMinDate,
} from "../../../../../../legl-ui/input/legl-input-date";
import "../../../../../../legl-ui/input/legl-input-file-upload";
import "../../../../../../legl-ui/lds-button";
import {
  danger,
  neutral,
  primary,
  warning,
} from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing/index";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { del, post } from "../../../../../../legl-ui/utils/fetch";

export class LeglRiskAssessmentUpload extends LeglFormValidationMixin(
  LitElement,
) {
  static get styles() {
    return css`
            .risk_assessment-intro {
                margin-bottom: ${spacing.l};
            }

            .risk-assessment-upload-form__inputs-wrapper {
                width: 50%;
            }

            legl-input-file-upload {
                width: 100%;
                margin: ${spacing.xs} 0 0 0;
            }

            .risk-assessment-upload-form__label {
                margin: 0;
                margin-bottom: ${spacing.xs};
                ${typographyPresets.bodyBold}
            }

            .risk-assessment-upload-form__label-description {
                margin: 0;
                ${typographyPresets.small}
            }

            .risk-assessment-upload-form__document-upload {
                margin: ${spacing.s} 0 ${spacing.xs};
            }

            .risk-assessment-upload-form__document-upload h4 {
                margin: 0;
            }

            .risk-assessment-upload-form__controls {
                width: 100%;
                margin-top: ${spacing.xl};
                padding-top: ${spacing.s};
                padding-bottom: ${spacing.xl};
                gap: ${spacing.s};
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                border-top: 1px solid ${neutral["200"]};
            }

            .risk-assessment-upload-form__risk-score-wrapper {
                width: 100%;
                height: 48px;
                border-radius: 4px;
                border-top: 1px solid ${neutral["300"]};
                border-right: 1px solid ${neutral["300"]};
                border-bottom: 1px solid ${neutral["300"]};
                margin-bottom: ${spacing.xs};
            }

            .risk-assessment-upload-form__risk-score-wrapper.high {
                border-left: 4px solid ${danger["500"]};
            }

            .risk-assessment-upload-form__risk-score-wrapper.medium {
                border-left: 4px solid ${warning["500"]};
            }

            .risk-assessment-upload-form__risk-score-wrapper.low {
                border-left: 4px solid ${primary["500"]};
                margin-bottom: 0;
            }

            .radio-container {
                ${typographyPresets.body};
                display: block;
                position: relative;
                line-height: 16px;
                padding: 16px 0 16px 44px;
                width: 276px;
                cursor: pointer;
                user-select: none;
            }

            .radio-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            .radio-checkmark {
                box-sizing: border-box;
                position: absolute;
                left: 10px;
                height: 20px;
                width: 20px;
                border: 2px solid ${neutral["700"]};
                border-radius: 50%;
            }

            .radio-container:hover input ~ .radio-checkmark {
                background-color: ${neutral["300"]};
                border: 2px solid ${neutral["700"]};
            }

            .radio-container input:checked ~ .radio-checkmark {
                background-color: white;
                border: 2px solid ${primary["500"]};
            }

            .radio-checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .radio-container input:checked ~ .radio-checkmark:after {
                display: block;
            }

            .radio-container .radio-checkmark:after {
                top: 4px;
                left: 4px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: ${primary["500"]};
            }

            .risk-assessment-upload-form__alert {
                margin-top: ${spacing.l};
                margin-bottom: ${spacing.l};
            }

            .risk-assessment-upload-form__validation-message {
                text-align: right;
                color: rgb(132, 8, 0);
                font-size: 0.8rem;
            }
        `;
  }

  constructor() {
    super();
    this.isSubmitting = false;
    this.documentIsUploading = null;
  }

  static get properties() {
    return {
      isSubmitting: { state: true },
      documentIsUploading: { state: true },
      riskScore: { state: true },
      showValidationErrors: { state: true },
    };
  }

  get drawer() {
    return document.querySelector("legl-drawer");
  }

  get form() {
    const body = {
      ...Object.fromEntries(
        new FormData(this.shadowRoot.querySelector("form")),
      ),
    };
    body.completed_at = this.formatCompletedAtDate(body.completed_at);
    body.document = this.documentUrl;
    return JSON.stringify(body);
  }

  formatCompletedAtDate(date) {
    if (date) {
      const [dd, mm, yyyy] = `${date}`.split("/");
      return `${yyyy}-${mm}-${dd}`;
    }
    return null;
  }

  _fileAdded() {
    this.documentIsUploading = true;
  }

  _addDocument(token) {
    if (token) {
      this.documentUrl = token.url;
      this.documentIsUploading = false;
    }
  }

  async _removeDocument(token) {
    if (token) {
      await del(this.documentUrl);
      this.documentIsUploading = false;
      this.documentUrl = null;
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.showValidationErrors = true;
    this.validate();
    if (this.isValid && this.riskScore && this.documentUrl) {
      this.isSubmitting = true;
      try {
        const res = await post(
          `/api/contacts/${this.params.get("contactId")}/risk_assessments/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            body: this.form,
          },
        );
        const JSONResponse = await res.json();
        if (!res.ok) {
          let errorMessage = "Error uploading the risk assessment";
          if (JSONResponse.detail) {
            errorMessage = JSONResponse.detail;
          } else if (Object.keys(JSONResponse).length) {
            errorMessage = Object.entries(JSONResponse)
              .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
              .join(", ");
          }
          throw new Error(errorMessage);
        } else {
          this.drawer.hide();
          // temporary fix to refresh the contact page - will be replaced with Redux
          if (document.querySelector("legl-contact-details")) {
            document.querySelector("legl-contact-details").getContactInfo();
          } else {
            window.dispatchEvent(new CustomEvent("contacts-information-changed"));
          }
          LdsToast.showSuccess({ text: "Upload successful" });
        }
      } catch (error) {
        LdsToast.showError({ text: error.message });
        this.isSubmitting = false;
      }
    }
  }

  closeDrawer = () => {
    this.drawer.closeHandler();
  };

  render() {
    return html`
            <div class="container">
                <p class="risk_assessment-intro">
                    Law Firms are required to identify, monitor and manage risk
                    relating to each client and each new matter on instruction
                    and on an ongoing basis. This is not only to ensure
                    compliance with regulatory codes and AML regulations where
                    applicable, but this also as part of best practice.
                </p>
                <div class="risk-assessment-upload-form">
                    <form @submit=${this.handleSubmit}>
                        <div
                            class="risk-assessment-upload-form__inputs-wrapper"
                        >
                            <legl-input
                                data-cy-upload-risk-assessment-name
                                class="source-input"
                                name="name"
                                .validators=${[new LeglRequired()]}
                                label="Risk assessment document name"
                            ></legl-input>
                            <legl-input
                                data-cy-upload-risk-assessment-matter-ref
                                class="source-input"
                                name="matter_ref"
                                .validators=${[new LeglRequired()]}
                                label="Matter reference"
                            ></legl-input>
                            <legl-input-date
                                data-cy-upload-risk-assessment-completed-at
                                class="source-input"
                                name="completed_at"
                                label="Completed date"
                                help-text="This is the date when the document was completed"
                                .validators=${[
                                  new LeglRequired(),
                                  new LeglIsDate(),
                                  new LeglMaxDate(new Date()),
                                  new LeglMinDate(new Date("01/01/1900")),
                                ]}
                            >
                            </legl-input-date>
                            <div
                                class="risk-assessment-upload-form__document-upload"
                            >
                                <p class="risk-assessment-upload-form__label">
                                    Upload a document
                                </p>
                                <legl-input-file-upload
                                    data-cy-upload-risk-assessment-document-upload
                                    .maxFiles=${1}
                                    .acceptedFiles=${["pdf", "doc", "docx"]}
                                    upload-url="/api/risk_assessment_documents/"
                                    param-name="document"
                                    @file-added="${() => {
                                      this._fileAdded();
                                    }}"
                                    @file-uploaded="${(e) => {
                                      this._addDocument(e.detail.token);
                                    }}"
                                    @file-removed="${(e) => {
                                      this._removeDocument(e.detail.token);
                                    }}"
                                >
                                </legl-input-file-upload>
                                ${when(
                                  this.showValidationErrors &&
                                    !this.documentUrl,
                                  () =>
                                    html`<div
                                            class="risk-assessment-upload-form__validation-message"
                                        >
                                            Upload a risk assessment document
                                        </div>`,
                                )}
                            </div>
                            <div
                                class="risk-assessment-upload-form__risk-score"
                            >
                                <p class="risk-assessment-upload-form__label">
                                    Score
                                </p>
                                <div
                                    class="risk-assessment-upload-form__risk-score-wrapper high"
                                >
                                    <label class="radio-container">
                                        High
                                        <input
                                            type="radio"
                                            name="risk_score"
                                            value="high"
                                            @change=${() =>
                                              (this.riskScore = "high")}
                                        />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </div>
                                <div
                                    class="risk-assessment-upload-form__risk-score-wrapper medium"
                                >
                                    <label class="radio-container">
                                        Medium
                                        <input
                                            type="radio"
                                            name="risk_score"
                                            value="medium"
                                            @change=${() =>
                                              (this.riskScore = "medium")}
                                        />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </div>
                                <div
                                    class="risk-assessment-upload-form__risk-score-wrapper low"
                                >
                                    <label class="radio-container">
                                        Low
                                        <input
                                            type="radio"
                                            name="risk_score"
                                            value="low"
                                            @change=${() =>
                                              (this.riskScore = "low")}
                                        />
                                        <span class="radio-checkmark"></span>
                                    </label>
                                </div>
                                ${when(
                                  this.showValidationErrors && !this.riskScore,
                                  () =>
                                    html`<div
                                            class="risk-assessment-upload-form__validation-message"
                                        >
                                            Score is required
                                        </div>`,
                                )}
                            </div>
                        </div>
                        <div class="risk-assessment-upload-form__controls">
                            <lds-button
                                class="form-controls__cancel"
                                colour="neutral"
                                variant="outlined"
                                type="button"
                                @click=${this.closeDrawer}
                            >
                                Cancel
                            </lds-button>
                            <lds-button
                                data-cy-upload-risk-assessment-submit
                                class="form-controls__submit"
                                colour="primary"
                                variant="contained"
                                ?disabled=${this.isSubmitting}
                            >
                                Submit
                            </lds-button>
                        </div>
                    </form>
                </div>
            </div>
        `;
  }
}

if (!customElements.get("legl-risk-assessment-upload")) {
  customElements.define("legl-risk-assessment-upload", LeglRiskAssessmentUpload);
}
