import { LitElement, css, html, nothing } from "lit";

import LeglFormValidationMixin from "../../../static-src/js/mixins/LeglFormValidationMixin";
import "../../legl-ui/action-bar";
import "../../legl-ui/button";
import { LeglExpandablePanelMixin } from "../../legl-ui/expandable-panel";
import { neutral } from "../../legl-ui/lds-colours";
import { spacing } from "../../legl-ui/lds-spacing";

import { LeglMinLength, LeglRequired } from "../../legl-ui/lds-input";
import { toastService } from "../../legl-ui/toast";
import { get, put } from "../../legl-ui/utils/fetch";
import { debounce } from "../../legl-ui/utils/functions";
import { userConfig } from "../lawyers-app/static-src/js/config/user-config.js";
import "./contacts-list-item.js";
import { Contact } from "./services/contact.js";
export class ContactSelector extends LeglFormValidationMixin(
  LeglExpandablePanelMixin(LitElement),
) {
  static get styles() {
    return [
      super.styles,
      css`
          :host {
              color: ${neutral["700"]};
          }
          :host * {
              box-sizing: border-box;
          }

          .back-btn {
              margin-bottom: ${spacing.xs}
          }

          .wrapper {
              padding: 0;
              box-shadow: none;
              border-radius: 4px;
          }

          fieldset {
              padding: 0;
              border: none;
          }

          fieldset > legend {
              margin-bottom: 1.25rem;
          }

          .basic-fields {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: auto auto auto;
              gap: 1.25rem;
              align-items: flex-end;
              grid-template-areas:
                  "email email"
                  "first-name surname"
                  "middle-name client-reference";
          }

          .basic-fields lds-input {
              margin: 0;
          }

          .email {
              grid-area: email;
          }

          .first-name {
              grid-area: first-name;
          }

          .surname {
              grid-area: surname;
          }

          .middle-name {
              grid-area: middle-name;
          }

          .client-reference {
              grid-area: client-reference;
          }

          .submit-btn {
              margin-top: 1.25rem;
          }

          .contacts-lookup {
              height: 275px;
              overflow-y: auto;
          }

          .contacts-lookup[data-count="0"],
          .contacts-lookup[data-count=""] {
              height: 0px;
          }

          .contacts-lookup[data-count="1"] {
              height: 125px;
          }

          .contacts-lookup[data-count="2"] {
              height: 200px;
          }

          legl-contacts-list-item::part(item-container) {
              border: solid 1px ${neutral["200"]};
              border-radius: 6px;
              padding: 10px;
          }

          legl-contacts-list-item:not(:last-of-type)::part(
                  item-container
              ) {
              border-bottom: none;
          }

          .list-item-actions {
            display: flex;
          }

          .-use-contact {
              border: 1px solid ${neutral["700"]};
          }

          .existing-contacts-message {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 32px 0 16px 0;
          }
          .existing-contacts-message p {
              margin: 16px 0 0;
          }
          .select-contact-button {
            margin-right: ${spacing.s};
          }
      `,
    ];
  }

  static get properties() {
    return {
      contact: {
        attribute: false,
      },
      results: {
        attribute: false,
      },
      searchType: {
        attribute: false,
      },
      selectedContact: {
        attribute: false,
      },
      selectedContactUid: {
        attribute: "selected-contact-uid",
        type: String,
      },
      firstName: {
        type: String,
      },
      lastName: {
        type: String,
      },
      email: {
        type: String,
      },
      middleName: {
        type: String,
      },
      clientReference: {
        type: String,
      },
      clientReferenceRequired: {
        type: Boolean,
      },
      businessAssociations: {
        attribute: false,
      },
      createOnly: { type: Boolean },
      introText: { attribute: "intro-text" },
      buttonText: { attribute: "button-text" },
      emailRequired: {
        type: Boolean,
      },
      isManualCDD: { type: Boolean },
      backButtonText: {
        type: String,
        attribute: "back-button-text",
      },
      previousEmailSearch: {
        state: true,
      },
      previousEmailResults: {
        state: true,
      },
      previousNameSearch: {
        state: true,
      },
      previousNameResults: {
        state: true,
      },
      isSearching: {
        state: true,
      },
    };
  }

  get emailValidators() {
    const validatorsArray = this.contact.getValidatorsForField("email");

    if (this.emailRequired) {
      validatorsArray.push(new LeglRequired());
    }

    return validatorsArray;
  }

  get clientReferenceValidators() {
    const validatorsArray =
      this.contact.getValidatorsForField("clientReference");

    if (this.clientReferenceRequired) {
      validatorsArray.push(new LeglRequired());
    }

    return validatorsArray;
  }

  nameValidators(name) {
    const validatorsArray = this.contact.getValidatorsForField(name);
    // onfido requires 2 characters for first and last name
    validatorsArray.push(new LeglMinLength(2));

    return validatorsArray;
  }

  get emailLabel() {
    if (!this.emailRequired) {
      return this.isManualCDD
        ? "Email address (optional, no emails will be sent to this client)"
        : "Email address (optional, for an email receipt)";
    }
    return "Email address";
  }

  get _expandableContent() {
    return html` <form @submit=${this.onSubmit}>
            <fieldset>
                <legend>
                    ${
                      this.introText ||
                      "Start by entering the contact's email, name, or both."
                    }
                </legend>
                <div class="basic-fields">
                    <lds-input
                        .validators=${this.emailValidators}
                        @model-value-changed=${(e) =>
                          this.onFieldChanged("email", e.target.value)}
                        @focus=${() => this.onFocus("email")}
                        class="email"
                        data-cy-contact-selector-email
                        label=${this.emailLabel}
                        value=${this.email || ""}
                        .modelValue=${this.email || ""}
                    ></lds-input>
                    <lds-input
                        id="contact-selctor-input-first-name"
                        .validators=${this.nameValidators("first_name")}
                        @model-value-changed=${(e) =>
                          this.onFieldChanged("firstName", e.target.value)}
                        @focus=${() => this.onFocus("name")}
                        class="first-name"
                        data-cy-contact-selector-first-name
                        label="First name"
                        .modelValue=${this.firstName || ""}
                        value=${this.firstName || ""}
                    ></lds-input>
                    <lds-input
                        .validators=${this.nameValidators("last_name")}
                        @model-value-changed=${(e) =>
                          this.onFieldChanged("lastName", e.target.value)}
                        @focus=${() => this.onFocus("name")}
                        class="surname"
                        data-cy-contact-selector-surname
                        label="Surname"
                        .modelValue=${this.lastName || ""}
                        value=${this.lastName || ""}
                    ></lds-input>
                    <lds-input
                        class="middle-name"
                        data-cy-contact-selector-middle-name
                        @model-value-changed=${(e) =>
                          this.onFieldChanged("middleName", e.target.value)}
                        label="Any middle names (optional)"
                        value=${this.middleName || ""}
                        .modelValue=${this.middleName || ""}
                    ></lds-input>
                    <lds-input
                        .validators=${this.clientReferenceValidators}
                        class="client-reference"
                        data-cy-contact-selector-client-reference
                        @model-value-changed=${(e) =>
                          this.onFieldChanged(
                            "clientReference",
                            e.target.value,
                          )}
                        label="Client reference"
                        value=${this.clientReference || ""}
                        .modelValue=${this.clientReference || ""}
                        .optional=${!this.clientReferenceRequired}
                    ></lds-input>
                </div>
            </fieldset>
            <div class="results">
                ${
                  this.isSearching
                    ? html`<div class="existing-contacts-message">
                          <lds-spinner></lds-spinner>
                          <p>Searching for existing contacts...</p>
                      </div>`
                    : (this.previousEmailSearch || this.previousNameSearch) &&
                        this.results.length === 0
                      ? html`<div class="existing-contacts-message">
                          <lds-alert
                            type="info"
                            title="Existing contact not found"
                            message="Please click Continue and we will create a new Contact for you using the details entered above."
                          >
                          </lds-alert>
                      </div>`
                      : html`${this.resultsMessage}
                          <div
                              class="contacts-lookup"
                              id="contactsLookup"
                              data-cy-contacts-lookup
                              data-count=${this.results?.length || 0}
                          >
                              ${this.results.map((contact) =>
                                this.renderItem(contact),
                              )}
                          </div>`
                }
            </div>
            <legl-button
                class="submit-btn"
                data-cy-contact-selector-submit-btn
                full-width
                .disabled=${this.isSearching}
                >${this.buttonText || "Continue"}</legl-button
            >
        </form>`;
  }

  get _primaryContent() {
    if (this.selectedContact) {
      return html` <div
                class="primary-content"
                data-cy-contact-selector-primary-content
            >
                <legl-contacts-list-item
                    name="${this.selectedContact.name}"
                    email="${this.selectedContact.email || ""}"
                    .ref=${this.selectedContact.client_reference || ""}
                    .hasNote=${!this.selectedContact.email}
                    .businesses=${this.selectedContact.businesses || []}
                    .uid=${this.selectedContact.uid}
                >
                ${
                  this.selectedContact.uid
                    ? html`${
                        userConfig.isConsultant
                          ? nothing
                          : html` <lds-button
                                @click=${() => (this.isExpanded = true)}
                                variant="outlined"
                                colour="primary"
                                data-cy-contact-selector-back-btn
                                .small=${true}
                            >
                              Change contact
                            </lds-button>`
                      }`
                    : nothing
                }

                </legl-contacts-list-item>
            </div>`;
    }
    if (this.selectedContactUid) {
      this.isExpanded = false;
      return html`<div class="existing-contacts-message">
                    <lds-spinner></lds-spinner>
                    <p>Loading contact details...</p>
                  </div>`;
    }
    return nothing;
  }

  get formControls() {
    return Array.from(this.shadowRoot.querySelectorAll("lds-input"));
  }

  get resultsMessage() {
    if (this.results.length === 0) {
      return nothing;
    }
    return html`<p>
            We've found contacts that contain some information you have
            provided. If the contact you are working with is listed, select by
            pressing <strong>Select contact</strong>. Otherwise, complete the
            form above to create a new contact.
        </p>`;
  }

  get validBusinessAssociations() {
    return (
      this.businessAssociations?.filter(
        (businessAssociation) => businessAssociation.id,
      ) || []
    );
  }

  renderItem(contact) {
    return html`
            <legl-contacts-list-item
                name="${contact?.name || ""}"
                email="${contact?.email || ""}"
                .ref=${contact?.client_reference || ""}
                .businesses=${contact?.businesses || []}
            >
              <div class="list-item-actions">
                ${
                  !this.createOnly
                    ? html`
                          <lds-button
                            class="select-contact-button"
                            type="button"
                            .small=${true}
                            @click=${(e) => {
                                e.stopPropagation();
                                if (this.validBusinessAssociations?.length > 0) {
                                  this.associateBusinesses(contact);
                                }
                                this.onContactSelected(contact);
                              }
                            }
                            colour="primary"
                          >
                          Select contact
                        </lds-button>
                      `
                    : nothing
                }
                ${
                  userConfig.isConsultant
                    ? nothing
                    : html` 
                        <lds-link-button
                          id="open-contact-details"
                          @click=${(e) => e.stopPropagation()}
                          href="${
                            contact?.uid
                              ? `/contacts/${contact.uid}/overview`
                              : "#"
                          }"
                          .openInNewTab=${true}
                          variant="outlined"
                          .small=${true}
                        >
                          View contact
                        </lds-link-button>
                      `
                }
              </div>
            </legl-contacts-list-item>
        `;
  }

  onContactSelected(contact, created) {
    this.dispatchEvent(
      new CustomEvent("contact-changed", {
        detail: contact
          ? {
              ...contact,
              created: Boolean(created),
            }
          : null,
        bubbles: true,
        composed: true,
      }),
    );
    if (!this.createOnly) {
      this.selectedContact = contact;
    }

    if (!this.createOnly && contact) {
      this.isExpanded = false;
    }
  }

  associateBusinesses(contact) {
    const res = put(`/api/contacts/${contact.uid}/`, {
      body: JSON.stringify({
        ...contact,
        businesses: this.validBusinessAssociations,
      }),
    });
    if (!res.ok) {
      console.error(
        `Failed to associate contact: ${
          contact.uid
        } with businesses: ${this.validBusinessAssociations.map(
          (business) => `${business.id}, `,
        )}`,
      );
    }
  }

  onSearch() {
    if (
      this.searchType === "name" &&
      this.contact.firstName &&
      this.contact.lastName
    ) {
      const searchTerm = `${this.contact.firstName} ${this.contact.lastName}`;
      if (searchTerm !== this.previousNameSearch) {
        this.previousNameSearch = searchTerm;
        this.getContactsList(
          `${this.contact.firstName} ${this.contact.lastName}`,
        );
      } else {
        this.results = this.previousNameResults || [];
      }
    } else if (this.searchType === "email" && this.contact.email?.length > 2) {
      if (this.contact.email !== this.previousEmailSearch) {
        this.previousEmailSearch = this.contact.email;
        this.getContactsList(this.contact.email);
      } else {
        this.results = this.previousEmailResults || [];
      }
    }
  }

  onSearchBound = this.onSearch.bind(this);

  debouncedSearch = debounce(this.onSearchBound, 1000);

  onFieldChanged(field, value) {
    this[field] = value;
    this.contact[field] = value;
    if (field === "email" || field === "firstName" || field === "lastName") {
      this.debouncedSearch();
    }

    this.requestUpdate();
  }

  onFocus(searchType) {
    if (["email", "name"].includes(searchType) === false) {
      return;
    }
    if (searchType !== this.searchType) {
      this.searchType = searchType;
      this.debouncedSearch();
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    this.validate();
    if (!this.isValid) {
      return false;
    }
    try {
      if (!this.emailRequired) {
        if (!this.contact["email"]) {
          this.contact["email"] = null;
        }
      }

      this.onFieldChanged("businesses", this.validBusinessAssociations);
      await this.contact.save();
      window.dispatchEvent(new CustomEvent("reload-contacts-table"));
      /**
       * Add the newly created contact to the search index in case
       * they go back and enter the same email without refreshing/re-fetching
       */
      const contact_data = {
        name: `${this.contact.firstName} ${this.contact.lastName}`,
        first_name: this.contact.firstName,
        last_name: this.contact.lastName,
        middle_name: this.contact.middleName,
        email: this.contact.email,
        uid: this.contact.uid,
        client_reference: this.contact.clientReference,
      };
      this.results.push(contact_data);
      this.onContactSelected(this.contact._data, true);
    } catch (err) {
      if (err.status >= 400 && err.status < 500 && err.messageFromJSONDetail) {
        toastService.showError(err.message);
      } else {
        toastService.showError("Unable to create contact");
      }
    }
  }

  async getContactsList(searchTerm) {
    try {
      this.isSearching = true;

      const url = `/api/contacts/?search=${encodeURIComponent(
        searchTerm,
      )}&compact=true`;

      const res = await get(url);
      
      if (!res.ok) {
        throw new Error("Failed to search existing contacts");
      }
      this.data = await res.json();
      this.results = this.data.results;

      if (this.searchType === "email") {
        this.previousEmailResults = this.results;
      } else {
        this.previousNameResults = this.results;
      }
    } catch {
      toastService.showError("We were unable to search existing contacts");
    } finally {
      this.isSearching = false;
    }
  }

  async getContactByUid(uid) {
    const url = `/api/contacts/${uid}/?compact=true`;
    const res = await get(url);
    if (res.ok) {
      const contact = await res.json();
      return contact;
    }
    return null;
  }

  constructor() {
    super();
    this.isExpanded = true;
    this.title = "Contact Details";
    this.contact = new Contact();
    this.results = [];
    this.selectedContact = null;
    this.emailRequired = true;
    this.clientReferenceRequired = userConfig.clientReferenceRequired;
    this.isManualCDD = false;
    this.isSearching = false;
    this.backButtonText = "Back";
  }

  async firstUpdated() {
    super.firstUpdated();
    if (this.selectedContactUid) {
      this.backButtonText = "";
      const contact = await this.getContactByUid(this.selectedContactUid);
      if (contact) {
        this.results.push(contact);
        this.onContactSelected(contact);
      }
      this.selectedContactUid = null;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.email) {
      this.searchType = "email";
    } else if (this.firstName && this.lastName) {
      this.searchType = "name";
    }
  }

  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has("isExpanded")) {
      if (this.isExpanded === true && this.selectedContact) {
        this.onContactSelected(null);
      }
    }
  }
}

if (!customElements.get("legl-contact-selector")) {
    customElements.define("legl-contact-selector", ContactSelector);
}
