import { LitElement, css, html } from "lit";
import "../../../../../legl-ui/input/legl-checkbox.js";

export class LeglTagSelect extends LitElement {
  static get styles() {
    return css`
            :host {
                text-align: center;
            }

            .options-group {
                display: flex;
                justify-content: space-around;
                overflow: hidden;
                width: 100%;
                margin-left: 50px;
            }

            .column {
                width: 33.33%;
                padding: 0 1rem;
            }

            .option {
                margin-top: 18px;
                min-height: 70px;
            }

            @media only screen and (max-width: 990px) {
                .options-group {
                    display: block;
                }

                .column {
                    width: 100%;
                }
            }
            legl-checkbox {
                margin-bottom: 20px;
            }
        `;
  }

  static get properties() {
    return {
      options: {
        type: Array,
      },
      multiselect: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.options = [];
    this.multiselect = true;
  }

  get checkedOptions() {
    return [...this.shadowRoot.querySelectorAll(".option")].reduce(
      (optionsOut, option) => {
        return {
          ...optionsOut,
          [option.value]: !!option.checked,
        };
      },
      {},
    );
  }

  get optionColumns() {
    const optionsPerColumn = Math.ceil(this.options.length / 3);
    return [
      [...this.options.slice(0, optionsPerColumn)],
      [...this.options.slice(optionsPerColumn, optionsPerColumn * 2)],
      [...this.options.slice(optionsPerColumn * 2)],
    ];
  }

  get selectedCount() {
    return Object.values(this.checkedOptions).filter((value) => value === true)
      .length;
  }

  dispatchChange() {
    this.dispatchEvent(
      new CustomEvent("valueChange", {
        detail: { updatedOptions: this.checkedOptions },
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`
            <slot name="before"> </slot>
            <legl-checkbox-group @change=${() => this.dispatchChange()}>
                <div class="options-group">
                    ${this.optionColumns.map(
                      (options) => html`
                            <div class="column">
                                ${options.map((option) => {
                                  return html`<legl-checkbox
                                        class="option"
                                        label="${option.label}"
                                        .choiceValue=${option.value}
                                        .modelValue=${option}
                                        @user-input-changed=${(e) => {
                                          if (this.multiselect === false) {
                                            this.shadowRoot
                                              .querySelectorAll(".option")
                                              .forEach((option) => {
                                                if (
                                                  option !== e.composedPath()[0]
                                                ) {
                                                  option.checked = false;
                                                }
                                              });
                                          }
                                        }}
                                    ></legl-checkbox>`;
                                })}
                            </div>
                        `,
                    )}
                </div>
            </legl-checkbox-group>
            <slot name="after"></slot>
        `;
  }
}

if (!customElements.get("legl-tag-select")) {
  customElements.define("legl-tag-select", LeglTagSelect);
}
