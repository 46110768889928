import { css } from "lit";
import { Accordion } from "../../../../../apps/pay-app/static-src/js/components/accordion/accordion.js";
import { spacing } from "../../../../../legl-ui/lds-spacing";
import { toastService } from "../../../../../legl-ui/toast";
import { formatDate } from "../../../../../legl-ui/utils";
import { get, post } from "../../../../../legl-ui/utils/fetch";
import { engageEventBus } from "./engage-event-bus.js";

const propFormatterOverrides = {
  "legl-basic-information": {
    date_of_birth: (val) => {
      return formatDate(val);
    },
  },
};

function getFormattedPropValue(componentName, propName, propValue) {
  if (
    propFormatterOverrides[componentName] &&
    propFormatterOverrides[componentName][propName] &&
    propFormatterOverrides[componentName][propName] instanceof Function
  ) {
    return propFormatterOverrides[componentName][propName](propValue);
  }
  return propValue;
}

function overwriteIsBusy(event) {
  /*
        in case we send multiple requests we don't want them to fail because
        isBusy is true, so we overwrite
     */
  if (event.detail?.payload?.cddDocumentsAction) {
    return event.detail.payload.cddDocumentsAction !== "delete";
  } else {
    return false;
  }
}

export class EngageAccordion extends Accordion {
  static get styles() {
    return [
      super.styles,
      css`
                .summary-text-container {
                    padding: ${spacing.s} ${spacing.m};
                }

                @media only screen and (max-width: 1030px) {
                    .summary-text-container {
                        padding: ${spacing.s} ${spacing.s};
                    }
                }
            `,
    ];
  }
  constructor() {
    super();
    this.elementLoaded = false;
    this.isBusy = false;
  }

  firstUpdated() {
    this.addEventListener("engageStepComplete", this.completeStep);
    this.addEventListener("engageStepResult", this.sendResults);
    this.addEventListener("click-beta", () => {
      document
        .querySelectorAll("legl-engage-accordion[show-alpha]")
        .forEach(
          (openEngageAccordion) => (openEngageAccordion.showAlpha = false),
        );

      this.showAlpha = true;
    });
    this.addEventListener("lock", (event) => {
      this.locked = true;
      if (event.detail.elementTypes?.length > 0) {
        engageEventBus.dispatchEvent(
          new CustomEvent("accordion-locked", {
            detail: event.detail,
          }),
        );
      }
    });
    engageEventBus.addEventListener("accordion-locked", (event) => {
      if (
        this.elementLoaded &&
        event.detail.elementTypes.includes(this.elementType)
      ) {
        this.locked = true;
      }
    });
    if (
      this.elementType === "cdd-documents" &&
      this.showOnlyForInternationalAddress
    ) {
      engageEventBus.addEventListener("international-address", (event) => {
        this.hidden = event.detail.hide;
      });
    }
  }

  updated() {
    if (!this.showAlpha || (this.complete && this.hidden)) {
      return;
    }
    if (!this.querySelector('section[slot="content-alpha"]').hasChildNodes()) {
      if (this.hidden) {
        this.sendResults();
      } else {
        this.loadElement();
      }
    }
  }

  static get properties() {
    return {
      ...super.properties,
      stepUrl: { type: String, attribute: "step-url" },
      elementType: { type: String, attribute: "element-type" },
      showOnlyForInternationalAddress: {
        type: Boolean,
        attribute: "show-only-for-international-address",
      },
      allowOutOfOrderStepCompletion: {
        type: Boolean,
        attribute: "allow-out-of-order-step-completion",
      },
      completedRedirectUrl: {
        type: String,
        attribute: "completed-redirect-url",
      },
    };
  }

  async loadElement() {
    try {
      const section = this.querySelector('section[slot="content-alpha"]');

      // Add spinner component to accordion while initial content is loading
      const LdsSpinner = window.customElements.get("lds-spinner");
      const spinner = new LdsSpinner();
      const spinnerContainer = document.createElement("div");
      spinnerContainer.setAttribute("class", "spinner-container");
      spinnerContainer.appendChild(spinner);
      section.appendChild(spinnerContainer);

      const response = await get(this.stepUrl);
      const component = await response.json();
      const ChildElement = window.customElements.get(component.name);
      // TODO: handle name being invalid and ChildElement being undefined
      const element = new ChildElement();
      Object.keys(component.props).forEach((key) => {
        element[key] = getFormattedPropValue(
          component.name,
          key,
          component.props[key],
        );
      });
      element.allowOutOfOrderStepCompletion =
        this.allowOutOfOrderStepCompletion;
      if (component.sections) {
        for (const name of component.sections) {
          const subsection = document.createElement("section");
          subsection.setAttribute("slot", name);
          element.appendChild(subsection);
        }
      }
      section.removeChild(spinnerContainer);
      section.appendChild(element);
      this.childComponent = element;
      this.elementLoaded = true;
      this.scrollIntoView();
    } catch (err) {
      toastService.showError(
        "An unexpected error has occurred, please check your internet connection and try again.",
      );
    }
  }

  goToNextStep() {
    this.showAlpha = false;
    const allStepsComplete =
      document.querySelectorAll("legl-engage-accordion:not([complete])")
        .length === 0;
    if (allStepsComplete) {
      document
        .querySelector("[data-engage-container]")
        .classList.add("flow-complete");

      if (!!this.completedRedirectUrl) {
        const redirectTimeout = 1000 * 2; // in milliseconds

        setTimeout(() => {
          window.location.href = this.completedRedirectUrl;
        }, redirectTimeout);
      }
    } else {
      const nextAccordion = document.querySelector(
        "legl-engage-accordion[show-alpha] + legl-engage-accordion:not([complete])",
      );
      if (nextAccordion) {
        nextAccordion.locked = false;
        nextAccordion.showAlpha = true;
      } else {
        const previousAccordion = document.querySelector(
          "legl-engage-accordion:not([complete]):not([hidden])",
        );
        if (previousAccordion) {
          previousAccordion.showAlpha = true;
          previousAccordion.locked = false;
        }
      }
    }
  }

  incrementCompletedCount() {
    const completedCount = document.querySelector("#completedSteps");
    completedCount.textContent =
      Number.parseInt(completedCount.textContent) + 1;
  }

  async completeStep(event) {
    let betaContent = "";
    if (event) {
      betaContent = event.detail.betaContent;
    }
    const section = this.querySelector('section[slot="content-beta"]');
    section.setAttribute("slot", "content-beta");
    section.innerHTML = betaContent;
    section.classList.add("complete");
    if (!this.complete) {
      this.complete = true;

      if (!this.hidden) this.incrementCompletedCount();
    }

    await this.updateComplete;
    const steps_locked_on_submit = [
      "document-request",
      "cdd-documents",
      "source-of-funds",
      "custom-form",
      "nfc",
    ];

    if (steps_locked_on_submit.includes(this.getAttribute("element-type"))) {
      this.locked = true;
    }
    this.goToNextStep();
  }

  async postResults(payload) {
    const response = await post(this.stepUrl, {
      body: JSON.stringify(payload),
    });

    // If response is OK or a 400 we assume the underlying element will handle the response on it's own
    // Otherwise we want to show a generic error to the user and not allow them to proceed
    if (!(response.ok || response.status === 400)) {
      throw new Error("ERROR_SAVING");
    }

    if (this.hidden) {
      this.completeStep();
    } else {
      try {
        const body = await response.json();
        this.childComponent.resultResponse = {
          status: response.status,
          body,
        };
      } catch (err) {
        // Non-JSON response
        this.childComponent.resultResponse = {
          status: response.status,
        };
      }
    }
  }

  async sendResults(event) {
    if (event && overwriteIsBusy(event)) {
      if (this.isBusy || !this.showAlpha) {
        return;
      }
    }
    this.isBusy = true;
    let payload = {};
    if (event) {
      payload = event.detail.payload;
    }
    try {
      await this.postResults(payload);
    } catch (err) {
      toastService.showError(
        "We were unable to store the results you submitted. Please check your internet connection and try again.",
      );
      const elementOnfido = document.querySelector("legl-element-onfido");
      elementOnfido?.dispatchEvent(new CustomEvent("removeSpinner"));
    }
    this.isBusy = false;
  }
}

if (!customElements.get("legl-engage-accordion")) {
  customElements.define("legl-engage-accordion", EngageAccordion);
}
