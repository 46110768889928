import { css, html } from "lit";
import { LeglSteps } from "./legl-steps.js";

export class LeglBasicSteps extends LeglSteps {
  static get styles() {
    return [
      super.styles,
      css`
                :host {
                    grid-template: "slot slot slot" auto/ auto 1fr 1fr;
                }
            `,
    ];
  }
  render() {
    return html`<slot></slot>`;
  }
}

if (!customElements.get("legl-basic-steps")) {
  customElements.define("legl-basic-steps", LeglBasicSteps);
}
