import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export class ContactsInsightItem extends LitElement {
  static get properties() {
    return {
      title: { type: String },
      content: { type: String },
      hasToolTip: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return css`
            :host p {
                margin: 0;
            }

            :host .content {
                font-size: 1.25rem;
                font-weight: 700;
                margin-bottom: 0.4rem;
                color: ${neutral["800"]};
            }

            ::slotted([slot="custom-content"]) {
                margin-bottom: 0.4rem;
                min-height: 40px;
            }

            :host .title-and-tooltip {
                display: inline-flex;
                font-size: 0.75rem;
                color: ${neutral["800"]};
            }

            :host .title {
                font-weight: 600;
            }

            :host .tooltip-icon-info-circle {
                padding: 2px 3px 2px 2px;
            }
        `;
  }

  constructor() {
    super();
    this.hasToolTip = false;
  }

  render() {
    return html`
      <slot name="custom-content"></slot>
        ${this.content ? html`<p class="content">${this.content}</p>` : ""}
        <div class="title-and-tooltip">
        <p class="title">${this.title}</p>
          ${
          this.hasToolTip
            ? html` <legl-tooltip variant="top-center">
                  <legl-icon
                      class="tooltip-icon-info-circle"
                      name="legl-icon-info-circle"
                  >
                  </legl-icon>
                  <span slot="content"
                      >"Average time to pay" measures how long (on
                      average) it takes this contact to pay your firm
                      when you send them pay requests from Legl. Phone
                      payments, generic portal payments, payment steps
                      in Engage requests, and payment plans are not
                      included in this calculation.</span
                  >
              </legl-tooltip>`
            : ""
        }
    </div>
`;
  }
}

if (!customElements.get("legl-contacts-insights-item")) {
    customElements.define("legl-contacts-insights-item", ContactsInsightItem);
}
