import { LitElement, css, html, nothing } from "lit";
import "../../../../../../legl-ui/fullscreen-loading-spinner";
import "../../../../../../legl-ui/icon";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../legl-ui/lds-modal";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import "./no-id-document-modal";

import SVGStep2 from "./img/external-steps/step-2.svg";
import SVGStep3 from "./img/external-steps/step-3.svg";
import SVGStep4 from "./img/external-steps/step-4.svg";
import SVGStep5 from "./img/external-steps/step-5.svg";
import SVGStep6 from "./img/external-steps/step-6.svg";

function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}
export class ExternalHelperScreens extends LitElement {
  static get styles() {
    return [
      css`
                .instructions__section {
                    text-align: start;
                    padding: ${spacing.xs} ${spacing.m} ${spacing.m};
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                @media only screen and (max-width: 1030px) {
                    .instructions__section {
                        padding: ${spacing.xs} ${spacing.s} ${spacing.m};
                    }
                }
                .instruction__button-container {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    flex-grow: 1;
                    margin-top: ${spacing.l};
                }
                .instructions__button {
                  margin-left: 16px;
                }
                .instruction-block__icon-grid {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 24px 20px;
                }
                .instruction-block__text {
                    margin: 0;
                    font-size: 16px;
                    color: ${neutral["800"]};
                }

                .instruction-block__text.margin-top {
                    margin-top: 24px;
                }

                .terms-of-use a {
                    color: ${neutral["800"]};
                }

                .instruction-block__title--2 {
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    text-align: center;
                    color: ${neutral["900"]};
                    margin: 30px 0;
                }
                .instruction-block__title--3 {
                    margin: 24px 0;
                    color: var(--legl-blue);
                    font-size: 20px;
                    font-weight: normal;
                }
                .instruction-block__icon--tick {
                    color: var(--legl-blue);
                }

                .instruction-block__icon--warning {
                    font-size: 38px;
                    color: var(--legl-yellow);
                }

                .instruction-block__image {
                    max-height: 350px;
                    width: 100%;
                    object-fit: contain;
                }
                .instruction-block__image--small {
                    max-height: 350px;
                    width: 100%;
                    object-fit: contain;
                }
                .instruction-block__image-container {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            `,
    ];
  }

  static get properties() {
    return {
      ...super.properties,
      instructionIndex: { type: Number },
      completeAction: { type: Object, attribute: true },
      companyPhoneNumber: { type: String },
      companyEmail: { type: String },
      companyName: { type: String },
    };
  }

  constructor() {
    super();
    this.instructionIndex = 0;
    this.instructionImages = [SVGStep2, SVGStep3, SVGStep4, SVGStep5, SVGStep6];
  }

  get instructionsHidden() {
    return this.instructionIndex > this.instructionBlocks.length - 1;
  }

  get instructionBlocks() {
    return [
      html`
                <p class="instruction-block__text">
                    The following identity check uses your passport or other
                    government-issued photo ID document to help your lawyer or
                    professional adviser verify your identity.
                </p>
                <h3 class="instruction-block__title--3">
                    In order to verify your ID, please make sure:
                </h3>
                <div class="instruction-block__icon-grid">
                    <lds-icon
                        class="instruction-block__icon--tick"
                        name="lds-icon-CheckCircleFilled"
                        large
                    ></lds-icon>
                    <p class="instruction-block__text">
                        You have your passport, driving licence or
                        government-issued photo ID to hand.
                    </p>
                    <lds-icon
                        class="instruction-block__icon--tick"
                        name="lds-icon-CheckCircleFilled"
                        large
                    ></lds-icon>
                    <p class="instruction-block__text">
                        You take a good quality photo of your document. This
                        allows us to process your check quickly.
                    </p>
                </div>
                <p class="instruction-block__text margin-top">
                    After you have uploaded your ID document, you may be
                    required to record a short video of yourself to confirm that
                    your identity matches your ID document. Please note that
                    this recording, alongside your uploaded document
                    <strong
                        >will be sent to the law firm for their records</strong
                    >, therefore please be aware of your surroundings.
                </p>
                <p class="instruction-block__text margin-top">
                    By clicking “Continue” below, I agree to provide my photo
                    for identity verification through biometric technology, as
                    detailed in Section 5 & 7(a) of the
                    <a href="https://legl.com/terms-of-use/" target="_blank"
                        >Terms of Use</a
                    >.
                </p>
            `,
      this.genericInstructionStep(
        html`Do not use a scanner or printer to get a copy of your ID -
                please take a photo of it`,
        html`
                    <p class="instruction-block__text">
                        Please use your phone, tablet or computer’s camera to
                        take a photo of your government issued photo identity
                        document, following our guidelines to take a good photo.
                    </p>
                `,
      ),
      this.genericInstructionStep(
        html`Take a photo of your government issued photo identity
                document`,
        html`
                    <p class="instruction-block__text">
                        Be sure to centre your ID or passport until all four
                        edges are
                        <strong>fully in frame</strong> and
                        <strong>not cutting off any side.</strong>
                    </p>
                `,
      ),
      this.genericInstructionStep(
        html`Avoid glare`,
        html`
                    <p class="instruction-block__text">
                        Your name, date of birth and other identifying
                        information must be
                        <strong>clearly readable.</strong> To prevent glare,
                        turn off your phone’s flash and face your document away
                        from bright lights.
                    </p>
                `,
      ),
      this.genericInstructionStep(
        html`Make sure your document is in focus`,
        html`
                    <p class="instruction-block__text">
                        To ensure valid results, make sure that the photo is in
                        focus and all text is
                        <strong>clear and readable.</strong> If your document
                        looks blurry while you’re taking your photo tap the
                        document on the screen to focus it.
                    </p>
                `,
      ),
      this.genericInstructionStep(
        html`Camera access will be required`,
        html`
                    <p class="instruction-block__text">
                        When asked, please
                        <strong>allow access to the camera</strong> on your
                        device, and <strong>allow access to microphone</strong>.
                        You will be required to take a photo of your government
                        issued identity document, and record a short video.
                    </p>
                `,
      ),
    ];
  }

  genericInstructionStep(title, textBlock) {
    return html`
            <div class="instruction-block__image-container">
                <img
                    class="instruction-block__image--small"
                    src="${this.instructionImages[this.instructionIndex - 1]}"
                />
            </div>
            <h2 class="instruction-block__title--2">${title}</h2>
            <div class="instruction-block__icon-grid">
                <legl-icon
                    class="instruction-block__icon--warning"
                    name="legl-icon-warning-triangle"
                ></legl-icon>
                ${textBlock}
            </div>
        `;
  }

  nextInstruction() {
    this.instructionIndex += 1;
    if (this.instructionsHidden) {
      this.completeAction();
    }
  }

  openNoIdModal() {
    LdsModal.create({
      title: "No identity document",
      slotComponent: "no-id-document-modal",
      slotComponentProperties: {
        companyPhoneNumber: this.companyPhoneNumber,
        companyEmail: this.companyEmail,
        companyName: this.companyName,
      },
      dataTestId: "no-id-document-modal",
    });
  }

  render() {
    let instructions = "";
    if (!isIE() && !this.instructionsHidden) {
      instructions = html`
                <div class="instructions__section">
                    <div>${this.instructionBlocks[this.instructionIndex]}</div>
                    <div class="instruction__button-container">
                      ${
                        this.instructionIndex === 0
                          ? html`<lds-button id="no-id-button" @click=${this.openNoIdModal} variant="outlined"> 
                            I do not have an ID
                        </lds-button>`
                          : nothing
                      }
                        <lds-button
                            id="instructions-button"
                            class="instructions__button"
                            @click="${this.nextInstruction}"
                            data-cy-instructions-button
                            colour="primary"
                        >
                            Continue
                        </lds-button>
                    </div>
                </div>
            `;
    }
    return instructions;
  }
}

if (!customElements.get("legl-external-helper-screens")) {
  customElements.define("legl-external-helper-screens", ExternalHelperScreens);
}
