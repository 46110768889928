// The below import is required to work correctly for karma tests. The default lib uses process.env.NODE_ENV and karma
// cannot resolve process
import { createPopper } from "@popperjs/core/dist/esm";
import { LitElement, css, html } from "lit";
import { nothing } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export class LeglTooltipDynamic extends LitElement {
  static get styles() {
    return css`
            #tooltip {
                background: ${neutral["100"]};
                border-radius: 1px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                max-width: 525px;
                z-index: 999;
                color: var(--legl-tooltip-color);
                padding: 8px;
            }

            .tooltip-hide {
                display: none;
            }
            .tooltip-show {
                display: block;
            }

            #arrow,
            #arrow::before {
                position: absolute;
                width: 16px;
                height: 16px;
                background: var(--legl-tooltip-background);
            }

            #arrow {
                visibility: hidden;
            }

            #arrow::before {
                visibility: visible;
                content: "";
                transform: rotate(45deg);
            }

            #tooltip[data-popper-placement^="top"] > #arrow {
                bottom: -8px;
            }

            #tooltip[data-popper-placement^="bottom"] > #arrow {
                top: -8px;
            }

            #tooltip[data-popper-placement^="left"] > #arrow {
                right: -8px;
            }

            #tooltip[data-popper-placement^="right"] > #arrow {
                left: -8px;
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                background-color: #000;
                opacity: 50%;
            }
        `;
  }

  static get properties() {
    return {
      showTooltip: {
        type: Boolean,
        attribute: false,
      },
      hover: {
        type: Boolean,
      },
      isEnabled: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.popperInstance = null;
    this.hover = false;
    this.isEnabled = true;
  }

  get slottedChildren() {
    const slot = this.shadowRoot.querySelector("slot");
    const childNodes = slot.assignedNodes({ flatten: true });
    return Array.prototype.filter.call(
      childNodes,
      (node) => node.nodeType === Node.ELEMENT_NODE,
    );
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    this.initializePopper();
    this.startListeningForArrowChanges();
  }

  initializePopper() {
    const button = this.slottedChildren[0];
    const tooltip = this.shadowRoot.querySelector("#tooltip");

    this.popperInstance = createPopper(button, tooltip, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 12],
          },
        },
      ],
    });
  }

  startListeningForArrowChanges() {
    const tooltipComponent = this.shadowRoot.querySelector("#tooltip");
    /**
     * The tooltip arrow has to change colors based on where the arrow gets placed (top / bottom).
     * In order to do that we listen to changes on the data-popper-placement attribute on the tooltip and apply
     * changes accordingly.
     * @type {MutationObserver}
     */
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          if (
            tooltipComponent.getAttribute("data-popper-placement") === "bottom"
          ) {
            this.shadowRoot
              .querySelector("#tooltip")
              .style.setProperty(
                "box-shadow",
                "0px -4px 4px rgba(0, 0, 0, 0.25)",
              );
          }
        }
      });
    });

    observer.observe(tooltipComponent, {
      attributes: true, // listen to attribute changes
    });
  }

  tooltipAndOverlayController() {
    if (this.isEnabled) {
      this.handleTooltip();
      this.displayOverlay();
      return this.popperInstance.update();
    }
  }

  handleTooltip() {
    const tooltip = this.shadowRoot.querySelector("#tooltip");
    /**
     * Below we handle the scenario when the close tooltip service is used, so we end up having a hidden tooltip,
     * but showTooltip is still true. We just change the css class to the correct one and trigger a popper update.
     */
    if (tooltip.getAttribute("class") === "tooltip-hide" && this.showTooltip) {
      tooltip.setAttribute("class", "tooltip-show");
    } else {
      this.showTooltip = !this.showTooltip;
    }
    // This is required in the editable field which is visible on hover
    const tooltipEvent = new CustomEvent("tooltip-state", {
      detail: { tooltipOpen: "true" },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(tooltipEvent);
  }

  displayOverlay() {
    if (
      !this.hover &&
      this.shadowRoot.querySelector("#overlay").getAttribute("style") ===
        "display: none"
    ) {
      this.shadowRoot
        .querySelector("#overlay")
        .setAttribute("style", "display: block");
    }
  }

  render() {
    return html`
            ${
              this.hover
                ? html`<div
                      @mouseenter="${() => this.tooltipAndOverlayController()}"
                      @mouseleave="${() => this.tooltipAndOverlayController()}"
                  >
                      <slot></slot>
                  </div>`
                : html` <slot
                      @click="${() => this.tooltipAndOverlayController()}"
                  ></slot>`
            }
            <div
                id="tooltip"
                role="tooltip"
                class="${this.showTooltip ? "tooltip-show" : "tooltip-hide"}"
            >
                <slot name="content"></slot>
                <div id="arrow" data-popper-arrow></div>
            </div>
            ${
              this.hover
                ? nothing
                : html`<div
                      id="overlay"
                      class="overlay"
                      style="display: none"
                  ></div>`
            }
        `;
  }
}

if (!customElements.get("legl-tooltip-dynamic")) {
  customElements.define("legl-tooltip-dynamic", LeglTooltipDynamic);
}
