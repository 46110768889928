import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "./legl-mini-accordion-item-includes.js";

export class LeglMiniAccordionItem extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
            }
            .accordion {
                border: solid 1px var(--legl-grey-light);
                border-radius: 5px;
                padding: 18px 18px 8px 18px;
                display: grid;
                grid-template:
                    "title selected chevron"
                    "description description description"
                    "details details details" / auto 80px 30px;
                cursor: pointer;
            }
            .accordion--selected {
                border-color: var(--legl-green);
            }
            .accordion__title {
                grid-area: title;
                color: var(--legl-grey-dark);
                margin: 0;
                font-size: 14px;
                font-weight: 600;
            }
            .accordion__title--selected {
                color: var(--legl-green);
            }
            .accordion__selected-marker {
                grid-area: selected;
                margin: 0;
                color: var(--white);
                background: var(--legl-green);
                border-radius: 5px;
                height: 22px;
                width: 75px;
                text-align: center;
            }
            .accordion__description {
                grid-area: description;
                color: var(--legl-grey-dark);
                padding: 0;
                font-size: 12px;
                font-style: italic;
                font-weight: 400;
            }
            .accordion__details {
                grid-area: details;
            }
            .accordion__chevron-container {
                grid-area: chevron;
            }
            .accordion__chevron {
                -moz-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
                justify-self: center;
            }
            .accordion__chevron--selected {
                transform: rotate(90deg);
                color: var(--legl-green);
            }
        `;
  }

  static get properties() {
    return {
      title: { type: String, reflect: true },
      selected: { type: Boolean, attribute: "selected", reflect: true },
      url: { type: String, attribute: false },
      description: { type: String, attribute: false },
      steps: { type: Array, attribute: false },
      includes: { type: Element, attribute: false },
      isValid: { type: Boolean, attribute: false },
      extraData: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.selected = false;
  }

  get accordionClasses() {
    return {
      accordion: true,
      "accordion--selected": this.selected,
    };
  }

  get accordionTitleClasses() {
    return {
      accordion__title: true,
      "accordion__title--selected": this.selected,
    };
  }

  get chevronClasses() {
    return {
      accordion__chevron: true,
      "accordion__chevron--selected": this.selected,
    };
  }

  get isValid() {
    return this.includes.isValid;
  }

  get extraData() {
    return this.includes.extraData;
  }

  open() {
    this.selected = true;
  }
  close() {
    this.selected = false;
  }

  firstUpdated() {
    this.includes = this.shadowRoot.querySelector(
      "legl-mini-accordion-item-includes",
    );
  }

  render() {
    return html`
            <div class="${classMap(this.accordionClasses)}">
                <h2 class="${classMap(this.accordionTitleClasses)}">
                    ${this.title}
                </h2>
                <p class="accordion__selected-marker" ?hidden=${!this.selected}>
                    selected
                </p>
                <p class="accordion__description">${this.description}</p>
                <div class="accordion__chevron-container">
                    <legl-icon
                        name="legl-icon-chevron-right"
                        size="large"
                        class="${classMap(this.chevronClasses)}"
                    ></legl-icon>
                </div>
                <div class="accordion__details" ?hidden=${!this.selected}>
                    <legl-mini-accordion-item-includes
                        .steps=${this.steps}
                    ></legl-mini-accordion-item-includes>
                </div>
            </div>
        `;
  }
}

if (!customElements.get("legl-mini-accordion-item")) {
  customElements.define("legl-mini-accordion-item", LeglMiniAccordionItem);
}
