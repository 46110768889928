import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../legl-ui/lds-colours";
import "../../legl-ui/menu";
import "./legl-action-bar-item";
export class LeglActionBar extends LitElement {
  static get styles() {
    return css`
            .action-bar {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                min-width: var(--legl-action-bar-min-width, 200px);
            }

            .action-bar__primary {
                display: flex;
                align-items: flex-start;
            }

            .action-bar__secondary {
                --legl-menu-min-width: 200px;
                margin: 0 5px;
                position: relative;
            }

            .action-bar__secondary.is-hidden {
                display: none;
            }

            /* TODO: Start of CSS to be deleted in Clubhouse story https://app.clubhouse.io/legl/story/8620/migrate-legl-action-bar-items-to-use-new-legl-action-bar-item-component */

            .action-bar__primary ::slotted(button),
            .action-bar__primary ::slotted(a) {
                color: ${neutral["800"]};
                box-sizing: border-box;
                border-radius: 6px;
                font-size: 12px;
                font-weight: 700;
                overflow: hidden;
                cursor: pointer;
                border: none;
                position: relative;
                padding: 15px 5px;
                background: none;
                transform-origin: 50% 50%;
                transition: transform 0.125s ease-in-out;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                text-decoration: none;
                text-align: center;
                margin: 0 5px;
                max-width: 90px;
                display: grid;
                grid-template-rows: 20px auto;
            }

            .action-bar__primary ::slotted(button:active),
            .action-bar__primary ::slotted(a:active),
            .action-bar__primary ::slotted(button:focus),
            .action-bar__primary ::slotted(a:focus) {
                text-decoration: none;
            }

            .action-bar__primary ::slotted(button:hover),
            .action-bar__primary ::slotted(a:hover) {
                background-color: ${neutral["200"]};
                text-decoration: none;
            }

            .action-bar__primary ::slotted(button[disabled]),
            .action-bar__primary ::slotted(a.is-disabled) {
                color: var(--legl-grey);
                cursor: not-allowed;
                pointer-events: none;
            }

            .action-bar__primary ::slotted(legl-icon) {
                font-size: 14px;
            }

            /* End of CSS to be deleted in https://app.clubhouse.io/legl/story/8620/migrate-legl-action-bar-items-to-use-new-legl-action-bar-item-component */
        `;
  }

  static get properties() {
    return {
      hasSecondaryActions: {
        attribute: false,
      },
    };
  }

  get secondaryActionsClasses() {
    return {
      "is-hidden": !this.hasSecondaryActions,
      "action-bar__secondary": true,
    };
  }

  updated(props) {
    super.updated(props);
    if (
      this.shadowRoot
        .querySelector(".action-bar__secondary-slot")
        .assignedNodes().length > 0
    ) {
      this.hasSecondaryActions = true;
    } else {
      this.hasSecondaryActions = false;
    }
  }
  render() {
    return html`
            <div class="action-bar">
                <div class="action-bar__primary">
                    <slot name="primary"></slot>
                </div>
                <legl-menu class="${classMap(this.secondaryActionsClasses)}">
                    <slot
                        class="action-bar__secondary-slot"
                        name="secondary"
                    ></slot>
                </legl-menu>
            </div>
        `;
  }
}

if (!customElements.get("legl-action-bar")) {
  customElements.define("legl-action-bar", LeglActionBar);
}
