import { LitElement, css, html } from "lit";
import { connect } from "pwa-helpers";
import LeglFormValidationMixin from "../../../../../../../static-src/js/mixins/LeglFormValidationMixin.js";
import "../../../../../../apps/lawyers-contacts/contact-selector.js";
import "../../../../../../legl-ui/accordion";
import "../../../../../../legl-ui/button";
import "../../../../../../legl-ui/expandable-panel";
import "../../../../../../legl-ui/input/legl-input-amount.js";
import "../../../../../../legl-ui/input/legl-select.js";
import "../../../../../../legl-ui/input/legl-textarea.js";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { LeglRequired } from "../../../../../../legl-ui/lds-input";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import "../../../../../../legl-ui/steps";
import { toastService } from "../../../../../../legl-ui/toast";
import "../../../../../../legl-ui/tooltip";
import { formatDate } from "../../../../../../legl-ui/utils";
import { get, patch, post } from "../../../../../../legl-ui/utils/fetch.js";
import store, { engageActions } from "../../../../../../redux/lawyers-admin";
import companyEngageSettings from "../../config/company-engage-settings.js";
import { userConfig } from "../../config/user-config.js";
import "../request-success-message/request-success-message.js";

export class LeglEngageRequestCreate extends connect(store)(
  LeglFormValidationMixin(LitElement),
) {
  static get styles() {
    return css`
            .engage-create__input-group {
                display: grid;
                grid-template-columns: calc(50% - 8px) calc(50% - 8px);
                margin-top: 32px;
                grid-column-gap: 16px;
                -moz-column-gap: 16px;
                column-gap: 16px;
            }
            .engage-create__label {
                color: ${neutral["800"]};
            }
            .engage-create__comment,
            .engage-create__workflows-label {
                margin-top: ${spacing.l};
            }
            .engage-create__workflows-label {
                margin-bottom: ${spacing.s};
            }
            .engage-create__workflows {
                margin-bottom: ${spacing.xl};
            }
            .engage-create__success-panel {
                display: grid;
                grid-template:
                    "success-title success-title"
                    "copy-button create-button"
                    "details-card details-card"
                    "close-button close-button"/ 50% 50%;
                justify-content: center;
                gap: 20px;
                margin-top: 80px;
            }
            .engage-create__success-title {
                grid-area: success-title;
                justify-self: center;
                color: ${neutral["800"]};
                font-size: 20px;
                font-weight: 700;
                margin: 0;
            }
            .engage-create__close-button {
                grid-area: close-button;
                width: 100%;
                display: flex;
                justify-content: center;
                padding-top: ${spacing.xs};
                border-top: 1px solid ${neutral["200"]};
                border-radius: 0;
            }
            .details-card {
                grid-area: details-card;
                display: grid;
                grid-template:
                    "contact created-date requested-by . ."
                    "matter-ref invoice-ref . . ."
                    "comment comment comment comment comment" / 1fr 1fr 1fr;
                color: ${neutral["700"]};
                font-size: 12px;
                padding: 50px 30px;
                margin: 60px 0;
            }
            .details-card strong {
                font-weight: 700;
            }
            .details-card__comment {
                grid-area: comment;
            }
            .details-card__created-date {
                grid-area: created-date;
            }
            .details-card__invoice-ref {
                grid-area: invoice-ref;
            }
            .details-card__matter-ref {
                grid-area: matter-ref;
            }
            .details-card__requested-by {
                grid-area: requested-by;
            }
            .details-card__total {
                grid-area: total;
                text-align: end;
            }
            .details-card__total-value {
                font-size: 17px;
                font-weight: 700;
                margin: 0;
            }
            .details-card__contact {
                grid-area: contact;
            }
            .tooltip-text {
                font-size: 12px;
                padding: 5px 10px;
                font-weight: normal;
            }
            .engageCreate__successPanel.--reminders {
                display: grid;
                grid-template:
                    "success-title success-title"
                    "reminders-wrapper reminders-wrapper"
                    "details-card details-card"
                    "close-button close-button"/ 50% 50%;
                justify-content: center;
                gap: 20px;
                margin-top: 80px;
            }
            .engageCreate__successPanel.--wrapper {
                grid-area: reminders-wrapper / reminders-wrapper /
                    reminders-wrapper / reminders-wrapper;
                max-width: 750px;
                justify-self: center;
            }
        `;
  }

  static get properties() {
    return {
      reviewers: { type: Array, attribute: false },
      selectedReviewer: {
        attribute: false,
      },
      workflows: { type: Array, attribute: false },
      engageRequest: { type: Object, attribute: false },
      accordion: { type: Object, attribute: false },
      form: { type: Element, attribute: false },
      comment: { type: Element, attribute: false },
      emailSentCount: { type: Number, attribute: false },
      link: { type: String, attribute: false },
      selectedContact: {
        attribute: false,
      },
      isSubmitting: {
        attribute: false,
      },
      emailRemindersEnabled: {
        attribute: false,
        type: Boolean,
      },
      companyEngageSettings: { type: Object, attribute: false },
      ccOnRequestEmail: { state: true },
      matterReferenceRequired: { state: true },
    };
  }

  constructor() {
    super();
    this.reviewers = [];
    this.workflows = [];
    this.isSubmitting = false;
    this.emailRemindersEnabled = true;
    this.companyEngageSettings = {};
  }

  get json() {
    const form = new FormData(this.form);
    const body = {
      ...Object.fromEntries(form),
      ...this.accordion.selectItem.extraData,
      template: this.accordion.selectItem.url,
    };
    if (this.params.get("business_id")) {
      body.business_id = this.params.get("business_id");
    }
    if (this.selectedContact) {
      body.contact_uid = this.selectedContact.uid;
      body.first_name = this.selectedContact.first_name;
      body.last_name = this.selectedContact.last_name;
      body.email = this.selectedContact.email;
      body.client_reference = this.selectedContact.client_reference;
    }
    return body;
  }

  getReferer() {
    // We want to fake a referrer when the url looks like this:
    // https://account.legl.com/{wherever the drawer was opened}?some=actual&query=params#/drawer/engage-create/?first_name=joe&last_name=bloggs&externalSource=true...
    // we pass the referer as:
    // https://account.legl.com/drawer/engage-create/?first_name=joe&last_name=bloggs&externalSource=true...
    // which captures the prefilled form data in the referrer header to be recorded by the backend.
    // This is necessary because the fragment (everything after the hash) is always stripped out by the browser when it executes the post request.
    const collapsedUrl = `${window.location.protocol}//${
      window.location.host
    }${window.location.hash.slice(1)}`;
    const defaultReferrer = window.location.toString();
    return window.location.hash ? collapsedUrl : defaultReferrer;
  }

  async submitForm(event) {
    event.preventDefault();
    if (this.isSubmitting) {
      return;
    }

    if (this.accordion.isValid) {
      if (
        this.accordion.selectedWorkflowSteps?.find(
          (step) =>
            (step.element_type === "signature-request" ||
              step.element_type === "e-signature-request-v2") &&
            !this.accordion?.selectItem?.extraData?.signature_requests?.[
              step.pk
            ]?.signature_request_id,
        )
      ) {
        return LdsToast.showError({
          text: "Please upload and tag a document",
          autoClose: true,
        });
      }

      if (
        this.accordion.selectedWorkflowSteps?.find(
          (step) =>
            step.element_type === "shared-document" &&
            step.element_config.bespoke_document &&
            !this.accordion?.selectItem?.extraData?.bespoke_documents?.[
              step.pk
            ],
        )
      ) {
        return LdsToast.showError({
          text: "Please upload a shared document",
          autoClose: true,
        });
      }

      this.isSubmitting = true;
      try {
        const res = await post("/api/engage_applications/", {
          body: JSON.stringify(this.json),
          referrer: this.getReferer(),
        });
        if (!res.ok) {
          const errorMessage = await res.text();
          if (errorMessage) {
            const error_key = Object.keys(JSON.parse(errorMessage))[0];
            throw new Error(
              error_key.replace("_", " ") +
                ": " +
                JSON.parse(errorMessage)[error_key],
            );
          } else {
            throw new Error(
              "There was a problem when creating the application",
            );
          }
        }
        this.engageRequest = await res.json();
        if (this.selectedContact.entity_id) {
          await patch(`/v2/entities/${this.selectedContact.entity_id}/`, {
            body: JSON.stringify({ applications: [this.engageRequest.id] }),
            referrer: this.getReferer(),
          });
        }
        window.dispatchEvent(new CustomEvent("reload-engage-table"));

        document
          .querySelector("legl-drawer")
          .shadowRoot.querySelector(".drawer__content").scrollTop = 0;
        return;
      } catch (error) {
        toastService.showError(
          "There was a problem when creating the application",
        );
        console.error(error);
        return;
      } finally {
        this.isSubmitting = false;
      }
    }
    LdsToast.showError({
      text: "Please select a workflow",
      autoClose: true,
    });
  }

  async fetchReviewer() {
    try {
      let url = `/api/lawyers/?permissions_codename=view_companyengagesettings&user__isnull=false&active=true`;
      let reviewers = [];
      do {
        const res = await get(url);
        if (!res.ok) {
          throw new Error(res.error);
        }
        const response_json = await res.json();
        reviewers = reviewers.concat(response_json.results);
        url = response_json?.next;
      } while (url);
      this.reviewers = reviewers;
      this.selectedReviewer = this.reviewers.find((reviewer) =>
        this.currentLawyerSelect(reviewer.url),
      ).url;
    } catch {
      toastService.showError("Error retrieving reviewers");
    }
  }

  async fetchWorkflows() {
    try {
      const res = await get(`/api/flow_templates/`);
      if (!res.ok) {
        throw new Error(res.error);
      }
      const json = await res.json();
      this.workflows = json.results;
    } catch {
      toastService.showError("Error retrieving workflows");
    }
  }

  async fetchCompanyEngageSettings() {
    await companyEngageSettings.fetch();
    this.reminderStartAfterDays = companyEngageSettings.reminderStartAfterDays;
    this.bccOnRequestEmail = companyEngageSettings.bccOnRequestEmail;
    this.ccOnRequestEmail = companyEngageSettings.ccOnRequestEmail;
    this.reminderStartAfterDaysOptions =
      companyEngageSettings.reminderStartAfterDaysOptions;
    this.matterReferenceRequired =
      companyEngageSettings.matterReferenceRequired;
  }

  firstUpdated() {
    this.fetchReviewer();
    this.fetchWorkflows();
    this.fetchCompanyEngageSettings();
  }

  updated(changedProperties) {
    if (changedProperties.has("selectedContact")) {
      store.dispatch(engageActions.selectedContact(this.selectedContact));
    }
  }

  currentLawyerSelect(value) {
    return value?.endsWith(`/${userConfig.userId}/`);
  }

  handleReviewerChange(event) {
    this.selectedReviewer = event.target.value;
    if (this.isCommentHidden) {
      this.comment.value = "";
    }
  }

  onContactChanged(e) {
    if (!e?.detail?.uid) {
      this.selectedContact = null;
      return;
    }
    this.selectedContact = e.detail;
  }

  get isCommentHidden() {
    return this.currentLawyerSelect(this.selectedReviewer);
  }

  get form() {
    return this.shadowRoot.querySelector(".engage-create__contact-form");
  }
  get comment() {
    return this.shadowRoot.querySelector(".engage-create__comment");
  }
  get accordion() {
    return this.shadowRoot.querySelector("legl-mini-accordion");
  }

  get drawer() {
    return document.querySelector("legl-drawer");
  }

  get link() {
    return this.engageRequest?.link || "";
  }

  get workFlowInformationFields() {
    return html`
            <div class="engage-create__input-group">
                <strong class="engage-create__label engage-create__matter-label"
                    >Is there a matter reference to associate with this
                    workflow?</strong
                >
                ${
                  this.matterReferenceRequired !== undefined
                    ? html` <lds-input
                          label="Matter reference"
                          name="matter_reference"
                          value="${this.params.get("matterReference") || ""}"
                          data-cy-create-engage-matter-reference
                          .optional="${!this.matterReferenceRequired}"
                      ></lds-input>`
                    : ""
                }
            </div>
            <div class="engage-create__input-group">
                <strong
                    class="engage-create__label engage-create__reviewer-label"
                    >Who will review the workflow results?</strong
                >
                <lds-select
                    label="Reviewer"
                    name="assigned_reviewer"
                    @change=${this.handleReviewerChange}
                    @model-value-changed=${() => this.requestUpdate()}
                    .validators=${[new LeglRequired()]}
                    data-cy-create-engage-request-reviewer
                >
                    <select slot="input">
                        ${this.reviewers.map(
                          (lawyer) =>
                            html`<option
                                    ?selected=${
                                      lawyer.url === this.selectedReviewer
                                    }
                                    value=${lawyer.url}
                                >
                                    ${lawyer.select_label}
                                </option>`,
                        )}
                    </select>
                </lds-select>
            </div>
            <lds-textarea
                label="Comment"
                name="optional_note"
                class="engage-create__comment"
                ?hidden=${this.isCommentHidden}
                data-cy-create-engage-request-comment
            ></lds-textarea>
        `;
  }

  get hiddenFields() {
    return html`<input
                type="hidden"
                name="company_report_person_reference"
                .value=${this.params.get("companyReportPersonReference")}
            />
            <input
                type="hidden"
                name="company_report_person_identifier"
                .value=${this.params.get("companyReportPersonIdentifier")}
            />
            <input
                type="hidden"
                name="company_report_reference"
                .value=${this.params.get("companyReportReference")}
            />`;
  }

  get workflowSelector() {
    return html` <div class="engage-create__workflows-label">
                <strong class="engage-create__label">
                    Select a workflow:
                </strong>
            </div>
            <div class="engage-create__workflows">
                <legl-mini-accordion
                    data-cy-engage-accordion
                    .items=${this.workflows}
                ></legl-mini-accordion>
            </div>
            <legl-button
                full-width
                class="engage-create__button"
                data-cy-create-engage-submit-second-step
                ?disabled=${this.isSubmitting || !this.isValid}
            >
                Create Engage Request
            </legl-button>`;
  }

  get requestInfo() {
    return {
      reference: this.engageRequest?.reference,
      id: this.engageRequest?.id,
    };
  }

  get successMessage() {
    return html`<div class="engageCreate__successPanel --reminders">
                <h1
                    class="engage-create__success-title"
                    data-cy-create-engage-success-title
                >
                    Request successfully created!
                </h1>
                <request-success-message
                    class="engageCreate__successPanel --wrapper"
                    .selectedContact=${this.selectedContact}
                    .requestInfo=${this.requestInfo}
                    .requestLink=${this.link}
                    .reminderStartAfterDays=${this.reminderStartAfterDays}
                    .bccEnabled=${this.bccOnRequestEmail}
                    .ccOnRequestEmail=${this.ccOnRequestEmail}
                    .reminderStartAfterDaysOptions=${
                      this.reminderStartAfterDaysOptions
                    }
                    product="engage"
                    data-cy-request-success-message-request-id=${
                      this.engageRequest.id
                    }
                ></request-success-message>
                <legl-card class="details-card card-bg card-border">
                    <h1 slot="title" variant="corner">request details</h1>
                    <div class="details-card__contact">
                        <strong>Contact</strong>
                        <legl-contact-mini-view
                            .contactName=${this.engageRequest?.name}
                            .contactEmail=${this.engageRequest?.email}
                        ></legl-contact-mini-view>
                    </div>
                    <div class="details-card__created-date">
                        <strong>Created</strong>
                        <p>
                            ${formatDate(this.engageRequest?.created_at, {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: false,
                            })}
                        </p>
                    </div>
                    <div class="details-card__requested-by">
                        <strong>Requested by</strong>
                        <p data-cy-summary-engage-create-requested-by>
                            ${this.engageRequest?.created_by?.select_label}
                        </p>
                    </div>
                    <div class="details-card__matter-ref">
                        <strong>Matter reference</strong>
                        <p>${this.engageRequest?.matter_reference || "-"}</p>
                    </div>
                    <div class="details-card__comment">
                        <strong>Optional note</strong>
                        <p>
                            ${
                              this.engageRequest?.application_events[0]
                                ?.comment || "-"
                            }
                        </p>
                    </div>
                </legl-card>
            </div>
            <div class="engage-create__close-button">
                <lds-button data-testid="engage-create-close-button" variant="text" @click=${() => this.drawer.hide()}
                    >Close</lds-button
                >
            </div>`;
  }

  render() {
    if (this.engageRequest) {
      return this.successMessage;
    }
    return html`<div data-cy-create-engage-request-drawer>
            <legl-contact-selector
                .selectedContactUid=${this.params.get("contactUid")}
                .firstName=${this.params.get("firstName")}
                .middleName=${this.params.get("middleName")}
                .lastName=${this.params.get("lastName")}
                .email=${this.params.get("email")}
                .clientReference=${this.params.get("clientReference")}
                .hideExpandButton=${true}
                @contact-changed=${this.onContactChanged}
                .businessAssociations=${[
                  {
                    id: this.params.get("business_id"),
                    type: this.params.get("type"),
                    role: this.params.get("role"),
                    shareholder_percentage:
                      this.params.get("shareholder_percentage") || 0,
                  },
                ]}
            >
            </legl-contact-selector>
            <legl-expandable-panel
                data-cy-create-engage-workflow-information
                title="Workflow Information"
                .isLocked=${true}
                .isExpanded=${this.selectedContact}
            >
                <form
                    @submit=${this.submitForm}
                    class="engage-create__contact-form"
                    slot="expandable-content"
                >
                    ${this.workFlowInformationFields} ${this.workflowSelector}
                    ${this.hiddenFields}
                </form>
            </legl-expandable-panel>
        </div>`;
  }
}


if (!customElements.get("legl-engage-request-create")) {
  customElements.define("legl-engage-request-create", LeglEngageRequestCreate);
}
