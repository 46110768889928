import { LitElement, css, html } from "lit";
import "../../../legl-ui/lds-button";
import { neutral } from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-input";
import { spacing } from "../../../legl-ui/lds-spacing";
import { get } from "../../../legl-ui/utils/fetch";

export class PostcodeLookup extends LitElement {
  static get styles() {
    return css`
            :host .wrapper {
                display: flex;
                align-items: flex-end;
            }

            .postcode-input {
                flex-grow: 1;
                margin-right: ${spacing.s};
            }

            .find-address-button {
                width: 154px;
            }

            .manual-entry {
                display: flex;
                justify-content: flex-end;
                font-style: italic;
                margin-bottom: 0;
            }

            .manual-entry a {
                color: ${neutral["800"]};
                font-size: 14px;
            }
        `;
  }

  static get properties() {
    return {
      postcode: {
        type: String,
        attribute: false,
      },
      isLoading: {
        type: Boolean,
        attribute: false,
      },
      lookupUrl: {
        type: String,
        attribute: "lookup-url",
      },
    };
  }

  static get events() {
    return {
      lookupSkipped: "lookupSkipped",
      lookupLoaded: "lookupLoaded",
      lookupError: "lookupError",
    };
  }

  get isSearchDisabled() {
    return this.postcode.length < 5;
  }

  constructor() {
    super();
    this.postcode = "";
    this.isLoading = false;
    this.lookupUrl = "";
  }

  onPostcodeChanged(value) {
    this.postcode = value.trim();
  }

  onFindClicked() {
    this.search();
  }

  onManualEntryClicked() {
    this.dispatchEvent(new CustomEvent(PostcodeLookup.events.lookupSkipped));
  }

  async search() {
    try {
      this.isLoading = true;
      const res = await get(
        `${this.lookupUrl}${encodeURIComponent(this.postcode)}`,
      );

      if (res.status >= 200 && res.status < 400) {
        const addresses = await res.json();
        this.dispatchEvent(
          new CustomEvent(PostcodeLookup.events.lookupLoaded, {
            detail: { addresses },
          }),
        );
      } else if (res.status === 404) {
        this.dispatchEvent(
          new CustomEvent(PostcodeLookup.events.lookupLoaded, {
            detail: {
              addresses: [],
            },
          }),
        );
      } else {
        this.dispatchEvent(
          new CustomEvent(PostcodeLookup.events.lookupError, {
            detail: {
              status: res.status,
            },
          }),
        );
      }
    } catch (err) {
      this.dispatchEvent(
        new CustomEvent(PostcodeLookup.events.lookupError, {
          detail: {
            message: err.message,
          },
        }),
      );
    } finally {
      this.isLoading = false;
    }
  }

  render() {
    return html`
            <div class="wrapper">
                <lds-input
                    class="postcode-input"
                    label="Postcode"
                    @model-value-changed=${(e) => {
                      this.onPostcodeChanged(e.target.modelValue);
                    }}
                ></lds-input>
                <lds-button
                    type="button"
                    class="find-address-button"
                    .disabled=${this.isSearchDisabled}
                    .isLoading=${this.isLoading}
                    @click=${this.onFindClicked}
                >
                    Find address
                </lds-button>
            </div>
            <p class="manual-entry">
                <a
                    data-cy-postcode-lookup-skip
                    href="#"
                    @click=${(e) => {
                      e.preventDefault();
                      this.onManualEntryClicked();
                    }}
                    >Enter address manually</a
                >
            </p>
        `;
  }
}

if (!customElements.get("legl-postcode-lookup")) {
  customElements.define("legl-postcode-lookup", PostcodeLookup);
}
