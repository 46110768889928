import { LitElement, css, html } from "lit";
import { danger, neutral, primary, warning } from "../../legl-ui/lds-colours";
import { spacing } from "../../legl-ui/lds-spacing";
import { typographyPresets } from "../../legl-ui/lds-typography";

export class LabelWithIcon extends LitElement {
  static get properties() {
    return {
      label: { type: String },
    };
  }

  static get styles() {
    return css`
            .content legl-icon,
            .content lds-icon {
                font-size: 1.125rem;
                margin-right: ${spacing.xs};
            }
            :host {
                ${typographyPresets.body};
                text-transform: capitalize;
                min-width: 120px;
            }

            .content {
                display: flex;
                align-items: center;
            }

            .content legl-icon {
                display: flex;
            }

            .content.default {
                color: ${neutral["800"]};
            }

            .content.success {
                color: ${primary.base};
            }

            .content.warning {
                color: ${warning.base};
            }

            .content.danger {
                color: ${danger.shade};
            }
        `;
  }

  get slug() {
    if (this.label) {
      return this.label.toLowerCase().replace(/\s/g, "-");
    }
    return null;
  }

  get iconName() {
    return this.labelIconMap[this.slug]?.icon || null;
  }

  render() {
    return html`
            <span
                class="content ${
                  this.labelIconMap[this.slug]?.colour || "default"
                }"
            >
                ${
                  this.iconName
                    ? html` <lds-icon small .name=${this.iconName}></lds-icon>`
                    : ""
                } <slot></slot>
            </span>
        `;
  }
}

if (!customElements.get("legl-label-with-icon")) {
  customElements.define("legl-label-with-icon", LabelWithIcon);
}
