import { defineStore } from "pinia";
import { ref } from "vue";
import { WaffleFlags } from "../../../../waffle-flags";

import type { AsyncComponentLoader } from "vue";
import type { Drawer } from "../drawers/types";

export const DrawerNames = {
  PAY: {
    PAYMENT_PLAN_DETAIL: "payment-plan-review",
  },
  CONFLICTS: {
    RESULT_DETAIL: "result-detail",
  },
  ENGAGE_REVIEW: {
    VIEW_RESULT: "screening-result-detail",
    IDENTITY_VERIFICATION_DETAILS: "identity-verification-details",
    FINANCIAL_CHECK_DETAILS: "financial-check-details",
  },
};

const drawers: Drawer[] = [
  {
    hidden: !waffle.flag_is_active(WaffleFlags.PAYMENT_PLANS_DETAIL_DRAWER_VUE),
    name: DrawerNames.PAY.PAYMENT_PLAN_DETAIL,
    component: () =>
      import(
        "../views/pay/payment-plans/drawers/payment-plan-detail-drawer/PaymentPlanDetailDrawer.vue"
      ),
  },
  {
    name: DrawerNames.CONFLICTS.RESULT_DETAIL,
    component: () =>
      import(
        "../views/conflicts/check-details/drawers/result-detail/ResultDetail.vue"
      ),
  },
  {
    name: DrawerNames.ENGAGE_REVIEW.VIEW_RESULT,
    component: () =>
      import(
        "../views/engage/engage-review/drawers/view-result/ViewWatchlistResult.vue"
      ),
  },
  {
    name: DrawerNames.ENGAGE_REVIEW.IDENTITY_VERIFICATION_DETAILS,
    component: () =>
      import(
        "../views/engage/engage-review/components/identity/drawer/IdentityVerificationDetails.vue"
      ),
  },
  {
    name: DrawerNames.ENGAGE_REVIEW.FINANCIAL_CHECK_DETAILS,
    component: () =>
      import(
        "../views/engage/engage-review/components/financial/drawer/FinancialCheckDetails.vue"
      ),
  },
];

export const useDrawer = defineStore("use-drawer", () => {
  const isOpen = ref(false);
  const name = ref<string | null>(null);
  const component = ref<AsyncComponentLoader>();
  const props = ref<any>(null);
  const scrollY = ref<number>(0);

  const currentHash = ref(window.location.hash);

  function checkHashForDrawer() {
    if (
      currentHash.value !== window.location.hash &&
      window.location.hash.includes("#/drawer")
    ) {
      checkForOpenDrawer(window.location.hash);
    }
    currentHash.value = window.location.hash;
  }

  window.addEventListener("hashchange", checkHashForDrawer);

  function checkForOpenDrawer(urlHash: string) {
    try {
      const urlSearchParams = new URLSearchParams(urlHash.split("?")[1]);
      let drawerName = urlHash.split("/")[2];
      if (urlSearchParams.size > 0) {
        drawerName = drawerName.split("?")[0];
      }
      const drawerProps = Object.fromEntries(urlSearchParams.entries());

      findAndOpenDrawer(drawerName, drawerProps);
    } catch (e) {
      console.error(e);
    }
  }

  function updateURLHash(name: string, props: any) {
    let queryString = "";
    if (props) {
      const urlSearchParamsInstance = new URLSearchParams();

      for (const [key, value] of Object.entries(props)) {
        urlSearchParamsInstance.append(key, value as string);
      }
      queryString = `?${urlSearchParamsInstance.toString()}`;
    }
    window.location.hash = `#/drawer/${name}/${queryString}`;
  }

  function open({
    name: drawerName,
    props: drawerProps,
  }: { name: string; props?: any }) {
    if (isOpen.value) {
      close();
    }
    scrollY.value = window.scrollY;

    updateURLHash(drawerName, drawerProps);

    findAndOpenDrawer(drawerName, drawerProps);
  }

  async function findAndOpenDrawer(drawerName: string, drawerProps?: any) {
    try {
      const drawer = drawers.find((drawer) => drawer.name === drawerName);

      if (!drawer || drawer.hidden) {
        return;
      }

      component.value = drawer.component;
      name.value = drawerName;
      props.value = drawerProps;
      isOpen.value = true;

      window.dispatchEvent(
        new CustomEvent("legl-drawer:opened", {
          detail: {
            name: name.value,
          },
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }

  function close() {
    window.dispatchEvent(
      new CustomEvent("legl-drawer:closed", {
        detail: {
          name: name.value,
        },
      }),
    );
    name.value = null;
    props.value = null;
    isOpen.value = false;
    window.location.hash = "";
    window.scrollTo(0, scrollY.value);
  }

  if (window.location.hash.includes("#/drawer")) {
    checkForOpenDrawer(window.location.hash);
  }

  return {
    component,
    name,
    props,
    isOpen,
    open,
    close,
  };
});
