import { LitElement, css, html } from "lit";
import "../../../../../../legl-ui/carousel";
import "../../../../../../legl-ui/fullscreen-loading-spinner";
import "../../../../../../legl-ui/icon";

import colourNotBwImg from "./img/external-steps/colour-scan.png";
import inframeImg from "./img/external-steps/framing-scan.png";
import invalidScan from "./img/external-steps/step-2.svg";
import inFrameMobile from "./img/external-steps/step-3.svg";
import avoidGlareImg from "./img/external-steps/step-4.svg";
import infocusImg from "./img/external-steps/step-5.svg";
import smartphoneImg from "./img/internal-steps/framing-phone.png";
import step2SVG from "./img/internal-steps/id-within-frame.png";
import laptopUploadSVG from "./img/internal-steps/laptop-upload-id.svg";
import phoneWithIdSVG from "./img/internal-steps/phone-with-id.svg";

function isIE() {
  // IE 10 and IE 1
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

export class InternalHelperScreens extends LitElement {
  static get styles() {
    return [
      css`
                :host {
                    --legl-button-background-color: #2f37f2;
                    --legl-button-background-color-hover: #5c6cff;
                    --legl-button-padding: 0.5rem 2rem;
                    --legl-button-font-size: 20px;
                    --legl-button-font-weight: 100;
                    --legl-button-border-radius: 8px;
                    --legl-carousel-dot: #c4c4c4;
                    --legl-carousel-dot-active: #747474;
                }
                .instructions__section {
                    padding: 20px 34px 50px;
                    text-align: start;
                }
                @media only screen and (max-width: 480px) {
                    .instructions__section {
                        padding: 0 10px;
                    }
                }
            `,
    ];
  }

  static get properties() {
    return {
      ...super.properties,
      completeAction: { type: Object, attribute: true },
      route: { type: Array },
    };
  }

  constructor() {
    super();
    this.takePhotoStep = [
      this.genericInstructionStep(
        inFrameMobile,
        html`Ensure the whole document is visible`,
        html` <p class="instruction-block__text">
                    Be sure to centre the ID or passport until all four edges
                    are <strong>fully in frame</strong> and
                    <strong>not cutting off any side.</strong>
                </p>`,
      ),
      this.genericInstructionStep(
        avoidGlareImg,
        html`Avoid glare`,
        html` <p class="instruction-block__text">
                    Your client’s name, date of birth and other identifying
                    information must be clearly readable. Turn off your phone or
                    camera’s flash and face the document away from bright
                    lights.
                </p>`,
      ),
      this.genericInstructionStep(
        infocusImg,
        html`Make sure the document is in focus`,
        html` <p class="instruction-block__text">
                    To ensure valid results, make sure that the photo is in
                    focus and all text is clear and readable. If the document
                    looks blurry while you’re taking the photo with a smartphone
                    tap the document on the screen to focus it.
                </p>`,
      ),
    ];

    this.uploadPhotoStep = [
      this.genericInstructionStep(
        inFrameMobile,
        html`Ensure the whole document is visible`,
        html` <p class="instruction-block__text">
                    Make sure the whole document is visible in the photo sent by
                    your client. Documents that aren’t fully in-frame will not
                    be successfully read.
                </p>`,
      ),
      this.genericInstructionStep(
        avoidGlareImg,
        html`Only upload photos with no glare`,
        html` <p class="instruction-block__text">
                    Your client’s name, date of birth and other identifying
                    information must be clearly readable.
                </p>`,
      ),
      this.genericInstructionStep(
        infocusImg,
        html`Make sure the document is in focus`,
        html` <p class="instruction-block__text">
                    To ensure valid results, make sure that the photo is in
                    focus and all text is clear and readable.
                </p>`,
      ),
    ];

    this.uploadScanStep = [
      this.genericInstructionStep(
        inframeImg,
        html`Ensure the whole document is visible`,
        html` <p class="instruction-block__text">
                    Be sure to centre the ID or passport until all four edges
                    are <strong>fully in frame</strong> and
                    <strong>not cutting off any side</strong>. Make sure the
                    whole document is visible.
                </p>`,
      ),
      this.genericInstructionStep(
        colourNotBwImg,
        html`Upload full colour scans only`,
        html` <p class="instruction-block__text">
                    Black-and-white scans uploaded
                    <strong>will not come back as a “clear” result</strong> and
                    will require further action when reviewing the resulting
                    report.
                </p>`,
      ),
      this.genericInstructionStep(
        avoidGlareImg,
        html`Avoid glare`,
        html` <p class="instruction-block__text">
                    Your client’s name, date of birth and other identifying
                    information must be clearly readable.
                </p>`,
      ),
      this.genericInstructionStep(
        infocusImg,
        html`Make sure the document is in focus`,
        html` <p class="instruction-block__text">
                    To ensure valid results, make sure that the photo is in
                    focus and all text is clear and readable. For the best
                    quality scan make sure you’re using as high a DPI (dots per
                    inch) setting as possible.
                </p>`,
      ),
    ];

    this.selectedRoute = "take-photo";

    this.route = this.newInstructionBlocks().concat(this.takePhotoStep);
  }

  firstUpdated() {
    this.addEventListener("options-selection", (el) =>
      this.handleOptionSelect(el.detail),
    );
  }

  newInstructionBlocks() {
    return [
      html`
                <h3 class="instruction-block__title--3">
                    To run an identity check please provide a high quality photo
                    of your client's government-issued photo ID.
                </h3>
                <div class="instruction-block__icon-grid">
                    <img src="${phoneWithIdSVG}" />
                    <section class="instruction-block__text">
                        <p>Securely take a photo on your smartphone</p>
                        <p>
                            Continue this process on your phone to take a photo
                            of the ID you have on hand. Photos taken will not be
                            stored on your phone.
                        </p>
                    </section>
                    <img src="${laptopUploadSVG}" />
                    <section class="instruction-block__text">
                        <p>Or upload a photo from your computer</p>
                        <p>
                            Use your computer to upload the photo or scan of
                            your client's ID directly.
                        </p>
                    </section>
                </div>
            `,
      this.genericInstructionStep(
        smartphoneImg,
        html`Use a smartphone camera to take a photo of your client’s ID`,
        html` <p class="instruction-block__text">
                    By using your smartphone to take a photo of your client’s ID
                    you ensure your photo can be read by our document-scanning
                    technology.
                    <strong
                        >Images will not be saved to your smartphone.</strong
                    >
                </p>`,
      ),
      this.genericInstructionStep(
        invalidScan,
        html`Scanned documents are less likely to yield clear results in
                your CDD report`,
        html`
                    <p class="instruction-block__text">
                        Please consider using your phone, tablet or computer’s
                        camera to take a photo of your client’s government
                        issued photo identity document, following our guidelines
                        to take a good photo.
                    </p>
                `,
      ),
      this.selectOptionStep(),
    ];
  }

  get instructionBlocks() {
    return [
      html`
                <h3 class="instruction-block__title--3">
                    To run an identity check please provide a high quality photo
                    of your client's government-issued photo ID.
                </h3>
                <div class="instruction-block__icon-grid">
                    <img src="${phoneWithIdSVG}" />
                    <section class="instruction-block__text">
                        <p>Take a photo on your smartphone</p>
                        <p>
                            Continue this process on your phone to take a photo
                            of the ID you have on hand.
                        </p>
                    </section>
                    <img src="${laptopUploadSVG}" />
                    <section class="instruction-block__text">
                        <p>Or upload a photo from your computer</p>
                        <p>
                            Use your computer to upload the photo or scan of
                            your client's ID directly.
                        </p>
                    </section>
                </div>
            `,
      this.genericInstructionStep(
        step2SVG,
        html`Take a photo or scan of your client's government issued
                photo identity document`,
        html`<p class="instruction-block__text">
                    Be sure to centre your ID or passport until all four edges
                    are
                    <strong>fully in frame</strong> and
                    <strong>not cutting off any side.</strong>
                    Make sure the whole document is visible if the photo was
                    sent by your client.
                </p>`,
      ),
      this.genericInstructionStep(
        avoidGlareImg,
        html`Avoid glare`,
        html`
                    <p class="instruction-block__text">
                        Your client's name, date of birth and other identifying
                        information must be
                        <strong>clearly readable.</strong> If taking a photo,
                        turn off your phone’s flash and face your document away
                        from bright lights.
                    </p>
                `,
      ),
      this.genericInstructionStep(
        infocusImg,
        html`Make sure your document is in focus`,
        html`
                    <p class="instruction-block__text">
                        To ensure valid results, make sure that the photo is in
                        focus and all text is
                        <strong>clear and readable.</strong> If your document
                        looks blurry while you’re taking your photo with a
                        smartphone tap the document on the screen to focus it.
                    </p>
                `,
      ),
    ];
  }

  genericInstructionStep(imageUrl, title, textBlock) {
    return html`
            <div class="instruction-block__image-container">
                <img
                    class="instruction-block__image--small"
                    src="${imageUrl}"
                />
            </div>
            <h2 class="instruction-block__title--2">${title}</h2>
            <div class="instruction-block">${textBlock}</div>
        `;
  }

  selectOptionStep() {
    return html`
            <div class="instruction-block">How do you want to submit your client’s government-issued
                ID?</h2>
                <legl-options-selector
                    variant="vertical"
                    .options=${[
                      {
                        route: "take-photo",
                        img: "iPhoneWithId",
                        title: "Take a photo in-person (recommended)",
                        text: "When you have your client's physical ID to hand, using your smartphone to take a photo will ensure that it is correctly read by our document-reading technology. Images WILL NOT be saved to your phone.",
                      },
                      {
                        route: "upload-photo",
                        img: "laptopUpload",
                        title: "Upload a photo (no scans)",
                        text: "When you have received a photo of the ID, you can upload it from your computer. Better quality images with no glare and sharp focus will be read best by our document-reading technology.",
                      },
                      {
                        route: "upload-scan",
                        title: "Upload a scan (not recommended)",
                        theme: "warning",
                        footer:
                          "Note: Scanned PDF documents cannot always be verified and will often produce ‘consider’ results in the ‘ID data validation’ section of the report.",
                      },
                    ]}
                ></legl-options-selector>
        `;
  }

  get carousel() {
    return this.shadowRoot.querySelector("legl-carousel");
  }

  handleOptionSelect(details) {
    if (details.optionSelected.route === "take-photo") {
      this.route = this.newInstructionBlocks().concat(this.takePhotoStep);
    }

    if (details.optionSelected.route === "upload-photo") {
      this.route = this.newInstructionBlocks().concat(this.uploadPhotoStep);
    }

    if (details.optionSelected.route === "upload-scan") {
      this.route = this.newInstructionBlocks().concat(this.uploadScanStep);
    }
    this.carousel.stepsCount = 0;
    this.selectedRoute = details.optionSelected.route;
  }

  render() {
    let instructions = "";
    if (!isIE()) {
      instructions = html`
                <legl-carousel
                    class="instructions__section"
                    .completeAction="${() => {
                      this.carousel.hide();
                      this.completeAction(this.selectedRoute);
                    }}"
                    .hideFooter="${true}"
                    .steps="${this.route}"
                >
                </legl-carousel>
            `;
    }
    return instructions;
  }
}

if (!customElements.get("legl-internal-helper-screens")) {
  customElements.define("legl-internal-helper-screens", InternalHelperScreens);
}
