export class BaseAdapter {
  elementId = "payment-method-component";
  providerDetails = null;
  paymentDetails = null;
  amount = null;
  scriptSrc = null;

  async render() {
    const elementSlot = document.getElementById(this.elementId);
    if (!elementSlot) {
      return;
    }
    await this._loadScript();
    await this._appendPaymentMethod(elementSlot);

    this._initialize(elementSlot);
  }

  _initialize(elementSlot) {
    console.log("Initialize completed");
  }

  setElementId(id) {
    this.elementId = id;
  }

  setConfig(providerDetails, amount, paymentDetails) {
    this.providerDetails = providerDetails;
    this.paymentDetails = paymentDetails;
    this.amount = amount;
  }

  _appendPaymentMethod(DomElement) {
    throw Error;
  }

  _scriptExists(src) {
    return document.querySelector(`script[src='${src}']`) !== null;
  }

  async _loadScript() {
    return new Promise((resolve, reject) => {
      if (this.scriptSrc === null) {
        resolve();
        return;
      }

      if (this._scriptExists(this.scriptSrc)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = this.scriptSrc;
      document.body.appendChild(script);

      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error("Failed to load script"));
      };
    });
  }
}
