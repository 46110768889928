import { LitElement, css, html } from "lit";
import { formatCurrency, formatDate } from "../../legl-ui/utils/functions.js";

import { userConfig } from "../lawyers-app/static-src/js/config/user-config";

import "../../legl-ui/number";
import "./contacts-insight-cdd-item";
import "./contacts-insight-item";

import { LeglPermissionsOverlay } from "../../legl-ui/permissions-overlay/src/legl-permissions-overlay.js";

// For some reason, this needs to be repeated here under some weird cases ¯\_(ツ)_/¯
if (!customElements.get("legl-permissions-overlay")) {
  customElements.define("legl-permissions-overlay", LeglPermissionsOverlay);
}


const userPermissionsDisabledMessage =
  "Your account does not have access to use this feature. Ask your account Admin User(s) for permission to view this.";
const engageProductDisabledMessage =
  "Your account does not have access to Engage. Please contact us for more information.";

const payProductDisabledMessage =
  "Your account does not have access to Pay. Please contact us for more information.";

export class ContactsInsights extends LitElement {
  static get properties() {
    return {
      contact: { type: Object },
      permissions: { type: Object },
    };
  }

  static get styles() {
    return css`
            :host .grid {
                background: var(--legl-grey-light);
                display: grid;
                gap: 1px;
                grid-template: 50% 50% / 50% 50%;
                text-align: center;
                width: 100%;
            }

            :host .grid__item {
                background: white;
                box-sizing: border-box;
                padding: 35px;
                width: 100%;
            }
        `;
  }

  get totalPaidAmount() {
    const el = html`<legl-currency
            value=${this?.contact?.insights?.total_amount_paid || 0}
            locale=${userConfig.companyLocale}
            currency=${userConfig.companyCurrency}
        ></legl-currency>`;
    return el;
  }

  render() {
    if (this.contact) {
      return html`
                <div class="grid">
                    <div class="grid__item" data-cy-contacts-insights-last-update>
                        <slot name="item-one"></slot>
                        <legl-permissions-overlay
                            .hasPermissions=${true}
                            .overlayMessage=${userPermissionsDisabledMessage}
                        >
                            <legl-contacts-insights-item
                                .hasPermission=${true}
                            title="last update or activity"
                            content="${
                              formatDate(this.contact.last_updated) || "-"
                            }"
                        ></legl-contacts-insights-item>
                        </legl-permissions-overlay>
                    </div>
                    <div class="grid__item" data-cy-contacts-insights-cdd>
                      <legl-permissions-overlay
                        .hasPermissions=${this.permissions.engage}
                        .overlayMessage=${userConfig.companyProducts.engageEnabled ? userPermissionsDisabledMessage : engageProductDisabledMessage}
                      >
                        <slot name="item-two">
                            <legl-contacts-insights-cdd-item
                                .latestCddStep=${
                                  this?.contact?.insights?.latest_cdd_step
                                }
                            >
                            </legl-contacts-insights-cdd-item
                        ></slot>
                      </legl-permissions-overlay>
                    </div>
                    <div class="grid__item" data-cy-contacts-insights-time-to-pay>
                        <slot name="item-three">
                          <legl-permissions-overlay
                            .hasPermissions=${this.permissions.pay}
                            .overlayMessage=${userConfig.companyProducts.paymentsEnabled ? userPermissionsDisabledMessage : payProductDisabledMessage}
                          >
                            <legl-contacts-insights-item
                                title="average time to pay"
                                .hasToolTip=${true}
                                content=${
                                  this?.contact?.insights
                                    ?.average_time_to_pay || "-"
                                }
                            ></legl-contacts-insights-item>
                          </legl-permissions-overlay>
                        </slot>
                    </div>
                    <div class="grid__item" data-cy-contacts-insights-total-paid>
                        <slot name="item-four">
                          <legl-permissions-overlay
                            .hasPermissions=${this.permissions.pay}
                            .overlayMessage=${userConfig.companyProducts.paymentsEnabled ? userPermissionsDisabledMessage : payProductDisabledMessage}
                          >
                            <legl-contacts-insights-item
                                title="total paid to date"
                                .content=${this.totalPaidAmount}
                            ></legl-contacts-insights-item
                        ></legl-permissions-overlay>
                        </slot>
                    </div>
                </div>
            `;
    }
  }
}

if (!customElements.get("legl-contacts-insights")) {
  customElements.define("legl-contacts-insights", ContactsInsights);
}
