import { LionTextarea } from "@lion/textarea";
import { css } from "lit";
import { neutral } from "../../legl-ui/lds-colours";
import { LeglBaseInputMixin } from "./base-input-mixin.js";

export class LeglTextarea extends LeglBaseInputMixin(LionTextarea) {
  static get styles() {
    return [
      super.styles,
      css`
                :host {
                    min-height: 115px;
                    padding-top: 115px;
                    box-sizing: border-box;
                }

                :host .input-group.input-group {
                    height: auto;
                }
                .input-group__container
                    > .input-group__input
                    ::slotted(.legl-form-control) {
                    width: 100%;
                    max-width: calc(100% - 10px);
                    box-sizing: border-box;
                    padding-right: 0;
                    height: 95px;
                }

                .input-group__container
                    > .input-group__input
                    ::slotted(.legl-form-control)::placeholder {
                    color: transparent;
                }

                .input-group__container
                    > .input-group__input
                    ::slotted(.legl-form-control:focus)::placeholder {
                    color: ${neutral["800"]};
                }
            `,
    ];
  }
}

if (!customElements.get("legl-textarea")) {
  customElements.define("legl-textarea", LeglTextarea);
}
