import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "../../legl-ui/icon";
import { neutral, primary, warning } from "../../legl-ui/lds-colours";
import { spacing } from "../../legl-ui/lds-spacing";

import laptopUploadSVG from "../../../frontend/apps/engage-app/static-src/js/components/element-onfido/img/internal-steps/laptop-upload-id.svg";
import phoneWithIdSVG from "../../../frontend/apps/engage-app/static-src/js/components/element-onfido/img/internal-steps/phone-with-id.svg";

export class LeglOptionsSelector extends LitElement {
  static get styles() {
    return css`
            .container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                border: 1px solid ${neutral["200"]};
                border-radius: 3px;
                justify-content: space-evenly;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: center;
            }
            .option_container {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 20px 0;
                padding: 0 25px;
            }
            .option_container:hover {
                cursor: pointer;
            }
            .option_container:not(:last-child) {
                border-right: 1px solid ${neutral["200"]};
            }
            .option_title {
                font-weight: 700;
            }
            p {
                margin-bottom: 0;
                margin-top: 0;
                top: -10px;
                position: relative;
            }
            .icon_position {
                height: 21.5px;
                top: -10px;
                position: relative;
            }
            .empty_container {
                height: 21.5px;
                top: -10px;
                position: relative;
            }
            .container_vertical {
                grid-template-rows: 1fr;
                grid-template-columns: none;
                justify-content: normal;
                border: none;
            }
            .container_vertical .option_container {
                display: grid;
                grid-template-columns: auto 1fr 0.1fr;
                gap: 0px ${spacing.s};
                margin: 0;
                padding: ${spacing.s} ${spacing.m};
                border: solid 1px ${neutral["200"]};
                border-radius: 3px;
            }
            .container_vertical .option_container.-active.-warning {
                border-color: ${warning["400"]};
            }
            .container_vertical .option_container.-active.-warning legl-icon {
                color: ${warning["400"]};
            }
            .option_container.-active {
                border-color: ${primary["500"]};
            }
            .container_vertical .option_content {
                text-align: left;
            }
            .container_vertical p {
                top: 0;
                color: ${neutral["700"]};
            }
            .container_vertical .icon_position {
                top: 0;
                align-self: center;
                text-align: right;
            }
            .container_vertical .option_title {
                margin-bottom: ${spacing.xxs};
            }
            .container_vertical .footer {
                padding: ${spacing.xs} ${spacing.s};
                margin: 0;
                display: flex;
                text-align: left;
                color: ${neutral["700"]};
                background-color: ${neutral["200"]};
                border-radius: 0px 0px 3px 3px;
            }
            .container_vertical .footer legl-icon {
                margin-right: ${spacing.xs};
                color: ${neutral["800"]};
            }
            .container_vertical .empty_container_img {
                width: 43px;
                height: 21.5px;
            }
        `;
  }

  static get properties() {
    return {
      options: { type: Array },
      currentSelection: { type: Number, attribute: false },
      currentHover: { type: Boolean, attribute: false },
      variant: { type: String },
    };
  }

  constructor() {
    super();
    this.currentSelection = undefined;
    this.currentHover = undefined;
    this.boundIconHover = this.handleIconHover.bind(this);
    this.variant = undefined;
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    // Sets a default currentSelection if the component variant is vertical
    this.variant === "vertical" ? (this.currentSelection = 0) : "";
    this.shadowRoot.querySelectorAll(".option_container").forEach((el, idx) => {
      el.addEventListener("mouseenter", () => {
        this.boundIconHover(idx);
      });
      el.addEventListener("mouseleave", () => {
        this.boundIconHover(undefined);
      });
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.shadowRoot.querySelectorAll(".option_container").forEach((el) => {
      el.removeEventListener("mouseenter", this.boundIconHover);
      el.removeEventListener("mouseleave", this.boundIconHover);
    });
  }

  handleIconHover(index) {
    this.currentHover = index;
  }

  render() {
    return html` <div
            class="${
              this.variant === "vertical"
                ? `container container_vertical`
                : `container`
            }"
        >
            ${
              this.variant === "vertical"
                ? this.options.map((el, idx) =>
                    this.singleOptionVertical(el, idx),
                  )
                : this.options.map((el, idx) => this.singleOption(el, idx))
            }
        </div>`;
  }

  handleSelection(index, option) {
    this.currentSelection = index;
    const event = new CustomEvent("options-selection", {
      detail: { optionSelected: option },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  singleOption(option, index) {
    return html`
            <div
                class="option_container"
                @click=${() => this.handleSelection(index, option)}
            >
                ${
                  this.currentSelection === index || this.currentHover === index
                    ? this.currentSelectionIcon()
                    : this.emptyContainer()
                }
                <p class="option_title">${option.title}</p>
                <p>${option.text}</p>
            </div>
        `;
  }

  singleOptionVertical(option, index) {
    return html`
            <div
                class="option_container
                    ${classMap({
                      "-active": this.currentSelection === index,
                      "-warning": option.theme === "warning",
                    })}
                    "
                @click=${() => this.handleSelection(index, option)}
            >
                ${this.optionImage(option)}
                <div class="option_content">
                    <p class="option_title">${option.title}</p>
                    <p>${option.text}</p>
                </div>
                ${
                  this.currentSelection === index || this.currentHover === index
                    ? this.currentSelectionIcon()
                    : this.emptyContainer()
                }
            </div>
            ${
              this.currentSelection === index && option.footer
                ? this.footerContent(option)
                : ``
            }
        `;
  }

  currentSelectionIcon() {
    return html`
            <legl-icon
                color="green"
                name="legl-icon-confirm-circle"
                size="large"
                class="icon_position"
            >
                >
            </legl-icon>
        `;
  }

  emptyContainer() {
    return html`<span class="empty_container"></span>`;
  }

  optionImage(option) {
    if (option.img === "iPhoneWithId") {
      return html`<img src="${phoneWithIdSVG}" />`;
    }
    if (option.img === "laptopUpload") {
      return html`<img src="${laptopUploadSVG}" />`;
    }
    return html`<span class="empty_container_img"></span>`;
  }

  footerContent(option) {
    return html`<div class="footer">
            <legl-icon
                name="legl-icon-warning-triangle"
                size="small"
                className="icon_position"
            ></legl-icon>
            <p>${option.footer}</p>
        </div>`;
  }
}

if (!customElements.get("legl-options-selector")) {
  customElements.define("legl-options-selector", LeglOptionsSelector);
}
