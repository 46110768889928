import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { nothing } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

import { classMap } from "lit/directives/class-map.js";
import "../../legl-ui/timestamp";

export class LeglActivityCard extends LitElement {
  static get properties() {
    return {
      date: {},
      heading: {},
      state: {},
      viewLink: {
        attribute: "view-link",
      },
      badgeText: {
        attribute: "badge-text",
      },
      badgeIcon: {
        attribute: "badge-icon",
      },
      hoverable: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.hoverable = false;
  }

  static get styles() {
    return css`
            :host {
                display: block;
                --legl-action-bar-item-border-radius: 0px 4px 4px 0px;
                --legl-activity-card-background: var(--legl-grey-lightest);
                --legl-activity-card-hover-background: var(
                    --legl-grey-lightest
                );
                --legl-activity-card-box-shadow: 0px 2px 10px
                    rgba(0, 0, 0, 0.08);
                --legl-activity-card-end-tab-border: solid 1px ${neutral["200"]};
                --legl-activity-card-end-bad-visibility: visible;
            }

            :host .card {
                background: var(--legl-activity-card-background);
                box-shadow: var(--legl-activity-card-box-shadow);
                border-radius: 4px;
                display: flex;
                justify-content: space-between;
            }

            :host .card:hover {
                background: var(--legl-activity-card-hover-background);
            }

            :host .hoverable-card {
                background: transparent;
                box-shadow: none;
            }

            :host .hoverable-card:hover {
                background: ${neutral["200"]};
                box-shadow: none;
                cursor: pointer;
            }

            :host .activity-container {
                overflow-wrap: break-word;
                padding: 0.8rem 0rem 0.8rem 1rem;
                width: calc(100% - 65px);
                position: relative;
                display: grid;
                grid-template:
                    "heading-row"
                    "slotted-content";
            }

            :host .activity-container.has-badge {
                grid-template:
                    "heading-row badge"
                    "slotted-content badge";
            }

            .activity-container .badge-wrapper {
                grid-area: badge;
                display: flex;
                align-items: center;
                justify-self: end;
                margin-right: 0.8rem;
            }

            .activity-container .badge {
                display: flex;
                align-items: center;
                min-width: 16px;
                background: var(--legl-grey-light);
                padding: 8px;
                border-radius: 5px;
                color: ${neutral["800"]};
                font-size: 14px;
                white-space: nowrap;
            }

            .activity-container .badge legl-icon {
                margin-right: 4px;
                margin-top: 2px;
            }

            .activity-container .heading-row {
                grid-area: heading-row;
            }

            .activity-container .slotted-content {
                grid-area: slotted-content;
            }

            :host .heading-row .heading {
                color: ${neutral["800"]};
                font-size: 0.9rem;
                font-weight: 700;
                margin: 0 0 0.1rem 0;
                letter-spacing: 0.01em;
            }

            :host .end-tab {
                width: 65px;
                border-left: var(--legl-activity-card-end-tab-border);
            }

            :host .end-tab legl-action-bar-item {
                background: none;
                border: none;
                visibility: var(--legl-activity-card-end-bad-visibility);
            }

            :host .hoverable-end-tab {
                visibility: hidden;
            }

            :host .hoverable-card:hover .hoverable-end-tab {
                visibility: visible;
            }

            :host .timestamp {
                color: var(--legl-grey-dark);
                font-size: 0.7rem;
                margin-bottom: 1rem;
            }

            :host legl-timestamp {
                display: inline-block;
            }

            .request-status {
                text-transform: initial;
            }

            ::slotted([slot="content"]) {
                display: flex;
                justify-content: flex-start;
            }

            :host legl-action-bar-item {
                width: 100%;
                height: 100%;
            }

            :host([variant="transparent"]) {
                --legl-activity-card-background: transparent;
                --legl-activity-card-hover-background: var(
                    --legl-grey-lightest
                );
                --legl-activity-card-box-shadow: 0;
                --legl-activity-card-end-tab-border: solid 1px transparent;
                --legl-activity-card-end-bad-visibility: hidden;
            }
            :host([variant="transparent"]:hover) {
                --legl-activity-card-end-tab-border: solid 1px ${neutral["200"]};
                --legl-activity-card-end-bad-visibility: visible;
            }
        `;
  }

  get cardClasses() {
    return classMap({
      card: true,
      "hoverable-card": this.hoverable,
    });
  }

  get endTabClasses() {
    return classMap({
      "end-tab": true,
      "hoverable-end-tab": this.hoverable,
    });
  }

  get hasBadge() {
    return Boolean(this.badgeIcon || this.badgeText);
  }

  goToViewLink(event) {
    if (this.hoverable) {
      event.stopPropagation();
      window.location = this.viewLink;
    }
  }

  render() {
    return html`
            <a
                class="${this.cardClasses}"
                @click=${(event) => this.goToViewLink(event)}
            >
                <div
                    class="${classMap({
                      "activity-container": true,
                      "has-badge": this.hasBadge,
                    })}"
                >
                    ${
                      this.heading && this.date && this.state
                        ? html`<div class="heading-row">
                              <p class="heading" data-cy-activity-card-heading>
                                  ${this.heading}
                              </p>
                              <div class="timestamp">
                                  ${this.state}
                                  <legl-timestamp
                                      timestamp="${this.date}"
                                  ></legl-timestamp>
                              </div>
                          </div>`
                        : ""
                    }
                    ${
                      this.hasBadge
                        ? html`<div class="badge-wrapper">
                              <span class="badge"
                                  >${
                                    this.badgeIcon
                                      ? html`<legl-icon
                                            .name=${this.badgeIcon}
                                        ></legl-icon>`
                                      : nothing
                                  }<span
                                      >${this.badgeText}</span
                                  ></span
                              >
                          </div>`
                        : nothing
                    }
                    <div class="slotted-content">
                        <slot></slot>
                    </div>
                </div>
                <div class="${this.endTabClasses}">
                    <legl-action-bar-item
                        slot="primary"
                        icon="legl-icon-review"
                        href="${this.viewLink}"
                        size="fullsize"
                        >view
                    </legl-action-bar-item>
                </div>
            </a>
        `;
  }
}

if (!customElements.get("legl-activity-card")) {
  customElements.define("legl-activity-card", LeglActivityCard);
}
