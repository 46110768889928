import { LionInput } from "@lion/input";
import { css } from "lit";

import { IsDate, MaxDate, MinDate } from "@lion/form-core";
import { formatDate } from "../../legl-ui/utils";
import { LeglBaseInputMixin } from "./base-input-mixin.js";
export class LeglIsDate extends IsDate {
  execute(value) {
    const [dd, mm, yyyy] = `${value}`.split("/");
    return (
      Number.isNaN(Date.parse(`${mm}/${dd}/${yyyy}`)) ||
      (yyyy && yyyy.length !== 4) ||
      (mm && mm.length !== 2) ||
      (dd && dd.length !== 2)
    );
  }

  static getMessage(data) {
    return `${data.fieldName} must be in DD/MM/YYYY format.`;
  }
}

export class LeglMaxDate extends MaxDate {
  execute(value) {
    const [dd, mm, yyyy] = `${value}`.split("/");
    return super.execute(new Date(`${mm}/${dd}/${yyyy}`));
  }
  static getMessage(data) {
    return `${data.fieldName} must be before ${formatDate(
      data.params.toString(),
    )}.`;
  }
}

export class LeglMinDate extends MinDate {
  execute(value) {
    const [dd, mm, yyyy] = `${value}`.split("/");
    return super.execute(new Date(`${mm}/${dd}/${yyyy}`));
  }
  static getMessage(data) {
    return `${data.fieldName} must be after ${formatDate(
      data.params.toString(),
    )}.`;
  }
}
export class LeglInputDate extends LeglBaseInputMixin(LionInput) {
  static get styles() {
    return [
      super.styles,
      css`
                :host .form-field__label {
                    width: calc(100% - 40px);
                }
                :host([focused]) .form-field__label {
                    width: auto;
                }
                :host([filled]) .form-field__label {
                    width: auto;
                }
            `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("keydown", this.insertCharacter);
    this.addEventListener("paste", this.constructor.blockPaste);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("keydown", this.insertCharacter);
    this.removeEventListener("paste", this.constructor.blockPaste);
  }

  firstUpdated() {
    super.firstUpdated(this.changedProperties);
    const input = this._inputNode;
    input.setAttribute("maxlength", 10);
    input.setAttribute("placeholder", "DD/MM/YYYY");
  }

  static blockPaste(event) {
    event.preventDefault();
  }

  insertCharacter(event) {
    if (!this.isValidCharacter(event)) {
      event.preventDefault();
      return;
    }

    if (
      (this.value.length === 2 || this.value.length === 5) &&
      event.code !== "Backspace"
    ) {
      this.insertSlash(event);
    }
  }

  isValidCharacter(event) {
    return /[0-9]{1}/.test(event.key) || event.code === "Backspace";
  }

  insertSlash(event) {
    const text = "/";
    const val = this.value;
    let endIndex;
    if (this.selectionStart !== undefined && this.selectionEnd !== undefined) {
      endIndex = this.selectionEnd;
      this.value = `${val.slice(0, this.selectionStart)}${text}${val.slice(
        endIndex,
      )}`;
      this.selectionStart = this.selectionEnd = endIndex + text.length;
    }
  }
}

if (!customElements.get("legl-input-date")) {
  customElements.define("legl-input-date", LeglInputDate);
}
