import "@webcomponents/scoped-custom-element-registry";

import "../../../apps/lawyers-contacts/contacts-insights.js";
import "../../../legl-ui/activity-log";
import "../../../legl-ui/adyen-component/src/adyen-component.js";
import "../../../legl-ui/card";
import "../../../legl-ui/expandable-panel";
import "../../../legl-ui/fullscreen-loading-spinner";
import "../../../legl-ui/lds-alert";
import "../../../legl-ui/lds-button";
import "../../../legl-ui/lds-colours/legacy/scss/legl-variables.scss";
import "../../../legl-ui/lds-date-input";
import "../../../legl-ui/lds-date-of-birth-input";
import "../../../legl-ui/lds-date-picker";
import "../../../legl-ui/lds-flag-icon";
import "../../../legl-ui/lds-icon";
import "../../../legl-ui/lds-input-date";
import "../../../legl-ui/lds-link-button";
import "../../../legl-ui/lds-progress-bar";
import "../../../legl-ui/lds-spinner";
import "../../../legl-ui/lds-textarea";
import "../../../legl-ui/menu";
import "../../../legl-ui/permissions-overlay";
import "../../../legl-ui/postcode-lookup-controller";
import "../../../legl-ui/request-status";

import "../../../legl-ui/drawer";
import { setCustomColourProperties } from "../../../legl-ui/lds-colours";
import { setCustomElevationProperties } from "../../../legl-ui/lds-elevation";
import "../../../legl-ui/lds-nav";
import { setCustomSpacingProperties } from "../../../legl-ui/lds-spacing";
import { setCustomTypographyProperties } from "../../../legl-ui/lds-typography";

import { userConfig } from "../../../apps/lawyers-app/static-src/js/config/user-config.js";
import { drawers } from "../../../apps/lawyers-app/static-src/js/dialog-manager/drawer-manager.js";
import { modals } from "../../../apps/lawyers-app/static-src/js/dialog-manager/modal-manager.js";

import "../../../apps/lawyers-app/static-src/js/components/modals/admin-departments-modal/admin-departments-modal.js";
import "../../../apps/lawyers-app/static-src/js/drawers/contact/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/engage/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/engage/view.js";
import "../../../apps/lawyers-app/static-src/js/drawers/instant-screening/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/manualCDD/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/manualCDD/legl-manual-cdd.scss";
import "../../../apps/lawyers-app/static-src/js/drawers/ongoing-monitoring/ViewMonitoringUpdate.js";
import "../../../apps/lawyers-app/static-src/js/drawers/pay/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/pay/view.js";
import "../../../apps/lawyers-app/static-src/js/drawers/payment-plans/legl-payment-plan-review.js";
import "../../../apps/lawyers-app/static-src/js/drawers/phone-payment/create.js";
import "../../../apps/lawyers-app/static-src/js/drawers/risk-assessment/upload.js";

import { WaffleFlags } from "../../../waffle-flags";
import "../src/views/businesses/business-detail/tabs/ongoing-monitoring/components/ViewCompanyReportMonitoringUpdate.js";

async function bootstrapVueApp() {
  await userConfig.fetchConfig();
  const nav = document.getElementById("main-navigation");
  if (nav) {
    nav.navigationConfig = userConfig.navigation;
  }
  if (userConfig.isCurrentUserAdmin) {
    modals.register(
      "legl-admin-departments-modal",
      {
        config: userConfig.getModalConfig("adminDepartments"),
      },
      "admin-departments",
    );
  }
  if (!waffle.flag_is_active(WaffleFlags.PAYMENT_PLANS_DETAIL_DRAWER_VUE)) {
    drawers.register(
      "legl-payment-plan-review",
      {
        drawerTitle: "View payment plans details",
        drawerIcon: "lds-icon-Review",
      },
      "payment-plan-review",
      "pay",
    );
  }

  drawers.register(
    "legl-pay-review",
    { drawerTitle: "View payment details", drawerIcon: "lds-icon-Review" },
    "pay-review",
    "pay",
  );
  drawers.register(
    "legl-instant-screening",
    {
      drawerTitle: "Instant screening",
      drawerIcon: "lds-icon-WatchlistScreening",
      withCloseWarning: false,
    },
    "create-instant-screening",
    "create_engage_request",
  );
  drawers.register(
    "legl-engage-review",
    {
      drawerTitle: "View engage request",
      drawerIcon: "lds-icon-Review",
    },
    "engage-review",
    "engage",
  );
  drawers.register(
    "legl-manual-cdd",
    {
      drawerTitle: "Run in-person CDD",
      drawerIcon: "lds-icon-Engage",
      withCloseWarning: true,
    },
    "manual-cdd",
    "engage",
  );
  drawers.register(
    "legl-manual-cdd",
    {
      drawerTitle: "Turn on ongoing monitoring",
      drawerIcon: "lds-icon-Monitor",
      withCloseWarning: true,
    },
    "manual-cdd-om",
    "create_engage_request",
  );
  drawers.register(
    "legl-monitoring-update",
    {
      drawerTitle: "View monitoring update",
      drawerIcon: "lds-icon-Monitor",
    },
    "business-ongoing-monitoring-alert",
    "ongoing_monitoring",
  );

  drawers.register(
    "legl-monitoring-update",
    {
      drawerTitle: "View monitoring update",
      drawerIcon: "lds-icon-Monitor",
    },
    "ongoing-monitoring-alert",
    "ongoing_monitoring",
  );

  drawers.register(
    "legl-company-report-monitoring-update",
    {
      drawerTitle: "View monitoring update",
      drawerIcon: "lds-icon-Monitor",
    },
    "ongoing-company-report-monitoring-update",
    "ongoing_monitoring",
  );

  drawers.register(
    "legl-risk-assessment-upload",
    {
      drawerTitle: "Risk assessment upload",
      drawerIcon: "lds-icon-Screening",
    },
    "risk-assessment-upload",
  );

  modals.startRouter();
  drawers.startRouter();
}

setCustomColourProperties();
setCustomSpacingProperties();
setCustomTypographyProperties();
setCustomElevationProperties();

export { bootstrapVueApp };
