import { LitElement, css, html } from "lit";
import "../../legl-ui/activity-card";
import { neutral } from "../../legl-ui/lds-colours";

export class ContactCard extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
            }

            .contact {
                display: flex;
                align-items: center;
            }

            .contact-name-email {
                max-width: 165px;
            }

            .contact-name,
            .contact-email {
                font-size: 12px;
                font-weight: normal;
                color: ${neutral["900"]};
            }

            .contact-name {
                font-weight: 700;
            }
        `;
  }

  static get properties() {
    return {
      contact: {},
      hoverable: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.hoverable = false;
  }

  render() {
    return html`
            <legl-activity-card
                view-link="/contacts/${this.contact?.uid}"
                variant="transparent"
                .hoverable=${this.hoverable}
            >
                <div class="contact">
                    <div class="contact-name-email">
                        <div class="contact-name" data-cy-contact-card-name>
                            ${this.contact?.name}
                            <div>
                                <div
                                    class="contact-email"
                                    data-cy-contact-card-email
                                >
                                    ${this.contact?.email}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </legl-activity-card>
        `;
  }
}

if (!customElements.get("legl-contact-card")) {
    customElements.define("legl-contact-card", ContactCard);
}
