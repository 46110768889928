import { runCompanyReport } from "@/views/businesses/business-detail/BusinessDetailApi";
import axios from "axios";
import { LitElement, css, html, nothing } from "lit";
import "../../../../../../../../../apps/lawyers-app/static-src/js/components/modals/assign-monitoring-reviewer-modal/assign-monitoring-reviewer-modal.js";
import { userConfig } from "../../../../../../../../../apps/lawyers-app/static-src/js/config/user-config";
import "../../../../../../../../../legl-ui/activity-log";
import {
  neutral,
  primary,
} from "../../../../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../../../../legl-ui/lds-modal";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../../../../legl-ui/lds-typography";
import { get, post } from "../../../../../../../../../legl-ui/utils/fetch.js";
import { formatDateTime } from "../../../../../../../../../legl-ui/utils/functions.js";
import "./mark-company-monitoring-update-review-ui.js";

class CompanyReportMonitoringUpdate extends LitElement {
  static get properties() {
    return {
      alert: { attribute: false },
      params: { attribute: false, state: true },
      reviewUrl: { attribute: false, state: true },
    };
  }

  constructor() {
    super();
    this.alert = null;
    this.params = new URLSearchParams();
  }

  static get styles() {
    return css`
            :host {
                display: block;
                ${typographyPresets.body};
            }

            a {
                color: ${neutral["800"]};
            }

            .o-alert__content-details {
                width: 100%;
            }

            .o-alert__contact-alert-details {
                display: grid;
                grid-template-columns: 280px 1fr;
                grid-gap: ${spacing.s};
                padding-top: ${spacing.m};
                margin: 0;
                align-items: top;
            }

            .o-alert__contact-alert-details:not(.reviewer-ui) {
                margin-bottom: ${spacing.s};
            }

            .o-alert__meta-label {
                font-weight: 700;
            }

            .o-alert__title {
                font-size: 20px;
                margin-bottom: 32px;
                color: ${neutral["800"]};
            }

            .o-alert__pdf {
                margin-top: 40px;
                display: block;
            }

            .reviewer-button {
                height: auto;
            }

            .reviewer-wrapper {
                display: flex;
                align-items: center;
                gap: ${spacing.xs};
            }

            .associated-businesses {
                margin: 0;
            }

            .associated-businesses dd {
                margin-left: 0;
                ${typographyPresets.small};
            }

            .associated-businesses dt:not(:first-of-type) {
                margin-top: ${spacing.s};
            }

            .view-report-link {
                color: ${primary["500"]};
            }

            .reviewer-ui {
                border-top: 1px solid ${neutral["200"]};
            }

            .monitoring-update-data {
              margin-top: ${spacing.m};
            }

            h3 {
              margin: 0 0 ${spacing.m};
            }

            .table-wrapper, table {
              width: 100%;
            }

            table {
              border-collapse: collapse;
            }

            th {
              text-align: left;
              padding-bottom: ${spacing.s};
              ${typographyPresets.mediumBold};
            }

            tr {
              border-bottom: 1px solid ${neutral["200"]};
            }

            .value-pair {
              flex: 1;
            }

            .value-pair:nth-child(2) {
              margin-left: ${spacing.s}
            }

            dl {
              display: flex;
              margin: 0 0 ${spacing.s} 0;
            }

            dd {
              margin-left: 0;
            }

            h4 {
              margin: ${spacing.xs} 0;
            }
        `;
  }

  firstUpdated() {
    this.fetchAlert();
  }

  get alertId() {
    return this.params.get("alertId");
  }

  get businessId() {
    return this.params.get("businessId");
  }

  async fetchAlert() {
    const updateUrl = `/api/company_report_monitoring_update_batch/${this.alertId}/`;
    const res = await get(updateUrl);
    if (res.ok) {
      const json = await res.json();
      this.alert = json;
    } else {
      LdsToast.showError({
        text: "Unable to load alert. Please try again later.",
      });
    }
  }

  reloadTables = () => {
    if (window.location.pathname.includes("monitoring")) {
      window.dispatchEvent(
        new CustomEvent("reload-monitoring-dashboard-table"),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent("reload-business-monitoring-alerts-table"),
      );
    }
  };

  markAsReviewed = async (comment) => {
    const res = await this.postReview(comment);
    if (res.ok) {
      const reviewedAlert = await res.json();
      this.alert = {
        ...this.alert,
        status: reviewedAlert.status,
        events: reviewedAlert.events,
      };
      LdsToast.showSuccess({
        text: "Marked as reviewed.",
        autoClose: true,
      });
      this.reloadTables();
    } else {
      LdsToast.showError({ text: "Error saving, please try again" });
    }
  };

  markAsReviewedAndUpdate = async (comment) => {
    const runCompanyReportResult = await this.handleRunCompanyReport();
    if (runCompanyReportResult) {
      return await this.postReview(
        comment,
        "ALERT_REVIEWED_AND_REPORT_UPDATED",
      );
    }
    return Promise.reject(new Error("Error running company report"));
  };

  postReview = async (comment, action) => {
    const reviewUrl = `/api/company_report_monitoring_update_batch/${this.alertId}/review/`;
    const res = await post(`${reviewUrl}`, {
      body: JSON.stringify({
        comment,
        action,
      }),
    });
    return res;
  };

  getEventIndividual(event) {
    return event.triggered_by ? event.triggered_by.name : "Legl";
  }

  getActivityText(event) {
    switch (event.type) {
      case "alert_created":
        return "Update was reported.";
      case "alert_reviewed":
        return `${event?.triggered_by?.name} marked the update as reviewed. Company report was not updated.`;
      case "alert_reviewed_and_report_updated":
        return `${event?.triggered_by?.name} marked the update as reviewed. Company report was updated.`;
      case "alert_reviewer_assigned":
        return `${event?.triggered_by?.name} assigned ${event?.associated_individual?.name} as the reviewer.`;
      default:
        return "";
    }
  }

  get sortedHistory() {
    return this.alert.events
      ?.map((x) => ({ ...x }))
      .sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1;
        }

        if (b.created_at > a.created_at) {
          return 1;
        }
        return 0;
      })
      .map((event) => {
        return html`
            <legl-activity-log
                data-cy-review-activity-log
                comment=${event.comment}
                user=${this.getEventIndividual(event)}
                .activityText=${this.getActivityText(event)}
                date=${event.created_at}
            ></legl-activity-log>
        `;
      });
  }

  openAssignReviewerModal = () => {
    LdsModal.create({
      title: "Assign reviewer",
      slotComponent: "assign-monitoring-reviewer",
      slotComponentProperties: {
        monitoringAlertId: this.alertId,
        reviewersOptions: userConfig.engageReviewers,
        assignedReviewer: this.alert.assigned_reviewer?.pk,
        monitoringUpdateType: "company_report",
      },
    });
  };

  handleRunCompanyReport = async (createdAutomatically = false) => {
    try {
      const { data } = await runCompanyReport({
        provider_company_id: this.alert.business.provider_id,
        country_iso_code: this.alert.business.country,
        business_id: this.alert.business.id,
        created_automatically: createdAutomatically,
      });
      if (!this.alert.business.creditsafe_company_id) {
        window.location.href = `/company_report_v2/${data.reference}`;
      } else {
        window.location.href = `/business_cdd/reports/${data.reference}`;
      }

      return data;
    } catch (e) {
      let text =
        "We were unable to create a report at this time. Please try again later.";
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        text =
          "There is insufficient information on this company to provide a report. You will need to request information using your firms Engage workflows.";
      }
      LdsToast.showError({
        title: "Unable to create company report",
        text,
        autoClose: true,
      });
    }
  };

  get assignedReviewerUI() {
    return html`<dt class="o-alert__meta-label">
                Monitoring update reviewer
            </dt>
            <dd class="o-alert__meta-datum">
                ${
                  this.alert.assigned_reviewer
                    ? html`<div class="reviewer-wrapper">
                          <span>${this.alert.assigned_reviewer?.name}</span>
                          <div>
                              <lds-button
                                  class="reviewer-button"
                                  colour="primary"
                                  variant="outlined"
                                  .small=${true}
                                  @click=${this.openAssignReviewerModal}
                                  >Change</lds-button
                              >
                          </div>
                      </div>`
                    : html`<div>
                          <lds-button
                              class="reviewer-button"
                              colour="primary"
                              variant="outlined"
                              .small=${true}
                              @click=${this.openAssignReviewerModal}
                          >
                              Assign reviewer
                          </lds-button>
                      </div>`
                }
            </dd>`;
  }

  get reviewerUI() {
    return html`<dt class="o-alert__meta-label">Reviewer</dt>
            <dd class="o-alert__meta-datum">
                ${
                  this.alert.events.find(
                    (event) =>
                      event.type === "alert_reviewed" ||
                      event.type === "alert_reviewed_and_report_updated",
                  )?.triggered_by?.name || "-"
                }
            </dd>`;
  }

  get businessDetails() {
    return html`
        <dl class="o-alert__contact-alert-details">
            <dt class="o-alert__meta-label">Company name</dt>
            <dd class="o-alert__meta-datum">${this.alert.business.name}</dd>
            <dt class="o-alert__meta-label">Company number</dt>
            <dd class="o-alert__meta-datum">
                ${this.alert.business.registration_number || "-"}
            </dd>
            <dt class="o-alert__meta-label">Address</dt>
            <dd class="o-alert__meta-datum">
                ${this.alert.business.simple_address || "-"}
            </dd>
            <dt class="o-alert__meta-label">Updated at</dt>
            <dd class="o-alert__meta-datum">
                ${
                  formatDateTime(
                    this.alert.updated_at
                      ? this.alert.updated_at
                      : this.alert.created_at,
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    },
                  ) || "-"
                }
            </dd>
            <dt class="o-alert__meta-label">Latest report</dt>
            <dd>
                <div>${this.alert.company_report_created_by}</div>
                <a
                    class="view-report-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href=${this.alert.company_report_reference && this.alert.company_report_reference.startsWith("dnb") ? `/company_report_v2/${this.alert.company_report_reference}` : `/business_cdd/reports/${this.alert.company_report_reference}`}
                    >View report</a
                >
            </dd>
        </dl>
        <dl class="o-alert__contact-alert-details reviewer-ui">
            ${
              this.alert.status === "Reviewed"
                ? this.reviewerUI
                : this.assignedReviewerUI
            }
        </dl>
    `;
  }

  updateDataTableRow = ({ data, label }) => {
    let oldValue;
    let newValue;
    if (Array.isArray(data.oldValue)) {
      oldValue = "";
    } else {
      oldValue = data.oldValue;
    }
    if (Array.isArray(data.newValue) && Array.isArray(data.oldValue)) {
      newValue =
        data.oldValue.length > data.newValue.length
          ? "An element was removed"
          : "An element was added";
    } else {
      newValue = data.newValue;
    }
    return html`
    <tr>
      <td>
        <h4>${label ? label : data.ruleName}</h4>
        ${
          data.oldValue || data.newValue
            ? html`<dl>
          <div class="value-pair">
            <dt>Previous value:</dt>
            <dd>
              ${oldValue || "-"}
            </dd>
          </div>
          <div class="value-pair">
            <dt>New value:</dt>
            <dd>
              ${newValue || "-"}
            </dd>
          </div>
        </dl>`
            : nothing
        }
      </td>
        <td>
          ${
            formatDateTime(data.eventDate, {
              month: "short",
              day: "numeric",
              year: "numeric",
            }) || "-"
          }
        </td>
    </tr>
  `;
  };

  render() {
    if (!this.alert) {
      return nothing;
    }
    return html`
        <div class="o-alert" data-cy="monitoring-alert-drawer">
            <div class="o-alert__status">
                <legl-request-status status=${this.alert.status}
                    >${this.alert.status}</legl-request-status
                >
            </div>
            <h2 class="o-alert__title" data-cy="monitoring-alert-title">Company report monitoring update</h2>
            <legl-card class="o-alert__alert-details-card">
                <h3 slot="title" variant="corner">MONITORING DETAILS</h3>
                <div class="o-alert__content-details">
                    ${this.businessDetails}
                </div>
            </legl-card >
            <legl-card class="monitoring-update-data">
              <div class="table-wrapper">
              <h3 variant="corner">Updates to review</h3>
              <table>
                <thead><th>Description</th><th>Reported date</th></thead>
                <tbody>
                  ${this.alert.batch_data.map((batch_data) =>
                    this.updateDataTableRow(batch_data),
                  )}
                </tbody>
              </table>
              </div>
            </legl-card>
            ${
              this.alert?.status === "Ready for review"
                ? html`<mark-company-monitoring-update-review-ui
                      data-cy="mark-review"
                      .alertId=${this.alertId}
                      .markAsReviewed=${this.markAsReviewed}
                      .markAsReviewedAndUpdate=${this.markAsReviewedAndUpdate}
                  ></mark-company-monitoring-update-review-ui>`
                : nothing
            }
            <div id="update-alert-history">
                <h2 class="o-alert__title">Refresh alert history</h2>
                ${this.sortedHistory}
            </div>
        </div>
    `;
  }
}

customElements.define(
  "legl-company-report-monitoring-update",
  CompanyReportMonitoringUpdate,
);
