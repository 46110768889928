import DOMPurify from "dompurify";
import { flatten } from "flattenizer";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

export { preventDoubleSubmit } from "./prevent-double-submit.js";

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(this, args);
    }
  };
}

export function isIE(userAgent = window.navigator.userAgent) {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(userAgent);
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function uuidv4() {
  /* https://stackoverflow.com/a/2117523/13361341 */
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}

export function formatDate(val, options = {}) {
  if (val && val.length > 0 && !Number.isNaN(Date.parse(val))) {
    let valDate;
    /**
     * When receiving only a ISO date and not datetime, the JS date object has
     * some weird behaviour around timezone interpretation which causes our DOB
     * renderings to be off eg: 2000-01-01 renders as 1999-12-31 in Canada
     */
    if (/\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(val)) {
      valDate = new Date(`${val}T00:00:00.000`);
    } else {
      valDate = new Date(val);
    }
    return (
      new Intl.DateTimeFormat("en-GB", options)
        .format(valDate)
        /* IE11 Intl date format API seems to throw a bunch of left-to-right unicode marks
                        in the output so this strips out non-ASCII characters because we don't
                        care about them in our use case and they cause problems with the validation
                        TODO: Remove after IE11 deprecation */
        .replace(/[^\x00-\x7F]/g, "")
    );
  }
  return val;
}

export function formatDateTime(val, emptyValue = null) {
  return (
    formatDate(val, {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }) || emptyValue
  );
}
export function formatNumber(value, options = {}) {
  return new Intl.NumberFormat("en-GB", {
    maximumSignificantDigits: 3,
    ...options,
  }).format(value);
}

export function formatCurrency(value, options = {}) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    ...options,
  }).format(value);
}

export function has(object, key) {
  return Object.prototype.hasOwnProperty.call(object, key);
}

export function stringToHTML(message = "") {
  return unsafeHTML(
    DOMPurify.sanitize(message, {
      ADD_TAGS: ["legl-currency"],
      ADD_ATTR: ["currency", "locale"],
    }),
  );
}

export function getCookie(key) {
  const cookies = document.cookie.split("; ").reduce((acc, item) => {
    const parts = item.split("=");
    acc.set(parts[0], parts[1]);
    return acc;
  }, new Map());
  return cookies.get(key);
}

export function getCsrfToken() {
  return getCookie("csrftoken");
}

export function luhnCheck(value) {
  // https://gist.github.com/DiegoSalazar/4075533
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false;
  value = value.replace(/\D/g, "");

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n),
      nDigit = Number.parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
}

export function isCreditCardNumer(value) {
  return value.length > 12 && luhnCheck(value);
}

export function pushEvent(eventName, eventDetails, optionsOverrides = {}) {
  const options = {
    intercom: true,
    ...optionsOverrides,
  };
  if (window.Intercom && options.intercom) {
    const flattened = eventDetails ? flatten(eventDetails) : {};
    window.Intercom("trackEvent", eventName, flattened);
  }
}

export function daysAgo(date) {
  const today = new Date();
  const logDate = new Date(date);

  const differenceInTime = today.getTime() - logDate.getTime();

  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.round(differenceInDays);
}

export function camelToSnakeCase(inputString) {
  return inputString
    .split("")
    .map((character, index) => {
      if (character === character.toUpperCase()) {
        const prefix = index > 0 ? "_" : "";
        return `${prefix}${character.toLowerCase()}`;
      } else {
        return character;
      }
    })
    .join("");
}

export function snakeToCamelCase(inputString) {
  return inputString
    .split("_")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return `${word.slice(0, 1).toUpperCase()}${word
        .slice(1, word.length)
        .toLowerCase()}`;
    })
    .join("");
}

export function snakeToSentenceCase(inputString, allLowercase = false) {
  return inputString
    .split("_")
    .map((word, index) => {
      if (index === 0 && !allLowercase) {
        return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
      }
      return word.toLowerCase();
    })
    .join(" ");
}

export function pascaleToKebabCase(string) {
  return string.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase();
}

export function kebabToPascaleCase(string) {
  return string.replace(/(^\w|-\w)/g, (text) =>
    text.replace(/-/, "").toUpperCase(),
  );
}

export function titleCase(string) {
  if (typeof string !== "string") {
    return "";
  }
  return (
    string
      .trim()
      .toLowerCase()
      // split string on spaces, tabs, newlines - used to remove whitespace
      .split(/[\s\t\n]+/)
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" ")
  );
}

export function objectToQueryString(params) {
  if (!params) {
    return "";
  }
  const searchParams = new URLSearchParams(params);
  if (searchParams.size === 0) {
    return "";
  }
  return `?${searchParams}`;
}

export function formatAddress(address) {
  return [
    [address?.address_line_1, address?.address_line_2],
    [address?.town, address?.state],
    [address?.country],
    [address?.postcode],
  ]
    .map((addressGroupIn) => {
      const formattedGroup = addressGroupIn
        .reduce((acc, addressPart) => {
          if (addressPart) {
            acc.push(addressPart.trim());
          }
          return acc;
        }, [])
        .join(" ");
      return formattedGroup;
    })
    .filter((group) => Boolean(group))
    .join(", ");
}

export function formatFileName(filename, emptyValue = null) {
  // Attempts to remove directories from filepaths to only show filename
  const name = filename?.split("/")?.pop();
  return name || emptyValue;
}

/*
#YYYY#     4-digit year             1999
#YY#       2-digit year             99
#MMMM#     full month name          February
#MMM#      3-letter month name      Feb
#MM#       2-digit month number     02
#M#        month number             2
#DDDD#     full weekday name        Wednesday
#DDD#      3-letter weekday name    Wed
#DD#       2-digit day number       09
#D#        day number               9
#th#       day ordinal suffix       nd
#hhhh#     2-digit 24-based hour    17
#hhh#      military/24-based hour   17
#hh#       2-digit hour             05
#h#        hour                     5
#mm#       2-digit minute           07
#m#        minute                   7
#ss#       2-digit second           09
#s#        second                   9
#ampm#     "am" or "pm"             pm
#AMPM#     "AM" or "PM"             PM

example:
        const time = msToDate(
            new Date(milliseconds, "#DD# #MMM# #YYYY#, #hh#:#mm#"
        );
 */
export function millisecondsToDate(ms, formatString) {
  let YYYY,
    YY,
    MMMM,
    MMM,
    MM,
    M,
    DDDD,
    DDD,
    DD,
    D,
    hhhh,
    hhh,
    hh,
    h,
    mm,
    m,
    ss,
    s,
    ampm,
    AMPM,
    dMod,
    th;
  YY = ((YYYY = ms.getFullYear()) + "").slice(-2);
  MM = (M = ms.getMonth() + 1) < 10 ? "0" + M : M;
  MMM = (MMMM = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][M - 1]).substring(0, 3);
  DD = (D = ms.getDate()) < 10 ? "0" + D : D;
  DDD = (DDDD = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][ms.getDay()]).substring(0, 3);
  th =
    D >= 10 && D <= 20
      ? "th"
      : (dMod = D % 10) === 1
        ? "st"
        : dMod === 2
          ? "nd"
          : dMod === 3
            ? "rd"
            : "th";
  formatString = formatString
    .replace("#YYYY#", YYYY)
    .replace("#YY#", YY)
    .replace("#MMMM#", MMMM)
    .replace("#MMM#", MMM)
    .replace("#MM#", MM)
    .replace("#M#", M)
    .replace("#DDDD#", DDDD)
    .replace("#DDD#", DDD)
    .replace("#DD#", DD)
    .replace("#D#", D)
    .replace("#th#", th);
  h = hhh = ms.getHours();
  if (h === 0) h = 24;
  if (h > 12) h -= 12;
  hh = h < 10 ? "0" + h : h;
  hhhh = hhh < 10 ? "0" + hhh : hhh;
  AMPM = (ampm = hhh < 12 ? "am" : "pm").toUpperCase();
  mm = (m = ms.getMinutes()) < 10 ? "0" + m : m;
  ss = (s = ms.getSeconds()) < 10 ? "0" + s : s;
  return formatString
    .replace("#hhhh#", hhhh)
    .replace("#hhh#", hhh)
    .replace("#hh#", hh)
    .replace("#h#", h)
    .replace("#mm#", mm)
    .replace("#m#", m)
    .replace("#ss#", ss)
    .replace("#s#", s)
    .replace("#ampm#", ampm)
    .replace("#AMPM#", AMPM);
}

export function copyToClipboard(content) {
  // Create textarea to copy content to
  const textArea = document.createElement("textarea");
  textArea.value = content;

  // Append textarea to body
  document.body.appendChild(textArea);

  // Highlight and select text
  textArea.select();
  textArea.setSelectionRange(0, 99999);

  // Copy text refactor the below deprecated method
  document.execCommand("copy");

  // Remove textarea
  document.body.removeChild(textArea);
}

export function isEmpty(value) {
  // Check for null or undefined
  if (value == null) {
    return true;
  }

  // Check for array-like values (including strings and arguments)
  if (
    Array.isArray(value) ||
    typeof value === "string" ||
    (typeof value === "object" && value.constructor === Object)
  ) {
    return Object.keys(value).length === 0;
  }

  // Check for Maps and Sets
  if (value instanceof Map || value instanceof Set) {
    return value.size === 0;
  }

  // Check for other types (numbers, boolean, etc) which are not considered collections
  // and thus not "empty" in the same sense as above, returning false by default
  return false;
}

export function capitalize(str) {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }

  // Convert the first character to upper case and the rest to lower case
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function truncate(string = "", options = {}) {
  const { length = 30, omission = "...", separator } = options;

  if (string.length <= length) return string;

  let truncated = string.slice(0, length);

  if (separator) {
    if (typeof separator === "string") {
      const lastIndex = truncated.lastIndexOf(separator);
      if (lastIndex > -1) {
        truncated = truncated.slice(0, lastIndex);
      }
    } else if (separator instanceof RegExp) {
      // Ensure the regex is global
      const globalSeparator = new RegExp(
        separator.source,
        "g" +
          (separator.ignoreCase ? "i" : "") +
          (separator.multiline ? "m" : ""),
      );
      const matches = [...truncated.matchAll(globalSeparator)];

      if (matches.length > 0) {
        const lastMatch = matches[matches.length - 1];
        truncated = truncated.slice(0, lastMatch.index);
      }
    }
  }

  // Ensure the final string, including the omission, doesn't exceed the specified length
  if (truncated.length + omission.length > length) {
    truncated = truncated.slice(0, length - omission.length);
  }

  return truncated + omission;
}

/**
 * Add the specified number of months to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of months to be added.
 * @returns {Date} The new date with the months added.
 *
 */
export function addMonths(date, amount) {
  if (isNaN(amount)) {
    return new Date(Number.NaN); // Return an invalid date if the amount is not a number
  }

  const newDate = new Date(date);
  const dayOfMonth = newDate.getDate();

  newDate.setMonth(newDate.getMonth() + amount + 1, 0); // Set the date to the end of the target month
  const daysInMonth = newDate.getDate();

  if (dayOfMonth >= daysInMonth) {
    return newDate; // If the original day was at or after the end of the month, return the end-of-month date
  } else {
    // Set the date to the original day in the target month, adjusting for end-of-month.
    newDate.setDate(Math.min(dayOfMonth, daysInMonth));
    newDate.setMonth(newDate.getMonth()); // This readjusts the month in case setting the date overflowed
    return newDate;
  }
}

export function formatAmount(amount, currency = "GBP") {
  if (Boolean(amount) || Number(amount) === 0) {
    if (!isNaN(amount)) {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: currency,
      }).format(amount);
    }
    return amount;
  }
  return "-";
}

export function isURL(value) {
  return typeof value === "string" && value.startsWith("http");
}
