import { LionButtonSubmit } from "@lion/button";
import { css, html } from "lit";
import { LdsButtonMixin } from "./lds-button-mixin.js";
import { LdsButtonStylesMixin } from "./lds-button-styles-mixin.js";

import "../../../legl-ui/lds-icon";

export class LdsIconButton extends LdsButtonStylesMixin(
  LdsButtonMixin(LionButtonSubmit),
) {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
            display: inline-flex;
            padding: 12px;
        }
        :host([variant="outlined"]) {
            padding: 11px;
        }
        .button-content {
            display: flex;
        }
        :host([small]) {
            padding: 3px 3px;
            height: 30px;
        }
    `,
    ];
  }

  static get properties() {
    return {
      ...super.properties,
      name: {
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.isLoading = false;
  }

  render() {
    return html`<div class="button-content" aria-label=${this.name}>
      ${
        this.isLoading
          ? this.loadingIcon
          : html`<lds-icon name=${this.name} colour=${this.colour}></lds-icon>`
      }
      ${super.render()}
  </div>`;
  }
}
if (!customElements.get("lds-icon-button")) {
  customElements.define("lds-icon-button", LdsIconButton);
}
