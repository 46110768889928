export const WaffleFlags = {
  SIGNATURE_REQUEST_SWITCH: "signature_request",
  ENABLE_LEGL_ASSIST_CDD: "enable_legl_assist_cdd",
  CONFLICT_CHECKS_FLAG: "conflict_checks",
  VUE_PAY_CHECKOUT_FLAG: "vue-pay-checkout",
  STANDARD_CDD_NO_ID: "standard_cdd_no_id",
  ADYEN_ALLOWED: "adyen-allowed",
  CONTACTS_V2_INSIGHTS: "contacts_v2_insights",
  DNB_COMPANIES_HOUSE_FILINGS: "dnb_companies_house_filings",
  PAYMENT_PLANS_DETAIL_DRAWER_VUE: "payment_plans_detail_drawer_vue",
  CLIO_INTEGRATION: "clio_integration",
};
