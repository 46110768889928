import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";
import { leglModalSelectors } from "../../../legl-ui/modal/src/legl-modal-selectors";

export class LeglModal extends LitElement {
  static get styles() {
    return css`
            :host {
                width: 100%;
                max-width: var(--legl-modal-max-width, 500px);
                background: white;
                border-radius: 0.3rem;
                z-index: 1060;
                max-height: calc(100vh - 6rem);
                overflow-y: auto;
            }
            .modal {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .modal__title {
                background: ${neutral["800"]};
                padding: 1rem 1.5rem;
                margin: 0;
                color: white;
                font-size: 1.3rem;
                font-weight: normal;
            }

            .modal__alert {
                padding: 1rem 1.5rem;
                background: var(--legl-grey-lightest);
            }

            .modal__content {
                padding: 1rem 1.5rem;
            }

            .modal__footer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                border-top: solid 1px var(--legl-grey-active);
            }

            .modal__footer > legl-button {
                width: 50%;
            }

            .modal__footer > legl-button:first-child {
                --legl-button-border-radius: 0px 0px 0px 4px;
            }

            .modal__footer > legl-button:last-child {
                --legl-button-border-radius: 0px 0px 4px 0px;
            }
        `;
  }

  static get properties() {
    return {
      title: {
        type: String,
      },
      confirmText: {
        type: String,
        attribute: "confirm-text",
      },
      confirmVariant: {
        type: String,
        attribute: "confirm-variant",
      },
      cancelText: {
        type: String,
        attribute: "cancel-text",
      },
      multistep: {
        type: Boolean,
      },
      isLastStep: {
        type: Boolean,
        state: true,
      },
      showNextStep: {
        type: Boolean,
        state: true,
      },
    };
  }

  closeModal(accept = false) {
    this.dispatchEvent(
      new CustomEvent("modal-close", {
        detail: { body: this.closeEventDetails, accept },
      }),
    );
  }

  get closeEventDetails() {}

  get modalContent() {}

  get modalAlert() {
    return null;
  }

  get isValid() {
    return true;
  }

  get isButtonDisabled() {
    return !this.isValid;
  }

  get nextStepContent() {}

  render() {
    return html`
            <div class="modal" data-cy="${leglModalSelectors.modal}">
                <h2 class="modal__title">${this.title}</h2>
                ${
                  this.modalAlert && !this.showNextStep
                    ? html`<div
                          class="modal__alert"
                          data-cy="${leglModalSelectors.alert}"
                      >
                          ${this.modalAlert}
                      </div>`
                    : ""
                }
                <div class="modal__content">
                    ${
                      this.showNextStep
                        ? this.nextStepContent
                        : this.modalContent
                    }
                </div>
                <div class="modal__footer">
                    <legl-button
                        variant="transparent"
                        size="large"
                        @click=${() => this.closeModal(false)}
                        data-cy="${leglModalSelectors.cancelButton}"
                        >${this.isLastStep ? "close" : this.cancelText}</legl-button
                    >
                    ${
                      !this.showNextStep
                        ? html` <legl-button
                              size="large"
                              .variant=${this.confirmVariant}
                              data-cy="${leglModalSelectors.confirmButton}"
                              .disabled=${this.isButtonDisabled}
                              @click=${() => this.closeModal(true)}
                              >${this.confirmText}</legl-button
                          >`
                        : nothing
                    }
                </div>
            </div>
        `;
  }

  static create(config = {}) {
    return new Promise((resolve, reject) => {
      let isLastStep;
      const modalId = `modal-${Date.now()}`;
      const newModal = document.createElement("lion-dialog");
      newModal.id = modalId;
      const content = document.createElement(this.tagName);
      content.slot = "content";
      Object.entries(config).forEach(([key, value]) => {
        content[key] = value;
      });
      newModal.appendChild(content);
      document.body.appendChild(newModal);
      content.addEventListener("show-next-step", () => {
        content.showNextStep = true;
        content.isLastStep = true;
        isLastStep = true;
      });
      content.addEventListener("close-on-failure", () => {
        newModal.opened = false;
        setTimeout(() => {
          newModal.remove();
        }, 500);
      });
      content.addEventListener("modal-close", ({ detail }) => {
        if (detail.accept && content.isValid) {
          resolve(detail.body);
        }
        if (!detail.accept) {
          reject(new Error("Modal closed without accepting"));
        }
        if (
          !detail.accept ||
          (!config.multistep && detail.accept && content.isValid)
        ) {
          newModal.opened = false;
          setTimeout(() => {
            newModal.remove();
          }, 500);
        }
      });
      newModal.opened = true;
    });
  }

  static get tagName() {
    return "legl-modal";
  }
}

customElements.define(LeglModal.tagName, LeglModal);
