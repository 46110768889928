import DOMPurify from "dompurify";
import { LitElement, css, html, nothing } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import markdownit from "markdown-it";
import "../../../../../../legl-ui/button";
import "../../../../../../legl-ui/lds-alert";
import { customColours, neutral } from "../../../../../../legl-ui/lds-colours";
import "../../../../../../legl-ui/lds-icon";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { patch } from "../../../../../../legl-ui/utils/fetch";
import { copyToClipboard } from "../../../../../../legl-ui/utils/functions.js";

export class LeglAssistSummary extends LitElement {
  // As more use cases emerge, we should have a set of standard styles
  // and load custom implementation CSS from a separate file.
  static styles = css`
        :host {
            text-transform: initial;
            display: block;
            min-height: 34px;
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            border-radius: 4px 4px 0 0;
            background: rgba(228, 234, 254, 0.6);
            padding: 24px;
        }

        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .left-top-row {
            display: flex;
            align-items: flex-end;
            gap: 6px;
        }

        .legl-assist-tag {
            display: flex;
            padding: 4px 8px 4px 4px;
            align-items: center;
            gap: 4px;
            border-radius: 100px;
            background: rgba(74, 112, 250, 0.25);
            ${typographyPresets.bodyBold};
            color: ${neutral["900"]};
            line-height: 18px;
        }

        .legl-assist-tag > lds-icon {
            margin-top: 2px;
        }

        .header-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin: 0 0 12px 0;
        }

        .summary-header {
            margin: 0;
            color: ${neutral["900"]};
        }

        lds-alert {
            width: 100%;
            margin: 24px 0 20px 0;
        }

        .copy-button {
            transition: transform 300ms ease-in-out;
        }

        .content {
            margin-bottom: 16px;
            color: ${neutral["900"]};
        }

        /* Close and copy button styling  */
        button.icon-with-label {
            background: none;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            font: inherit;
            outline: inherit;
            margin-left: auto;
        }

        button.icon-with-label:hover {
            color: ${neutral["600"]};
        }

        button.icon-with-label:active {
            transform: scale(0.98);
        }

        .button-container {
            display: flex;
            gap: 10px;
        }

        .copy-button {
            width: 180px;
        }

        /* View summary button styling  */
        .summary-button {
            background-color: ${customColours["leglBlue"]};
            border: 1px solid ${customColours["leglBlue"]};
            opacity: 1;
            visibility: visible;
            padding: 6px 10px;
            height: 34px;
            overflow: hidden;
            transition: background-color 0.15s ease-in-out;
        }

        .summary-button:hover {
            background-color: #4358a5;
            border: 1px solid #4358a5;
        }

        /* Animation styling  */
        .fade-in {
            animation: fadeIn 0.35s ease-in forwards;
            animation-delay: 0.5s;
            opacity: 0;
        }

        .fade-out {
            animation: fadeOut 0.15s ease-in forwards;
        }

        .slider {
            overflow-y: hidden;
            max-height: 2500px; /* approximate max height */

            transition-property: all;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        }

        .legl-assist-beta-pill {
            display: flex;
            padding: 2px 8px;
            align-items: center;
            border-radius: 100px;
            background: ${neutral["200"]};
            text-transform: uppercase;
            ${typographyPresets.smallBold};
        }

        .slider.closed {
            max-height: 0;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
                visibility: hidden;
            }
            1% {
                height: 34px;
                padding: 6px 10px;
            }
            100% {
                opacity: 1;
                visibility: visible;
                padding: 6px 10px;
                height: 34px;
            }
        }

        @keyframes fadeOut {
            0% {
                opacity: 1;
                visibility: visible;
            }
            100% {
                opacity: 0;
                visibility: hidden;
            }
        }
    `;

  static get properties() {
    return {
      header: {
        type: String,
      },
      content: {
        type: String,
      },
      isOpen: {
        type: Boolean,
      },
      isCopied: {
        type: Boolean,
        state: true,
      },
      isClicked: {
        type: Boolean,
        state: true,
      },
      pdfHasSummary: {
        type: Boolean,
        state: true,
      },
      pdfUrl: {
        type: String,
      },
      leglAssistId: {
        type: Number,
      },
    };
  }

  constructor() {
    super();
    this.content = "";
    this.converter = markdownit();
    this.isOpen = false;
    this.isCopied = false;
    this.isClicked = false;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async addSummaryToPdf() {
    this.isClicked = true;
    const res = await patch(`/api/${this.leglAssistId}/add-to-pdf/`);

    if (res.ok) {
      this.isClicked = false;
      this.pdfHasSummary = true;
      LdsToast.showSuccess({
        title: "Legl Assist successfully added to report",
        autoClose: true,
      });
    } else {
      LdsToast.showError({
        title: "Unable to add to report. Please try again later.",
        autoClose: true,
      });
      this.isClicked = false;
    }
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen;

    this.applyAnimation();
  }

  applyAnimation() {
    const slideContent = this.shadowRoot?.querySelector(
      "[data-target='slide-content']",
    );
    const button = this.shadowRoot?.querySelector("#summary-button");

    if (this.isOpen) {
      button?.classList.add("fade-out");
      button?.classList.remove("fade-in");
    } else {
      button?.classList.add("fade-in");
      button?.classList.remove("fade-out");
    }
  }

  copyFunc() {
    this.isCopied = true;
    copyToClipboard(this.content);

    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

  get title() {
    return html`<span>Legl Assist is an advisory AI product.</span>`;
  }

  get message() {
    return html`<p>
            Legl Assist can contain errors, inconsistencies, or outdated
            information. Legl Assist should only be used as an aid, for more
            information see
            <a
                href="https://help.legl.com/en/articles/119531-my-report-has-come-back-with-a-consider-result-for-one-or-more-sections-what-does-this-mean"
                target="_blank"
                title="Legl Assist"
                >here</a
            >.
        </p>`;
  }

  renderHeaderSection() {
    return html`
            <div class="header-container">
                <h3 class="summary-header">${this.header}</h3>
            </div>
        `;
  }

  render() {
    const bodyHTML = DOMPurify.sanitize(this.converter.render(this.content));

    return html`
            <lds-button
                id="summary-button"
                class="summary-button"
                variant="success"
                small
                colour="neutral"
                icon="lds-icon-Magic"
                @click=${() => this.toggleIsOpen()}
                >View summary</lds-button
            >
            <div
                data-target="slide-content"
                class="slider ${this.isOpen ? "" : "closed"}"
            >
                <div class="container">
                    <div class="top-row">
                        <div class="left-top-row">
                            <span class="legl-assist-tag"
                                ><lds-icon name="lds-icon-Magic"></lds-icon>Legl
                                Assist<span class="legl-assist-beta-pill"
                                    >Beta</span
                                >
                            </span>
                        </div>

                        <button
                            id="legl-assist-close-button"
                            class="close icon-with-label"
                            @click=${() => this.toggleIsOpen()}
                        >
                            Close
                            <lds-icon name="lds-icon-Close" small></lds-icon>
                        </button>
                    </div>
                    <lds-alert
                        type="warning"
                        title=${this.title}
                        message=${this.message}
                    ></lds-alert>
                    <div class="content-container">
                        ${this.header ? this.renderHeaderSection() : nothing}
                        <div class="content">${unsafeHTML(bodyHTML)}</div>
                    </div>
                    <div class="button-container">
                        <lds-button
                            colour="neutral"
                            variant="contained"
                            small
                            .disabled="${!this.pdfUrl || this.pdfHasSummary}"
                            ?is-loading=${this.isClicked}
                            id="add-to-pdf-button"
                            icon="lds-icon-${
                              this.pdfHasSummary ? "Check" : "Add"
                            }"
                            @click=${() => this.addSummaryToPdf()}
                            >${
                              this.pdfHasSummary
                                ? "Legl Assist added to report"
                                : "Add Legl Assist to report"
                            }</lds-button
                        >
                        <lds-button
                            colour="neutral"
                            variant="outlined"
                            small
                            id="legl-assist-copy-button"
                            .disabled="${this.isCopied}"
                            @click=${() => this.copyFunc()}
                            class="copy-button"
                            icon="${
                              this.isCopied ? "lds-icon-Check" : "lds-icon-Copy"
                            }"
                        >
                            ${
                              this.isCopied
                                ? "Copied to clipboard"
                                : "Copy to clipboard"
                            }
                        </lds-button>
                    </div>
                </div>
                <slot name="after"></slot>
            </div>
        `;
  }
}

if (!customElements.get("legl-assist-summary")) {
  customElements.define("legl-assist-summary", LeglAssistSummary);
}
